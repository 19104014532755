/* eslint-disable import/prefer-default-export */
export const API_STATIC_FAQ = 'faq';
export const API_STATIC_FAQNEW = 'frequently-asked-questions';
export const API_STATIC_HOME = 'home';
export const API_STATIC_WEEKZERO = 'week-zero';
export const API_STATIC_ESSENTIALS_ONLINE = 'slimmingworld-essentials-online';
export const API_STATIC_ESSENTIALS_GROUP = 'slimmingworld-essentials-group';
export const API_STATIC_PUBLIC_ADVERTISE_WITH_US = 'magazine-advertise-with-us';
export const API_STATIC_PUBLIC_HOME = 'public-home';
export const API_STATIC_PUBLIC_WHAT_HAPPENS_ONLINE = 'public-what-happens-online-page';
export const API_STATIC_PUBLIC_WHAT_HAPPENS_IN_GROUP_PAGE = 'public-what-happens-in-group-page';
export const API_STATIC_PUBLIC_CONTACT_US = 'public-contact-us';
export const API_STATIC_PUBLIC_ICELAND_LANDING = 'food-range';
export const API_STATIC_PUBLIC_ICELAND_OUR_STORY = 'our-story';
export const API_STATIC_PUBLIC_ICELAND_OUR_PRODUCTS = 'our-products';
export const API_STATIC_PUBLIC_ICELAND_PROMOTION = 'public-iceland-promotion';
export const API_STATIC_PUBLIC_ICELAND_OUR_RANGE = 'our-range';
export const API_STATIC_PUBLIC_OFFERS = 'public-offers';
export const API_STATIC_PUBLIC_SCIENCE = 'public-the-science';
export const API_STATIC_PUBLIC_WHAT_CAN_I_EAT = 'public-what-can-i-eat';
export const API_STATIC_PUBLIC_HOW_IT_WORKS = 'public-how-it-works';
export const API_STATIC_PUBLIC_HOW_IT_WORKS_PAGE = 'public-how-it-works-page';
export const API_STATIC_PUBLIC_OUR_STORY = 'public-the-slimming-world-story';
export const API_STATIC_PUBLIC_FREE_FOOD_FEB = 'public-free-food-feb';
export const API_STATIC_PUBLIC_MENTAL_HEALTH = 'public-mental-health';
export const API_STATIC_PUBLIC_RESEARCH_USER_TESTING = 'public-research-user-testing';
export const API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN = 'public-nearest-group-bulletin';
export const API_STATIC_PUBLIC_ICELAND_SPECIAL_OFFERS = 'iceland-special-offers';
export const API_STATIC_PUBLIC_SUCCESS_STORIES_LANDING = 'public-success-stories';
export const API_STATIC_PUBLIC_GROUP_DETAILS = 'public-group-search-detail-page';
export const API_STATIC_PUBLIC_JAN_2020_HOMEPAGE = 'public-jan-2020-homepage';
export const API_STATIC_VIRTUAL_GROUPS_CAMPAIGN = 'public-virtual-groups-campaign-block';
export const API_STATIC_PUBLICITY_PHOTO_UPLOADER = 'publicity-photo-uploader';
export const API_STATIC_PUBLIC_HOMEPAGE_SECONDARY_SHOWCASE = 'public-homepage-secondary-showcase';
export const API_STATIC_SHOP_DELIVERY_DELAY_INFORMATION = 'shop-delivery-delay-information';
export const API_STATIC_VIRTUAL_GROUPS_LANDING = 'public-virtual-groups-landing';
export const API_STATIC_PHYSICAL_GROUPS_LANDING = 'public-physical-groups-landing';
export const API_STATIC_PUBLIC_BETTER_HEALTH_NEW = 'public-better-health-new';
export const API_STATIC_PUBLIC_BETTER_HEALTH_TILE = 'public-better-health-tile';
export const API_STATIC_PUBLIC_RETURN_CAMPAIGN = 'public-return-campaign';
export const API_STATIC_PUBLIC_NEAREST_GROUP_SEARCH_RESULTS = 'public-nearest-group-search-results';
export const API_STATIC_PROFILE_BUILDER = 'profile-builder';
export const API_STATIC_PUBLIC_NEWSLETTER_SIGNUP = 'public-newsletter-signup';
export const API_STATIC_PODCAST_LANDING_PAGE = 'podcast';
export const API_STATIC_FOOD_OPTIMISING = 'food-optimising-introduction';
export const API_STATIC_FOOD_OPTIMISING_RECIPE_IMAGES =
  'food-optimising-introduction-recipe-images';
export const API_STATIC_FOOD_OPTIMISING_BOOKMARKS = 'food-optimising-bookmarks';
export const API_STATIC_PUBLIC_BETTER_HEALTH_PHE_TILE = 'public-homepage-better-health-phe-tile';
export const API_STATIC_HOME_HEADER_CAMPAIGN = 'public-home-header-campaign';
export const API_STATIC_PUBLIC_RECIPE_LANDING_PAGE_V2 = 'public-recipe-landing-page-v2';
export const API_STATIC_PUBLIC_JANUARY_2022_CAMPAIGN = 'public-january-2022-campaign';
export const API_STATIC_PUBLIC_VEGAN_RECIPE_PROMOTION = 'public-vegan-recipe-promotion';
export const API_STATIC_PUBLIC_MYTHS = 'public-slimming-world-myths';
export const API_STATIC_PUBLIC_MAGAZINE = 'public-magazine';
export const API_STATIC_PUBLIC_JANUARY_2021_CAMPAIGN_TILE = 'public-january-2021-campaign-tile';
export const API_STATIC_PUBLIC_BODY_MAGIC = 'public-body-magic';
export const API_STATIC_PUBLIC_BMI_CALCULATOR_PAGE = 'public-bmi-calculator';

// seven day menus
export const API_STATIC_PUBLIC_SEVEN_DAY_ENTRY = 'public-seven-day-menu-landing-page';
export const API_STATIC_PUBLIC_SEVEN_DAY_MENU = 'public-seven-day-menu-';

// new pages that are made using the: Public Market Rich Text Content Page document type
export const API_STATIC_PUBLIC_NEAREST_GROUP_DETAIL_V2 = 'public-nearest-group-detail-v2';
export const API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN_V2 = 'public-nearest-group-bulletin-v2';
export const API_STATIC_PUBLIC_NEAREST_GROUP_SEARCH_RESULTS_V2 =
  'public-nearest-group-search-results-v2';
export const API_STATIC_PHYSICAL_GROUPS_LANDING_V2 = 'public-physical-groups-landing-v2';

// Consultant - pages
export const API_STATIC_PUBLIC_FRANCHISE_FACTS = 'public-franchise-facts';
export const API_STATIC_PUBLIC_OUR_FRANCHISE_PACKAGE = 'public-our-franchise-package';
export const API_STATIC_PUBLIC_OUR_CONSULTANTS = 'public-our-consultants';
export const API_STATIC_PUBLIC_CONSULTANT_HOME = 'public-consultant-home';
export const API_STATIC_PUBLIC_CONSULTANT_ENQUIRY = 'public-consultant-enquiry';

// Careers - pages
export const API_STATIC_PUBLIC_CAREERS_FAQS = 'public-careers-faqs';
export const API_STATIC_PUBLIC_CAREERS_LANDING = 'public-careers-landing';
export const API_STATIC_PUBLIC_CAREERS_JOIN_THE_HEAD_OFFICE_TEAM =
  'public-careers-join-the-head-office-team';
export const API_STATIC_PUBLIC_CAREERS_BECOME_A_DISTRICT_MANAGER =
  'public-careers-become-a-district-manager';

// Comparison pages
export const API_STATIC_PUBLIC_COMPARISON_PAGE = 'public-comparison-page';

// App Promotion Page
export const API_STATIC_PUBLIC_SLIMMMING_WORLD_APP = 'public-slimming-world-app';

export const API_STATIC_PUBLIC_GROUP_OR_ONLINE_MEMBERSHIP_PAGE =
  'public-group-or-online-membership-page';
