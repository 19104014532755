/* global WP_DEFINE_IS_NODE */
import { captureXhrError } from './raven/raven-client';
import authenticate from './auth/authenticate';
import serviceConfig from '../config/service.configdefinitions';
import Gateway from '../net/gateway/Gateway';
import RESTOutputHandler from '../net/gateway/output/RESTOutputHandler';
import RESTInputHandler from '../net/gateway/input/RESTInputHandler';
import { setValue } from './injector';
import {
  GATEWAY_ACCOUNT,
  GATEWAY_ACCOUNT_MIGRATION,
  GATEWAY_ACCOUNT_IDS,
  GATEWAY_CONTACT,
  GATEWAY_CONTENT,
  GATEWAY_CONTENT_V2,
  GATEWAY_SELF,
  GATEWAY_SHOP,
  GATEWAY_DEAL,
  GATEWAY_FOOD,
  GATEWAY_ACTIVITY,
  GATEWAY_GROUP_ACCOUNT,
  GATEWAY_GROUP_SEARCH,
  GATEWAY_COMMUNITY,
  GATEWAY_COMMUNITY_V2,
  GATEWAY_ADVERTISEMENT,
  GATEWAY_ACCOUNT_WITHOUT_PATH,
} from '../data/Injectables';
import WebHosts from '../data/enum/WebHost';

const DEFAULT_CONTENT_MAX_AGE = 60 * 60; // 1 hour in seconds

/**
 * Disable SSR cache for public
 * @returns {boolean}
 */
const setUseCache = () => {
  let useCache = true;
  if (WP_DEFINE_IS_NODE && serviceConfig.webHost === WebHosts.PUBLIC) {
    useCache = false;
  }

  return useCache;
};

const addAuthHeaders = options => {
  if (!options.getState) {
    throw new Error(
      `When using the authentication hook on the Gateway, you should pass the setState to the options when doing the Gateway call. Check the action that executes this API call: ${options.url}`,
    );
  }

  return authenticate().then(accessToken => {
    const originalHeaders = options.headers || {};
    // eslint-disable-next-line no-param-reassign
    options.headers = {
      ...originalHeaders,
      Authorization: `Bearer ${accessToken}`,
    };
  });
};

/**
 * Sets up the injects for use in the project.
 * This is done in a specific time in the startup flow where the required information is available,
 * but before any of the values are used.
 *
 * @function setupInjects
 * @param config {any} Config object with API information
 * @param dispatch {function} The redux store dispatch function
 * @param clientCredentialsManagerInstance {any} The instance of the ClientCredentialsManager
 */
const setupInjects = ({ config }) => {
  const baseGatewayConfig = {
    mode: 'cors', // cors, no-cors, or same-origin
    outputHandler: new RESTOutputHandler(),
    inputHandler: new RESTInputHandler(),
    onError(error) {
      captureXhrError(error);
    },
  };

  const gateways = [
    { name: GATEWAY_ACCOUNT, api: config.api.account, auth: false },
    { name: GATEWAY_ACCOUNT_WITHOUT_PATH, api: config.api.account_without_path, auth: false },
    { name: GATEWAY_ACCOUNT_MIGRATION, api: config.api.account_migration, auth: false },
    {
      name: GATEWAY_ACCOUNT_IDS,
      api: config.api.account,
      auth: false,
      options: {
        url: config.api.account.host,
        credentials: 'include',
      },
    },
    {
      name: GATEWAY_CONTACT,
      api: config.api.contact,
      auth: false,
    },
    {
      name: GATEWAY_CONTENT,
      api: config.api.content,
      auth: false,
      options: {
        useCache: setUseCache(),
        defaultMaxAge: DEFAULT_CONTENT_MAX_AGE,
      },
    },
    {
      name: GATEWAY_CONTENT_V2,
      api: config.api.content_v2,
      auth: false,
      options: {
        useCache: setUseCache(),
        defaultMaxAge: DEFAULT_CONTENT_MAX_AGE,
      },
    },
    { name: GATEWAY_DEAL, api: config.api.deal, auth: false },
    { name: GATEWAY_FOOD, api: config.api.food, auth: false },
    { name: GATEWAY_ACTIVITY, api: config.api.activity, auth: false },
    { name: GATEWAY_SHOP, api: config.api.shop, auth: false },
    { name: GATEWAY_GROUP_ACCOUNT, api: config.api.groupAccount, auth: false },
    {
      name: GATEWAY_GROUP_SEARCH,
      api: config.api.groupSearch,
      auth: false,
      options: {
        useCache: true,
        defaultMaxAge: DEFAULT_CONTENT_MAX_AGE,
      },
    },
    { name: GATEWAY_COMMUNITY, api: config.api.community, auth: false },
    { name: GATEWAY_COMMUNITY_V2, api: config.api.community_v2, auth: false },
    { name: GATEWAY_ADVERTISEMENT, api: config.api.ads, auth: false },
  ];

  if (!WP_DEFINE_IS_NODE) {
    const gatewaySelf = new Gateway({
      url: `${window.location.protocol}//${window.location.host}`,
      outputHandler: new RESTOutputHandler(),
      inputHandler: new RESTInputHandler(),
    });
    setValue(GATEWAY_SELF, gatewaySelf);
  }

  const authOptions = {
    beforeRequest(options) {
      return addAuthHeaders(options);
    },
  };

  gateways.forEach(gateway => {
    const gatewayInstance = new Gateway({
      ...baseGatewayConfig,
      ...(gateway.auth ? authOptions : {}),
      url: `${gateway.api.host}${gateway.api.path}`,
      ...gateway.options,
    });

    setValue(gateway.name, gatewayInstance);
  });
};

export default setupInjects;
