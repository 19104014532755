import React from 'react';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import DeviceStateType from 'components/types/DeviceState';
import ImageType from 'components/types/Image';

import QuotationWithLargeAuthorPhotoAndLinkBlock from '../QuotationWithLargeAuthorPhotoAndLinkBlock';

import './quotation-with-large-author-photo-and-link-list.scss';

export type QuotationWithLargeAuthorPhotoAndLinkListProps = {
  deviceState: DeviceStateType;
  items: Array<{
    author: string;
    authorPhoto: ImageType;
    mediaAssetCta: {
      ctaText: string;
      ctaLink: string;
    };
    note: string;
    quotation: string;
    photoPosition: 'Left' | 'Right';
    _type: string;
  }>;
};

const QuotationWithLargeAuthorPhotoAndLinkList = (
  { items, deviceState }: QuotationWithLargeAuthorPhotoAndLinkListProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => (
  <section className={className} data-testid={dataTestId}>
    {items?.map(({ author, authorPhoto, mediaAssetCta, note, quotation, photoPosition }, index) => (
      <QuotationWithLargeAuthorPhotoAndLinkBlock
        author={author}
        authorPhoto={authorPhoto}
        mediaAssetCta={mediaAssetCta}
        note={note}
        quotation={quotation}
        key={index}
        deviceState={deviceState}
        photoPosition={photoPosition}
      />
    ))}
  </section>
);

type QuotationWithLargeAuthorPhotoAndLinkListType = (
  props: QuotationWithLargeAuthorPhotoAndLinkListProps,
) => JSX.Element;

export default compose<QuotationWithLargeAuthorPhotoAndLinkListType>(
  withFunctionalClassName(ComponentType.ATOM, 'QuotationWithLargeAuthorPhotoAndLinkList'),
)(QuotationWithLargeAuthorPhotoAndLinkList);
