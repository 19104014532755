import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';

import RecruitmentHeader from 'components/organisms/RecruitmentHeader';

import './careers.scss';

export type CareersProps = {
  children: { children: React.ReactNode };
  deviceState: number;
};

const Careers = (
  { children }: CareersProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => {
  const navigationLinks = [
    {
      to: Pages.UK_PUBLIC_CAREERS,
      locale: 'careers.navigation.home',
    },
    {
      to: Pages.UK_PUBLIC_CAREERS_JOIN_THE_HEAD_OFFICE_TEAM,
      locale: 'careers.navigation.joinTheHeadOfficeTeam',
    },
    {
      to: Pages.UK_PUBLIC_CAREERS_BECOME_A_DISTRICT_MANAGER,
      locale: 'careers.navigation.becomeADistrictManager',
    },
    {
      to: Pages.UK_PUBLIC_CAREERS_FAQS,
      locale: 'careers.navigation.faqs',
    },
  ];
  return (
    <div className={className} data-testid={dataTestId}>
      <RecruitmentHeader navigationLinks={navigationLinks} site="careers" />
      {children}
    </div>
  );
};

export default withFunctionalClassName(ComponentType.PAGE, 'Careers')(Careers);
