import React from 'react';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import LockUp from 'components/atoms/LockUp';

import TextNew, {
  Colors,
  FontWeight,
  ElementTypes,
  FontScaleType,
  FontScaleLineHeight,
} from 'components/atoms/TextNew';

import IndividualStage from './components/IndividualStage';

import './careers-three-stages.scss';

export type CareersThreeStagesProps = object;

const stages = [
  {
    bodyLocaleParams: {
      SERVICE: <strong>exceptional service</strong>,
    },
    hasArrow: true,
  },
  {
    bodyLocaleParams: {
      INSPIRE: <strong>inspire and motivate</strong>,
    },
    hasArrow: true,
  },
  {
    bodyLocaleParams: {
      DISTRICT: (
        <TextNew
          fontScaleType={FontScaleType.SCRIPT_STANDOUT_TWO}
          color={Colors.BRAND}
          element={ElementTypes.SPAN}
        >
          District
        </TextNew>
      ),
      MANAGER: (
        <TextNew
          fontScaleType={FontScaleType.SCRIPT_STANDOUT_TWO}
          color={Colors.BRAND}
          element={ElementTypes.SPAN}
        >
          Manager
        </TextNew>
      ),
    },
    hasArrow: false,
  },
];

const CareersThreeStages = ({}: CareersThreeStagesProps, {}, className: string): JSX.Element => (
  <section className={className}>
    <LockUp type="inline">
      <LockUp.Serif>what your </LockUp.Serif>
      <LockUp.Script>training programme </LockUp.Script>
      <LockUp.Serif>might look like</LockUp.Serif>
    </LockUp>

    <TextNew
      fontScaleType={FontScaleType.SANS_SERIF_STANDOUT_TWO}
      localeId="careersThreeStages.description"
      cid="training-description"
    />

    <section className="stages">
      {stages.map((stage, index) => (
        <IndividualStage
          key={`stage-${index + 1}`}
          id={`stage-${index + 1}`}
          title={
            <h3>
              <TextNew
                fontScaleLineHeight={FontScaleLineHeight.SMALL}
                fontScaleType={FontScaleType.SERIF_STANDOUT_ONE}
                localeId="careersThreeStages.stageTitle"
                element={ElementTypes.SPAN}
                color={Colors.BRAND}
                weight={FontWeight.SEMI_BOLD}
              />
              <TextNew
                cid="number"
                fontScaleLineHeight={FontScaleLineHeight.SMALL}
                fontScaleType={FontScaleType.SERIF_DISPLAY_TWO}
                element={ElementTypes.SPAN}
                color={Colors.BRAND}
                weight={FontWeight.SEMI_BOLD}
              >
                {index + 1}
              </TextNew>
            </h3>
          }
          body={
            <TextNew
              cid="stage-body"
              localeId={`careersThreeStages.stage${index + 1}.body`}
              fontScaleLineHeight={FontScaleLineHeight.SMALL}
              fontScaleType={FontScaleType.SANS_SERIF_BODY_TWO}
              color={Colors.BRAND}
              localeParams={stage.bodyLocaleParams}
            />
          }
          description={
            <TextNew
              cid="stage-description"
              localeId={`careersThreeStages.stage${index + 1}.description`}
              fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}
            />
          }
          hasArrow={stage.hasArrow}
        />
      ))}
    </section>
  </section>
);

type CareersThreeStagesType = (props: CareersThreeStagesProps) => JSX.Element;

export default compose<CareersThreeStagesType>(
  withFunctionalClassName(ComponentType.MOLECULE, 'CareersThreeStages'),
)(CareersThreeStages);
