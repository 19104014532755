import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import createScriptTitle from 'common/src/app/util/createScriptTitle';

import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';

import RecruitmentFaqQuestionAnswer from 'components/molecules/RecruitmentFaqQuestionAnswer';

export type RecruitmentFaqProps = {
  nestedRoleContent: Array<{
    quotation: string;
    text: string;
    title?: string;
    _type: string;
  }>;
};

const RecruitmentFaq = (
  { nestedRoleContent }: RecruitmentFaqProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => (
  <div className={className} data-testid={dataTestId}>
    {nestedRoleContent.map(({ _type, ...content }, index) => {
      switch (_type) {
        case types.QUOTATION_WITH_AUTHOR_PHOTO_BLOCK:
          return (
            <QuotationBlock
              {...content}
              quotation={createScriptTitle(content.quotation)}
              isConsultant
              key={`recruitment-faq-${index}`}
            />
          );

        default:
          return <RecruitmentFaqQuestionAnswer {...content} key={`recruitment-faq-${index}`} />;
      }
    })}
  </div>
);

export default withFunctionalClassName(ComponentType.ORGANISM, 'RecruitmentFaq')(RecruitmentFaq);
