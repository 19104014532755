import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import Button from 'components/atoms/Button';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, { Colors, ElementTypes, FontSizes, FontWeight } from 'components/atoms/TextNew';
import Loader from 'components/atoms/Loader';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import errorBoundary from 'components/hoc/ErrorBoundary';
import FiveStarReview from 'components/molecules/FiveStarReview';
import OurMembershipPackages from 'components/organisms/OurMembershipPackages';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import RegistrationCTAWithOptionalRoundelAndTitle from '../../molecules/RegistrationCTAWithOptionalRoundelAndTitle';
import NewsletterSignUp from '../../molecules/NewsletterSignUp';
import OnlineCarousel from '../../molecules/OnlineCarousel';
import SuccessStoryInspiration from '../../molecules/SuccessStoryInspiration';

import './what-happens-online.scss';

const WhatHappensOnline = (
  { whatHappensOnlineData, isInitializing, deviceState },
  context,
  className,
) => {
  if (isInitializing)
    return (
      <div className={className}>
        <Loader />
      </div>
    );

  return (
    <div className={className}>
      <header>
        <HeroBarPublic image={whatHappensOnlineData.heroImage}>
          <TextNew.Serif.SM
            element={ElementTypes.H1}
            weight={FontWeight.BOLD}
            cid="hero-bar-public-serif-heading"
          >
            {whatHappensOnlineData.title}
          </TextNew.Serif.SM>
          <TextNew.Script.MD
            cid="hero-bar-public-script-heading"
            color={Colors.BRAND}
            element={ElementTypes.H2}
          >
            {whatHappensOnlineData.subtitle}
          </TextNew.Script.MD>
        </HeroBarPublic>
      </header>

      <RegistrationCTAWithOptionalRoundelAndTitle
        introText={whatHappensOnlineData.introduction}
        roundelText={whatHappensOnlineData.roundel}
        buttonLeft={{
          link: whatHappensOnlineData.buttons1[0].buttonLink,
          label: whatHappensOnlineData.buttons1[0].buttonLabel,
        }}
        buttonRight={{
          link: whatHappensOnlineData.buttons1[1].buttonLink,
          label: whatHappensOnlineData.buttons1[1].buttonLabel,
        }}
      />

      <section className="carousel">
        <OnlineCarousel
          appPromoTitle={whatHappensOnlineData.appPromoTitle}
          appPromos={whatHappensOnlineData.appPromos}
        />
      </section>

      <Wrapper element={ElementTypes.ASIDE} width="md" padding="sm">
        <FiveStarReview
          reviewTitle={whatHappensOnlineData.reviewTitle}
          reviewBody={whatHappensOnlineData.reviewText}
        />
      </Wrapper>

      <OurMembershipPackages data={whatHappensOnlineData} />

      <RegistrationCTAWithOptionalRoundelAndTitle
        titleText={whatHappensOnlineData.buttons2Title}
        buttonLeft={{
          link: whatHappensOnlineData.buttons2[0].buttonLink,
          label: whatHappensOnlineData.buttons2[0].buttonLabel,
        }}
        buttonRight={{
          link: whatHappensOnlineData.buttons2[1].buttonLink,
          label: whatHappensOnlineData.buttons2[1].buttonLabel,
        }}
      />

      <SuccessStoryInspiration
        header={
          <>
            {whatHappensOnlineData?.successStoriesHeader && (
              <RichTextBlock
                cid="success-story-inspiration-header"
                text={whatHappensOnlineData.successStoriesHeader}
              />
            )}
          </>
        }
        footer={
          <>
            {whatHappensOnlineData?.successStoriesFooterCTA && (
              <Button
                cid="footer-button"
                to={whatHappensOnlineData.successStoriesFooterCTA.buttonLink}
              >
                <TextNew.Sans.MD
                  element={ElementTypes.SPAN}
                  color={Colors.PRIMARY_LIGHT}
                  size={deviceState >= DeviceState.LG ? FontSizes.MD : FontSizes.SM}
                >
                  {whatHappensOnlineData.successStoriesFooterCTA.buttonLabel}
                </TextNew.Sans.MD>
              </Button>
            )}
          </>
        }
        successStories={[
          whatHappensOnlineData.successStoriesLeft[0],
          whatHappensOnlineData.successStoriesRight[0],
        ]}
      />

      <NewsletterSignUp />
    </div>
  );
};

WhatHappensOnline.propTypes = {
  isInitializing: PropTypes.bool,
  whatHappensOnlineData: PropTypes.object,
  deviceState: PropTypes.number,
};

export default compose(
  withDeviceState(),
  errorBoundary({ wholePageError: true }),
  withFunctionalClassName(ComponentType.PAGE, 'WhatHappensOnline'),
)(WhatHappensOnline);
