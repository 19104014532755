import React, { FC } from 'react';
import classNames from 'classnames';

import './label.scss';

interface LabelProps {
  text?: React.ReactNode;
  htmlFor?: string;
  suppress?: boolean;
}

/**
 * The <label> tag defines a label for an <input> element.
 */

const Label: FC<LabelProps> = ({ text, htmlFor, suppress, ...props }) => (
  <label
    htmlFor={htmlFor}
    {...props}
    className={classNames('atom-label', {
      'sw-masked': suppress,
    })}
    data-testid="label"
  >
    {text}
  </label>
);

export default Label;
