import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import createScriptTitle from 'common/src/app/util/createScriptTitle';

import './call-out-block.scss';

const localeIdData = ['callOutBlock.block1', 'callOutBlock.block2', 'callOutBlock.block3'];

const CallOutBlock = ({ showBackground, pageBackgroundImage }, { getMessage }, className) => {
  const style = showBackground && { backgroundImage: `url(${pageBackgroundImage?.src}` };
  return (
    <div className={className} style={{ ...style }}>
      <div className="callout-content-wrapper">
        {localeIdData.map(localeId => (
          <RichTextBlock
            key={localeId}
            cid="callout-item-text-block"
            text={createScriptTitle(getMessage(localeId))}
          />
        ))}
      </div>
    </div>
  );
};

CallOutBlock.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

CallOutBlock.defaultProps = {
  showBackground: false,
};

CallOutBlock.propTypes = {
  pageBackgroundImage: PropTypes.object,
  showBackground: PropTypes.bool,
};

export default withFunctionalClassName(ComponentType.ATOM, 'CallOutBlock', [
  ({ showBackground }) => showBackground && 'show-background',
])(CallOutBlock);
