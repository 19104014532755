import React from 'react';
import classNames from 'classnames';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import TextNew, { FontScaleType, FontScaleLineHeight, Colors } from '../TextNew';
import './lock-up.scss';
import TextWithSwoosh from './components/TextWithSwoosh';

type ValueOf<T> = T[keyof T];

type ColorsProps = ValueOf<typeof Colors>;
type FontScaleLineHeightProps = ValueOf<typeof FontScaleLineHeight>;
type FontScaleTypeProps = ValueOf<typeof FontScaleType>;

type LockUpProps = {
  type: 'stacked' | 'inline';
  element?: keyof JSX.IntrinsicElements;
  children: React.ReactNode;
};

const LockUpNoClassName = (
  { type, element = 'h2', children }: LockUpProps,
  context: any,
  className: string,
): JSX.Element => {
  const HtmlElement = element as keyof JSX.IntrinsicElements;

  return (
    <HtmlElement
      className={classNames(className, {
        'lock-up-stacked': type === 'stacked',
        'lock-up-inline': type === 'inline',
      })}
    >
      {children}
    </HtmlElement>
  );
};

type FontStyleProps = {
  color?: ColorsProps;
  fontScaleLineHeight?: FontScaleLineHeightProps;
  fontScaleType: FontScaleTypeProps;
};

type RichTextProps = {
  text: string;
  type: 'inline' | 'stacked';
  strongFontStyle: FontStyleProps;
  fontStyle: FontStyleProps;
};

const RichText: React.FC<RichTextProps> = ({ text, strongFontStyle, fontStyle, type }) => {
  const strongClasses = classNames({
    [`font-scale-type-${strongFontStyle?.fontScaleType}`]: strongFontStyle?.fontScaleType,
    [`font-scale-line-height-${strongFontStyle?.fontScaleLineHeight}`]: strongFontStyle?.fontScaleLineHeight,
    [`${strongFontStyle?.color}-color`]: strongFontStyle?.color,
  });

  const fontClasses = classNames({
    // Renamed from regularClasses to fontClasses
    [`font-scale-type-${fontStyle?.fontScaleType}`]: fontStyle?.fontScaleType,
    [`font-scale-line-height-${fontStyle?.fontScaleLineHeight}`]: fontStyle?.fontScaleLineHeight,
    [`${fontStyle?.color}-color`]: fontStyle?.color,
  });

  return (
    <span
      className={classNames('lock-up-rich-text', fontClasses, {
        'lock-up-stacked': type === 'stacked',
        'lock-up-inline': type === 'inline',
      })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text.replace(
          /<strong>(.*?)<\/strong>/g,
          `<strong class="${strongClasses}">$1</strong>`,
        ),
      }}
    />
  );
};

type SerifProps = {
  element?: keyof JSX.IntrinsicElements;
  color?: ColorsProps;
  fontScaleLineHeight?: FontScaleLineHeightProps;
  fontScaleType?: FontScaleTypeProps;
  children: string;
};

const Serif = ({
  element = 'span',
  color,
  fontScaleLineHeight = FontScaleLineHeight.SMALL,
  fontScaleType = FontScaleType.SERIF_DISPLAY_TWO,
  children,
}: SerifProps): JSX.Element => (
  <TextNew
    element={element}
    color={color}
    fontScaleLineHeight={fontScaleLineHeight}
    fontScaleType={fontScaleType}
  >
    {children}
  </TextNew>
);

type ScriptProps = {
  element?: keyof JSX.IntrinsicElements;
  color?: ColorsProps;
  fontScaleLineHeight?: FontScaleLineHeightProps;
  fontScaleType?: FontScaleTypeProps;
  children: string;
  swoosh?: boolean;
  swooshOffSet?: number;
};

const Script = ({
  element = 'span',
  color = Colors.BRAND,
  fontScaleLineHeight = FontScaleLineHeight.SMALL,
  fontScaleType = FontScaleType.SCRIPT_DISPLAY_TWO,
  children,
  swoosh = false,
  swooshOffSet = 0,
}: ScriptProps): JSX.Element => {
  if (swoosh) {
    return (
      <TextWithSwoosh
        element={element}
        color={color}
        fontScaleLineHeight={fontScaleLineHeight}
        fontScaleType={fontScaleType}
        swooshOffSet={swooshOffSet}
      >
        {children}
      </TextWithSwoosh>
    );
  }
  return (
    <TextNew
      element={element}
      color={color}
      fontScaleLineHeight={fontScaleLineHeight}
      fontScaleType={fontScaleType}
    >
      {children}
    </TextNew>
  );
};

interface LockUpComposition {
  Serif: React.FC<SerifProps>;
  Script: React.FC<ScriptProps>;
  RichText: React.FC<RichTextProps>;
}

const LockUp: React.FC<LockUpProps> & LockUpComposition = withFunctionalClassName(
  ComponentType.ATOM,
  'LockUp',
)(LockUpNoClassName);

LockUp.Serif = Serif;
LockUp.Script = Script;
LockUp.RichText = RichText;

export default LockUp;
