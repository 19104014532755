import { compose, Action } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import { ThunkDispatch } from 'redux-thunk';

import { getComponent } from 'common/src/app/actions/resources/componentActions';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { API_STATIC_PUBLIC_CONSULTANT_ENQUIRY } from 'common/src/app/data/apiStatics';
import { API_CONSULTANT_CAREERS_PAGE_BACKGROUND } from 'common/src/app/data/apiComponents';
import {
  contentComponentSelector,
  staticPageSelector,
} from 'common/src/app/selectors/publicSelectors';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import getJotformEmbedUrl from 'common/src/app/selectors/jotformSelector';
import ConsultantEnquiry from './ConsultantEnquiry';
import { ConsultantEnquiryProps, NestedContent, RootState } from './ConsultantEnquiry.types';

export type PageData = {
  nestedContent: NestedContent[];
};

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = (state: RootState): ConsultantEnquiryProps => {
  const pageData = staticPageSelector<PageData>(state, API_STATIC_PUBLIC_CONSULTANT_ENQUIRY);
  return {
    jotformEmbedUrl: getJotformEmbedUrl(state),
    loading: pageLoading(state),
    nestedContent: pageData?.nestedContent.filter(
      // eslint-disable-next-line no-underscore-dangle
      content => content?._type === types.RICH_TEXT_BLOCK,
    ),
    pageBackgroundImage: contentComponentSelector(
      state,
      API_CONSULTANT_CAREERS_PAGE_BACKGROUND,
      'image',
    ),
  };
};

const connected = connect(mapStateToProps);

type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action>;

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch: AppThunkDispatch) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_CONSULTANT_ENQUIRY, true)),
  prepared: ({}, dispatch: AppThunkDispatch) =>
    dispatch(getComponent(API_CONSULTANT_CAREERS_PAGE_BACKGROUND)),
});

export default compose(addInitAction, connected)(ConsultantEnquiry);
