import React from 'react';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import TextNew, { FontScaleType, FontScaleLineHeight, FontWeight } from 'components/atoms/TextNew';
import ImageType from 'components/types/Image';

import './svg-with-title-list.scss';

export type SvgWithTitleListProps = {
  items: Array<{
    svg: ImageType;
    title: string;
  }>;
};

const SvgWithTitleList = (
  { items }: SvgWithTitleListProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => (
  <section className={className} data-testid={dataTestId}>
    <div className="outer">
      {items?.map(({ title, svg }) => (
        <article className="section" key={title}>
          {svg?.src && <img src={svg.src} alt={svg?.alt || 'icon'} />}

          <TextNew
            fontScaleLineHeight={FontScaleLineHeight.SMALL}
            fontScaleType={FontScaleType.SANS_SERIF_BODY_TWO}
            weight={FontWeight.SEMI_BOLD}
            cid="detail-text"
          >
            {title}
          </TextNew>
        </article>
      ))}
    </div>
  </section>
);

type SvgWithTitleListType = (props: SvgWithTitleListProps) => JSX.Element;

export default compose<SvgWithTitleListType>(
  withFunctionalClassName(ComponentType.ATOM, 'SvgWithTitleList'),
)(SvgWithTitleList);
