import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import Pages from 'common/src/app/data/enum/Pages';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import IconButton from 'components/molecules/IconButton';
import LocaleMessage from 'components/atoms/LocaleMessage';
import IconName from 'common/src/app/data/enum/IconName';
import createScriptTitle from 'common/src/app/util/createScriptTitle';

import RecruitmentBubbleBackgroundWrapper from '../../atoms/RecruitmentBubbleBackgroundWrapper';

import './recruitment-cta-block.scss';

export type RecruitmentCtaBlockProps = {
  localePrefix: string;
  site: 'consultant' | 'careers';
  lockUp?: React.ReactNode;
};

const RecruitmentCtaBlock = (
  { localePrefix, site, lockUp }: RecruitmentCtaBlockProps,
  { getMessage }: { getMessage: (id: string, params?: Record<string, string>) => string },
  className: string,
): JSX.Element => (
  <div className={className}>
    <RecruitmentBubbleBackgroundWrapper>
      {lockUp}
      <RichTextBlock
        title={
          lockUp ? '' : createScriptTitle(getMessage(`recruitmentCtaBlock.${localePrefix}.title`))
        }
        text={createScriptTitle(getMessage(`recruitmentCtaBlock.${localePrefix}.text`))}
      />
      <IconButton
        icon={IconName.MENU_COMMUNITY}
        buttonLabel={getMessage(`recruitmentCtaBlock.${site}.cta`)}
        to={site === 'consultant' ? Pages.UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY : null}
        href={site === 'careers' ? PagesExternal.SW_PEOPLE_HR : null}
      >
        <LocaleMessage id={`recruitmentCtaBlock.${site}.cta`} />
      </IconButton>
    </RecruitmentBubbleBackgroundWrapper>
  </div>
);

RecruitmentCtaBlock.defaultProps = {
  localePrefix: 'default',
  site: 'consultant',
};

RecruitmentCtaBlock.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'RecruitmentCtaBlock', [
  ({ localePrefix }: { localePrefix: string }) => localePrefix,
])(RecruitmentCtaBlock);
