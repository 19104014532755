import React from 'react';
import { Route } from 'react-router';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import Pages from 'common/src/app/data/enum/Pages';

import Careers from 'bundle-loader?lazy&reactHot&name=Careers!./index';
import CareersLanding from 'bundle-loader?lazy&reactHot&name=CareersLanding!./components/templates/CareersLanding';
import JoinTheHeadOfficeTeam from 'bundle-loader?lazy&reactHot&name=JoinTheHeadOfficeTeam!./components/templates/JoinTheHeadOfficeTeam';
import BecomeADistrictManager from 'bundle-loader?lazy&reactHot&name=BecomeADistrictManager!./components/templates/BecomeADistrictManager';
import CareersFaqs from 'bundle-loader?lazy&reactHot&name=CareersFaqs!./components/templates/CareersFaqs';

const routes = (
  <Route {...getRouteComponentProp(Careers)}>
    <Route
      title="Careers | {pageTitle}"
      description=""
      path={Pages.UK_PUBLIC_CAREERS}
      {...getRouteComponentProp(CareersLanding)}
    />

    <Route
      title="Careers - Join the head office team | {pageTitle}"
      description=""
      path={Pages.UK_PUBLIC_CAREERS_JOIN_THE_HEAD_OFFICE_TEAM}
      {...getRouteComponentProp(JoinTheHeadOfficeTeam)}
    />

    <Route
      title="Careers - Become a district manager | {pageTitle}"
      description=""
      path={Pages.UK_PUBLIC_CAREERS_BECOME_A_DISTRICT_MANAGER}
      {...getRouteComponentProp(BecomeADistrictManager)}
    />

    <Route
      title="Careers - FAQs | {pageTitle}"
      description=""
      path={Pages.UK_PUBLIC_CAREERS_FAQS}
      {...getRouteComponentProp(CareersFaqs)}
    />
  </Route>
);

export default routes;
