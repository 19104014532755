import React from 'react';
import { compose } from 'redux';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import DeviceStateType from 'components/types/DeviceState';
import TextNew, { FontScaleType, FontScaleLineHeight } from 'components/atoms/TextNew';
import IndividualDistrictField from './components/IndividualDistrictField';

import './careers-field-details.scss';

export type CareersFieldDetailsProps = {
  deviceState: DeviceStateType;
};

const CareersFieldDetails = ({}: CareersFieldDetailsProps, {}, className: string): JSX.Element => {
  const stages = [
    {
      backgroundSvg: IconName.DISTRICT_HEART_CIRCLE,
      localeParams: {
        NUMBER_OF_GROUPS: <strong>12,500 groups</strong>,
        DISTRICTS: <strong>districts</strong>,
      },
    },
    {
      backgroundSvg: IconName.DISTRICT_TEAM_WORK_CIRCLE,
      localeParams: {
        REGION: <strong>region</strong>,
      },
    },
    {
      backgroundSvg: IconName.DISTRICT_MAP_CIRCLE,
      localeParams: {
        HEAD_OFFICE_TEAMS: <strong>Head Office teams</strong>,
      },
    },
  ];

  return (
    <section className={className}>
      {stages.map(({ localeParams, ...stage }, index) => (
        <IndividualDistrictField
          key={`individual-stage-${index + 1}`}
          id={`stage-${index + 1}`}
          {...stage}
          description={
            <TextNew
              fontScaleLineHeight={FontScaleLineHeight.MEDIUM}
              fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}
              localeId={`careersFieldDetails.stage${index + 1}.description`}
              cid="field-description"
              localeParams={localeParams}
            />
          }
        />
      ))}
    </section>
  );
};

type CareersFieldDetailsType = (props: CareersFieldDetailsProps) => JSX.Element;

export default compose<CareersFieldDetailsType>(
  withFunctionalClassName(ComponentType.MOLECULE, 'CareersFieldDetails'),
)(CareersFieldDetails);
