import React, { Fragment } from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import createScriptTitle from 'common/src/app/util/createScriptTitle';

import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';
import VideoWithThumbnailBlock from 'components/molecules/BlockContent/components/atoms/VideoWithThumbnailBlock';

import CallOutBlock from '../../atoms/CallOutBlock';

import './consultant-home-block.scss';

const quotationBlockWithTitleArray = [types.RICH_TEXT_BLOCK, types.QUOTATION_BLOCK];

export type ConsultantHomeBlockProps = {
  nestedRoleContent: Array<{
    quotation: string;
    text: string;
    title?: string;
    _type: string;
  }>; // @TODO
};

export type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: any; // @TODO
  children: React.ReactNode;
};

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children;

const ConsultantHomeBlock = (
  { nestedRoleContent }: ConsultantHomeBlockProps,
  {},
  className: string,
): JSX.Element => {
  const isQuotationWithTitleBlock = nestedRoleContent.every(item =>
    // eslint-disable-next-line no-underscore-dangle
    quotationBlockWithTitleArray.includes(item._type),
  );

  return (
    <div className={className}>
      <ConditionalWrapper
        condition={isQuotationWithTitleBlock}
        wrapper={(children: React.ReactNode) => (
          <div className="quotation-with-title-block-wrapper">{children}</div>
        )}
      >
        {nestedRoleContent?.map(({ _type, ...content }, index) => {
          switch (_type) {
            case types.RICH_TEXT_BLOCK:
              return (
                <RichTextBlock
                  title={createScriptTitle(content.title)}
                  text={createScriptTitle(content.text)}
                  key={`consultant-home-block-${index}`}
                />
              );

            case types.QUOTATION_BLOCK:
              return (
                <QuotationBlock
                  {...content}
                  quotation={createScriptTitle(content.quotation)}
                  isConsultant
                  key={`consultant-home-block-${index}`}
                />
              );

            case types.VIDEO_WITH_THUMBNAIL_BLOCK:
              return (
                <Fragment key={`consultant-home-block-${index}`}>
                  <VideoWithThumbnailBlock {...content} title={createScriptTitle(content.title)} />
                  <CallOutBlock showBackground />
                </Fragment>
              );

            default:
              return null;
          }
        })}
      </ConditionalWrapper>
    </div>
  );
};

export default withFunctionalClassName(
  ComponentType.ORGANISM,
  'ConsultantHomeBlock',
)(ConsultantHomeBlock);
