import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';

import RecruitmentCtaBlock from 'components/organisms/RecruitmentCtaBlock';
import Loader from 'components/atoms/Loader';
import Wrapper from 'components/atoms/Wrapper';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import TextNew, {
  Colors,
  FontScaleType,
  FontScaleLineHeight,
  FontWeight,
} from 'components/atoms/TextNew';
import Icon from 'components/atoms/Icon';

import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

// Types
import DeviceStateType from 'components/types/DeviceState';
import PageDataType from '../../../types/PageData';

import careersPageNames from '../../../../../../data/enum/careersPageNames';

import CareersBlockContent from '../../molecules/CareersBlockContent';

import './careers-landing.scss';

type CareersLandingProps = {
  deviceState: DeviceStateType;
  loading: boolean;
  pageData: PageDataType;
};

const CareersLanding = (
  { deviceState, loading, pageData }: CareersLandingProps,
  {},
  className: string,
): JSX.Element => {
  const headerSelector = () => {
    if (!pageData) return null;

    if (deviceState >= DeviceState.XL) {
      return pageData?.ultraWideHeaderImage;
    } else if (deviceState >= DeviceState.LG) {
      return pageData?.desktopHeaderImage;
    } else if (deviceState > DeviceState.MD) {
      return pageData?.tabletHeaderImage;
    } else if (deviceState > DeviceState.SM) {
      return pageData?.largeMobileHeaderImage;
    }
    return pageData?.smallMobileHeaderImage;
  };

  return (
    <div className={className}>
      <HeroBarPublic image={headerSelector() || pageData?.desktopHeaderImage}>
        <h1 className="header-lockup">
          <TextNew
            localeId="careersLanding.line1"
            fontScaleLineHeight={FontScaleLineHeight.SMALL}
            fontScaleType={FontScaleType.SERIF_DISPLAY_THREE}
            weight={FontWeight.SEMI_BOLD}
            color={Colors.BRAND}
          />
          <TextNew
            cid="healthier"
            localeId="careersLanding.line2"
            color={Colors.BRAND}
            fontScaleLineHeight={FontScaleLineHeight.SMALL}
            fontScaleType={FontScaleType.SERIF_DISPLAY_TWO}
          />
          <TextNew
            cid="happier-place"
            localeId="careersLanding.line3"
            color={Colors.BRAND}
            fontScaleLineHeight={FontScaleLineHeight.SMALL}
            fontScaleType={FontScaleType.SCRIPT_DISPLAY_ONE}
          />

          <Icon
            name={IconName.UNDERLINE_SWOOSH_SMALL}
            width={deviceState >= DeviceState.LG ? 200 : 110}
          />
        </h1>
      </HeroBarPublic>

      {loading && <Loader />}

      {!loading && (
        <Wrapper cid="content-wrapper">
          {pageData?.description && (
            <RichTextBlock cid="description" text={pageData?.description} />
          )}
          {pageData?.subdescription && (
            <RichTextBlock cid="sub-description" text={pageData?.subdescription} />
          )}

          {pageData && <CareersBlockContent pageData={pageData} deviceState={deviceState} />}
        </Wrapper>
      )}

      <RecruitmentCtaBlock localePrefix={careersPageNames.LANDING} site="careers" />
    </div>
  );
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.TEMPLATE, 'CareersLanding')(CareersLanding),
);
