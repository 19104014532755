/* global WP_DEFINE_DEVELOPMENT */
import React from 'react';
import Route from 'react-router/lib/Route';
import NotFound from 'components/templates/NotFound';
import PolicyContentRoutes from 'components/templates/PolicyContent/routes';
import Pages from 'common/src/app/data/enum/Pages';
import { getRouteComponentProp } from 'common/src/app/util/routeCallback';
import ConfirmNewsletterSignupEmail from 'bundle-loader?lazy&reactHot&name=ConfirmNewsletterSignupEmail!../pages/NewsletterSignupConfirmation/components/templates/ConfirmNewsletterSignupEmail';
import ConfirmedNewsletterSignupEmailDeprecated from 'bundle-loader?lazy&reactHot&name=ConfirmedNewsletterSignupEmailDeprecated!../pages/NewsletterSignupConfirmation/components/templates/ConfirmedNewsletterSignupEmailDeprecated';
import SmartNotFound from 'bundle-loader?lazy&reactHot&name=SmartNotFound!components/pages/SmartNotFound';

import ContactUsRoutes from 'packages/components/pages/ContactUs/routes';
import Application from '../Application';
import HomeRoutes from '../pages/Home/routes';
import SuccessStoriesRoutes from '../pages/SuccessStories/routes';
import RecipesRoutes from '../pages/Recipes/routes';
import NearestGroupRoutes from '../pages/NearestGroup/routes';
import OurStoryRoutes from '../pages/OurStory/routes';
import StaticContentRoutes from '../pages/StaticContent/routes';
import HowItWorksRoutes from '../pages/HowItWorks/routes';
import BmiCalculatorRoutes from '../pages/BmiCalculator/routes';
import GroupOnlineComparison from '../pages/GroupOnlineComparison/routes';
import WhatHappensOnline from '../pages/WhatHappensOnline/routes';
import SevenDayMenuRoutes from '../pages/SevenDayMenu/routes';
import WhatHappensInGroupRoutes from '../pages/WhatHappensInGroup/routes';
import AdvertiseWithUsPageRoutes from '../pages/AdvertiseWithUsPage/routes';
import IcelandRoutes from '../pages/Iceland/routes';
import WhatCanIEat from '../pages/WhatCanIEat/routes';
import UserTesting from '../pages/UserTesting/routes';
import OffersRoutes from '../pages/Offers/routes';
import MagazineRoutes from '../pages/Magazine/routes';
import ScienceRoutes from '../pages/Science/routes';
import CyprusRoutes from '../pages/Cyprus/routes';
import FreeFoodFebruaryRoutes from '../pages/FreeFoodFebruary/routes';
import MentalHealthRoutes from '../pages/MentalHealth/routes';
import MagazineCompetitionsRoutes from '../pages/MagazineCompetitions/routes';
import BetterHealthRoutes from '../pages/BetterHealth/routes';
import PodcastsRoutes from '../pages/Podcast/routes';
import BodyMagicRoutes from '../pages/BodyMagic/routes';
import MythsRoutes from '../pages/Myths/routes';
import BecomeAConsultantRoutes from '../pages/BecomeAConsultant/routes';
import ComparisonPageRoutes from '../pages/ComparisonPage/routes';
import SlimmingWorldAppRoutes from '../pages/SlimmingWorldApp/routes';

// Careers
import CareersRoutes from '../pages/Careers/routes';

export default (
  <Route component={Application} title="{pageTitle}">
    {HomeRoutes}
    {SuccessStoriesRoutes}
    {RecipesRoutes}
    {NearestGroupRoutes}
    {OurStoryRoutes}
    {HowItWorksRoutes}
    {BmiCalculatorRoutes}
    {PolicyContentRoutes}
    {StaticContentRoutes}
    {GroupOnlineComparison}
    {WhatHappensOnline}
    {SevenDayMenuRoutes}
    {WhatHappensInGroupRoutes}
    {AdvertiseWithUsPageRoutes}
    {WhatCanIEat}
    {OffersRoutes}
    {MagazineRoutes}
    {ScienceRoutes}
    {CyprusRoutes}
    {IcelandRoutes}
    {FreeFoodFebruaryRoutes}
    {MentalHealthRoutes}
    {MagazineCompetitionsRoutes}
    {UserTesting}
    {BetterHealthRoutes}
    {PodcastsRoutes}
    {MythsRoutes}
    {BodyMagicRoutes}
    {BecomeAConsultantRoutes}
    {ComparisonPageRoutes}
    {SlimmingWorldAppRoutes}
    {ContactUsRoutes}
    {CareersRoutes}

    <Route
      path={Pages.CONFIRM_NEWSLETTER_SUBSCRIPTION}
      {...getRouteComponentProp(ConfirmNewsletterSignupEmail)}
      title="Seven day menu newsletter email confirmation | {pageTitle}"
    />
    <Route
      path={Pages.CONFIRM_NEWSLETTER_SUBSCRIPTION_DEPRECATED}
      {...getRouteComponentProp(ConfirmedNewsletterSignupEmailDeprecated)}
      title="Seven day menu newsletter email confirmation | {pageTitle}"
    />
    <Route
      path={Pages.UK_PUBLIC_SMART_CONTENT_NOT_FOUND_PAGE}
      {...getRouteComponentProp(SmartNotFound)}
      title="Content not found | {pageTitle}"
    />
    <Route status={404} path="*" component={NotFound} />
  </Route>
);
