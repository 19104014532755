import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import BackgroundImageWrapper from 'components/atoms/BackgroundImageWrapper';
import TextNew, { ElementTypes, Colors, FontFamily, FontSizes } from 'components/atoms/TextNew';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import Loader from 'components/atoms/Loader';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import ImageType from 'components/types/Image';
import RecruitmentCtaBlock from 'components/organisms/RecruitmentCtaBlock';

import RecruitmentBubbleBackgroundWrapper from 'components/atoms/RecruitmentBubbleBackgroundWrapper';

import ConsultantHomeBlock from '../../organisms/ConsultantHomeBlock';

import './consultant-landing.scss';

export type ConsultantLandingProps = {
  deviceState: number;
  heroImage: ImageType;
  loading: boolean;
  pageData: any; // @TODO
};

const ConsultantLanding = (
  { deviceState, heroImage, loading, pageData }: ConsultantLandingProps,
  {},
  className: string,
): JSX.Element => (
  <div className={className}>
    <header className="hero">
      <RecruitmentBubbleBackgroundWrapper hasWhiteBubbles={true}>
        <TextNew
          size={FontSizes.XL}
          family={FontFamily.SCRIPT}
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          localeId="consultantLanding.hero.topFirstLine"
        />
        <TextNew
          size={FontSizes.XL}
          element={ElementTypes.SPAN}
          family={FontFamily.SCRIPT}
          color={Colors.PRIMARY_LIGHT}
          cid="swoosh-text"
          localeId="consultantLanding.hero.topLastLine"
        >
          <Icon
            name={IconName.UNDERLINE_SWOOSH_SMALL}
            width={deviceState >= DeviceState.XL ? 240 : 124}
          />
        </TextNew>
        <TextNew
          size={FontSizes.SM}
          family={FontFamily.SERIF}
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          localeId="consultantLanding.hero.inBetween"
        />
        <TextNew
          size={FontSizes.XL}
          family={FontFamily.SERIF}
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          localeId="consultantLanding.hero.bottomFirstLine"
        />
        <TextNew
          size={FontSizes.XL}
          family={FontFamily.SERIF}
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          localeId="consultantLanding.hero.bottomLastLine"
        />
      </RecruitmentBubbleBackgroundWrapper>
      <BackgroundImageWrapper image={heroImage} />
    </header>

    {loading && <Loader />}

    <div className="content-wrapper">
      {!loading &&
        // eslint-disable-next-line react/prop-types
        pageData?.nestedContent.map(({ _type, ...content }: { _type: string }, index: number) => {
          if (_type === types.ROLE_BASED_CONTENT) {
            return <ConsultantHomeBlock {...content} key={`consultant-landing-block-${index}`} />;
          }

          return null;
        })}
    </div>

    <RecruitmentCtaBlock />
  </div>
);

export default withDeviceState()(
  withFunctionalClassName(ComponentType.TEMPLATE, 'ConsultantLanding')(ConsultantLanding),
);
