var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};
var select = function (value, data) {
  return {}.hasOwnProperty.call(data, value) ? data[value] : data.other;
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error("Can't apply offset:" + offset + ' to argument `' + name + '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};

export default {
  whatHappensOnline: {
    errorBoundaryTitle: function(d) { return "what happens online"; }
  },
  whatHappensInGroup: {
    errorBoundaryTitle: function(d) { return "What happens in a Slimming World group"; },
    groupCta: function(d) { return "Find a Slimming World Group"; }
  },
  whatCanIEat: {
    heading: {
      h1: function(d) { return "Lose weight eating \nthe food you love"; },
      h2: function(d) { return "Welcome to Food Optimising"; },
      introduction: function(d) { return "The most flexible eating plan ever, Food Optimising makes losing weight easy and enjoyable. There’s no calorie counting or deprivation – just delicious everyday foods"; }
    },
    intro: {
      title: function(d) { return "Food Optimising sets you free..."; },
      one: function(d) { return "cut calories without counting them!"; },
      two: function(d) { return "personalise meals to suit your tastes, dietary needs and budget"; },
      three: function(d) { return "eat in or dine out with your family and friends. There’s no need to make separate meals or miss out"; },
      link: function(d) { return "Find out more about the science behind Food Optimising"; }
    },
    foodOptimising: {
      title: {
        one: function(d) { return "Food Optimising"; },
        two: function(d) { return "essentials"; }
      },
      freefood: {
        title: function(d) { return "Fill up on Free Food"; },
        content: function(d) { return "Free Food is your new best friend and the hero of every great Food Optimising plate. Free Food includes hundreds of everyday foods you can eat without counting, without weighing or measuring and without a shred of guilt. They will fill you up, slim you down and help you build those important new healthy eating habits that will last a lifetime.\n\nFree Foods include lean meat, eggs, fish, pasta, potatoes, fruit and vegetables. They’re filling and low in calories for their weight — so you can eat as much Free Food as you like!"; }
      },
      healthyextra: {
        title: function(d) { return "Add measured \nHealthy Extras"; },
        content: function(d) { return "Designed to help to provide a good balance of nutrients, alongside your Free Food, Healthy Extras include milk and cheese for calcium, wholemeal bread and breakfast cereals for fibre and other essential minerals, and nuts and seeds for healthy oils."; }
      },
      syns: {
        title: function(d) { return "Enjoy a few Syns"; },
        content: function(d) { return "The foods that are least filling and are higher in calories, like biscuits, sweets and alcohol, count as Syns at Slimming World. Enjoying a little of what you fancy  — like a piece of chocolate or a glass of wine — means you won’t be tempted to go off track."; }
      }
    },
    typicalDay: {
      title: {
        one: function(d) { return "A typical day"; },
        two: function(d) { return "At last, an eating plan that fits into your life…"; }
      }
    },
    errorBoundaryTitle: function(d) { return "what can i eat"; }
  },
  userTesting: {
    headOfferAddress: function(d) { return "Slimming World Head Office,\nClover Nook Road, Alfreton,\nDerbyshire  DE55 4SW"; },
    userTestingButton: function(d) { return "User experience"; },
    oneToOneButton: function(d) { return "One-to-one"; },
    groupWorkshopButton: function(d) { return "Group workshop"; }
  },
  successStoriesLanding: {
    heading: {
      landing: function(d) { return "Real-life weight loss \nsuccess stories"; },
      female: function(d) { return "Real-life women’s \nweight loss success stories"; },
      male: function(d) { return "Real-life men’s \nweight loss success stories"; },
      p1: function(d) { return "Meet our members"; },
      introduction: function(d) { return "There’s no one better to tell you about Slimming World than our amazing members! Discover how they’ve achieved their fabulous weight losses"; }
    },
    snippet: {
      personName: function(d) { return d.FIRST_NAME + " " + d.LAST_NAME; },
      storySummary: function(d) { return "Astonished her GP and turned her health around after losing over 6 stones"; },
      storyLink: function(d) { return "Read " + d.FIRST_NAME + "’s story"; }
    },
    sortBy: function(d) { return "Sort by"; },
    pagination: function(d) { return d.limit + " Success Stories of " + d.total; },
    loadAll: function(d) { return "Show all"; },
    errorBoundaryTitle: function(d) { return "success story landing"; }
  },
  ukPublicSuccessStoryDetail: {
    related: function(d) { return "Read more of our"; },
    title: function(d) { return "success stories"; },
    disclaimer: function(d) { return "* Your weight loss will vary according to your individual circumstances and how much you have to lose."; },
    errorBoundaryTitle: function(d) { return "success story detail"; }
  },
  relatedSuccessStories: {
    title: function(d) { return "Real-life Slimming World"; },
    subtitle: function(d) { return "success stories"; },
    meet: function(d) { return "Meet our members"; },
    support: {
      subtitle: function(d) { return "support every step of the way"; }
    }
  },
  slimmingWorldApp: {
    errorBoundaryTitle: function(d) { return "the Slimming World App"; }
  },
  sevenDayMenu: {
    header: function(d) { return "free 7-day menu"; },
    taglineEntry: function(d) { return "Get a taster of how\nSlimming World works with your"; },
    taglineMenu: function(d) { return "Enjoy a taste of how\nSlimming World works with your"; },
    carousel: {
      title: function(d) { return "Let’s get you started!"; },
      title2: function(d) { return "Day " + d.ACTIVE; },
      headers: {
        breakfast: function(d) { return "Breakfast"; },
        lunch: function(d) { return "Lunch"; },
        dinner: function(d) { return "Dinner"; },
        snack: function(d) { return "Anytime snack"; }
      }
    },
    recipeLink: function(d) { return "Go to recipe"; },
    subtitle: function(d) { return "We’ve included a Healthy Extra ‘b’ choice each day and suggested some Healthy Extra ‘a’ choices throughout the week (feel free to make up your two daily ‘a’ choices however you fancy!)."; },
    noMenu: function(d) { return "Loading"; },
    errorBoundaryTitle: function(d) { return "the Seven Day menu"; }
  },
  foodOptimisingStep: {
    foodOptimisingTitlePartOne: function(d) { return "Food Optimising in"; },
    foodOptimisingTitlePartTwo: function(d) { return " 3 "; },
    foodOptimisingTitlePartThree: function(d) { return "easy steps..."; },
    freeFoodTitle: function(d) { return "Free Food"; },
    healthyExtrasTitle: function(d) { return "Healthy Extras"; },
    synsTitle: function(d) { return "Syns"; }
  },
  science: {
    heading: {
      h1: function(d) { return "Discover the best way to lose weight for life…"; },
      h2: function(d) { return "The science behind \nSlimming World"; },
      introduction: function(d) { return "Founded in 1969, Slimming World is the UK’s most advanced and effective weight management organisation. Every year, we help millions of people to lead a healthier lifestyle"; }
    },
    philosophy: {
      paragraph: function(d) { return "Slimming World’s philosophy is based on a deep understanding of how people with a weight problem feel, coupled with a passionate desire to help them achieve their goals. Over 50 years, our programme has been developed and refined in line with the latest thinking on nutrition, exercise and the psychology of behaviour change.\n\nSlimming World groups are run by a network of Consultants, all of whom have been successful group members, and who work in their local community. Consultants receive specific training in dietary aspects of weight management, the role of physical activity and, importantly, effective facilitation of behaviour change."; }
    },
    pillars: {
      title: function(d) { return "Our multi–component approach"; },
      subtitle: function(d) { return "Slimming World integrates three key principles to help people adopt a healthier lifestyle:"; },
      pillar0: {
        title: function(d) { return "A satisfying eating plan"; },
        description: function(d) { return "Food Optimising – which promotes healthy eating patterns for life."; }
      },
      pillar1: {
        title: function(d) { return "An activity programme"; },
        description: function(d) { return "Our unique physical activity programme is tailorable and suitable for all fitness and mobility levels."; }
      },
      pillar2: {
        title: function(d) { return "A warm, empowering group environment"; },
        description: function(d) { return "IMAGE Therapy – which supports members and facilitates behaviour change around eating and activity habits."; }
      }
    },
    foodOptimising: {
      title: function(d) { return "Food Optimising – a healthy, satisfying eating plan"; },
      para: function(d) { return "Food Optimising is a flexible eating plan designed to create healthy eating patterns that can be maintained long term. It’s based on nutrition science that shows that foods higher in protein and carbohydrate are more satiating than foods high in fat. Encouraging a higher intake of more satiating foods helps to limit energy intake and results in weight loss. Food Optimising combines the principles of energy density (the calories per gram in a food) and satiety (how filling a food is).\n\nA typical Food Optimising daily menu includes at least five portions of fruits and vegetables alongside a healthy amount of lean protein-rich foods and carbohydrates, measured portions of fibre- and calcium-rich foods, and a reduction in overall fat, saturated fat and free sugars (the sugars that are not naturally found in fruit and vegetables)."; },
      componentstitle: function(d) { return "The Food Optimising plan focuses on three components…"; },
      components: {
        component0: {
          title: function(d) { return "Free Foods"; },
          info: function(d) { return "Free Foods are those foods that satisfy the appetite while reducing overall energy intake. They include lean meats, poultry, fish, eggs, vegetables, fruit, grains, potatoes and pasta. We encourage members to eat them abundantly. They are what makes Food Optimising so easy to establish and sustain, compared to weight loss plans that leave people feeling hungry and deprived."; }
        },
        component1: {
          title: function(d) { return "Healthy Extras"; },
          info: function(d) { return "Healthy Extras are measured amounts of foods eaten each day to provide a good overall balance of nutrients in addition to those obtained from Free Food. There is particular emphasis on calcium- and fibre-rich foods, including cheese, cereals and wholemeal bread."; }
        },
        component2: {
          title: function(d) { return "Syns"; },
          info: function(d) { return "Syns are the way members can guiltlessly enjoy the foods that many weight loss plans ban outright. Counting Syns helps members naturally limit their intake of more energy-dense foods such as fats, alcohol and sugar. These foods have a poor ability to satisfy hunger and, when consumed in large amounts, make weight loss difficult."; }
        }
      }
    },
    bodyMagic: {
      title: function(d) { return "Body Magic – a rewarding\nstep-by-step activity programme"; },
      para: function(d) { return "Our Body Magic activity programme helps members gradually increase physical activity levels and improve overall health and wellbeing. It’s informed by a deep understanding of the barriers overweight people often face in becoming more active.\n\nDeveloped in collaboration with Professor Ken Fox, a renowned exercise and public health expert, author, and government advisor, Body Magic uses well–established psychological and scientific principles of behaviour change. It helps people find their own personal, enjoyable, and sustainable ways of increasing activity and making it a part of their daily routine."; },
      block: {
        you: function(d) { return "you"; },
        your: function(d) { return "your"; },
        title: function(d) { return "Body Magic"; },
        subtitlePartOne: function(d) { return "Our physical activity programme,"; },
        subtitleComma: function(d) { return ","; },
        subtitlePartTwo: function(d) { return " will help you to:"; },
        left: {
          "0": function(d) { return "break down any personal barriers, misconceptions or worries that may be stopping you from getting going"; },
          "1": function(d) { return "feel the amazing benefits that being physically active brings, which include having more energy and improved health and wellbeing. Plus, when you reach your dream weight, it can help you stay slim for life! "; },
          "2": function(d) { return "build – or build up – your active habits in a way that’s enjoyable and sustainable with the overall aim of making physical activity an intrinsic part of your life"; }
        },
        right: {
          "0": function(d) { return "find new physical activities you love – which give you the feel-good factor, suit " + d.YOU + " and fit into " + d.YOUR + " lifestyle"; },
          "1": function(d) { return "continually find ways to take things up a gear and get even more benefits from different active habits, such as building bone and muscle strength and improving flexibility and balance  "; },
          "2": function(d) { return "celebrate every milestone along the way with tonnes of praise and encouragement, from day one right up to your Platinum Body Magic award, where you’re meeting the government’s recommended guidelines and physical activity is just a way of life! "; }
        }
      }
    },
    group: {
      title: function(d) { return "A warm, empowering group environment"; },
      para: function(d) { return "Slimming World’s unique group support system is a rich weave of individual support in a powerfully motivating group setting. It avoids any criticism, control or judgement and uses elements of behaviour–change techniques including Transactional Analysis, Motivational Interviewing and the influence of ego states on behaviour to help people overcome their personal barriers to change and to avoid relapse.\n\nAt Slimming World we call this positive support structure IMAGE (Individual Motivation And Group Experience) Therapy.\n\nBased on a deep understanding of the psychology of overweight people, our support system is designed to increase the confidence and self–esteem of our members, while equipping them with tools to develop lasting self–determined behaviour change.\n\nOur support is based on the belief that the burden carried by overweight people is two–fold. There’s the burden of excess weight itself, but there’s also a far heavier burden to be lifted – that of shame, self–criticism and poor self–esteem. For many overweight people, successful long–term weight loss can only be achieved by tackling these deeper emotional issues and this support is only effective when it’s delivered with genuine warmth, compassion, empathy, respect and understanding.\n\nWe have a sincere belief in the power of group support – the power for individuals to support and be supported, to inspire and be inspired, and to take members through the phases of commitment to change. IMAGE Therapy guides members through psychological and behavioural commitment, reinforcing these consistently until behaviour becomes intrinsic, ie it has become important to our members and they’re no longer dependent on the group to receive reward or praise."; }
    },
    online: {
      title: function(d) { return "Our online service"; },
      para: function(d) { return "The weekly support provided to members in group is strengthened through our digital service, which members can access whenever they like.\n\nFor people who aren’t able to access a group, or prefer not to, we have an enhanced digital service, Slimming World Online, which enables members to follow our Food Optimising plan, our step–by–step activity programme and receive empowering support via an online community. The digital service includes an app for iOS and Android users, providing a rich library of supportive content and personalised tools that helps members find support based on their particular situation."; }
    },
    bottom: {
      title: function(d) { return "Want to"; },
      titleTwo: function(d) { return "know more?"; },
      info: function(d) { return "To find out even more about Slimming World’s approach and evidence base, visit our"; },
      link: function(d) { return "Health professionals website"; }
    },
    errorBoundaryTitle: function(d) { return "Science"; }
  },
  recipeLanding: {
    heading: {
      h1: function(d) { return "Delicious meal ideas"; },
      h2: function(d) { return "Enjoy fabulous food with Slimming World"; },
      introduction: function(d) { return "From classic healthy meals to the latest must-try dishes – Slimming World recipes are great for weight loss. As a member, you’ll discover hundreds more recipe ideas on our members-only app."; }
    },
    errorBoundaryTitle: function(d) { return "recipe landing"; }
  },
  publicRecipes: {
    pagination: function(d) { return d.limit + " Recipes of " + d.total; },
    search: function(d) { return "Search"; },
    sortBy: function(d) { return "Sort by"; },
    loadAll: function(d) { return "Show all"; },
    results: {
      serves: function(d) { return "Serves:"; },
      readyIn: function(d) { return "Ready in:"; },
      synsPerServing: function(d) { return "Syns per serving:"; }
    }
  },
  paginationLabel: {
    pagination: function(d) { return d.LIMIT + " Recipes of " + d.TOTAL; },
    showAll: function(d) { return "Show all"; }
  },
  recipeDetail: {
    sevenDayMenu: {
      title: function(d) { return "Why not try"; },
      title2: function(d) { return "more amazing recipes"; }
    },
    errorBoundaryTitle: function(d) { return "recipe detail"; }
  },
  podcastLanding: {
    title: function(d) { return "Slimming World"; },
    subTitle: function(d) { return "podcast"; },
    categories: function(d) { return "Categories"; }
  },
  ourStory: {
    errorBoundaryTitle: function(d) { return "Our Story"; }
  },
  offers: {
    errorBoundaryTitle: function(d) { return "Offers"; }
  },
  confirmedNewsletterSignupEmailDeprecated: {
    title: function(d) { return "Confirming email failed"; },
    subTitle: function(d) { return "We're sorry, this page is expired.\n\nPlease sign up again to get our exclusive 7-day menu."; },
    errors: {
      errorBoundaryTitle: function(d) { return "confirm newsletter signup email"; }
    }
  },
  confirmNewsletterSignupEmail: {
    title: function(d) { return "You're good to go!"; },
    subTitle: function(d) { return "Thank you for confirming your email address. Click the link below to see your 7-day menu."; },
    linkLabel: function(d) { return "7-day menu"; },
    errors: {
      unknown: {
        title: function(d) { return "Confirming email failed"; },
        subTitle: function(d) { return "We're sorry, we don't recognise this link\n\nPlease sign up again to get our exclusive 7-day menu."; }
      },
      invalid: {
        title: function(d) { return "Confirming email failed"; },
        subTitle: function(d) { return "We're sorry, we don't recognise this link\n\nPlease sign up again to get our exclusive 7-day menu."; }
      },
      expired: {
        title: function(d) { return "Confirming email failed"; },
        subTitle: function(d) { return "We're sorry, this link has expired\n\nPlease sign up again to get our exclusive 7-day menu."; }
      },
      errorBoundaryTitle: function(d) { return "confirm newsletter signup email"; }
    }
  },
  groupsInATown: {
    groups: {
      explainer: function(d) { return "Slimming World Groups in " + d.TOWN + d.COUNTY; }
    }
  },
  nearestGroupTownsInCounty: {
    town: {
      name: function(d) { return "town"; },
      description: function(d) { return "Here are all of the towns in " + d.COUNTY + " that have a Slimming World group."; }
    }
  },
  nearestGroupLandingHeader: {
    resultsFor: function(d) { return "Your search results for"; },
    yourLocation: function(d) { return "Your current location"; }
  },
  emptySearchResults: {
    postcode: {
      titleIntro: function(d) { return "Sorry, there are currently"; },
      titleNoGroups: function(d) { return "no groups in your area"; },
      detail: function(d) { return "Have you taken a look at our online-only service?"; }
    },
    filter: {
      titleIntro: function(d) { return "We're sorry we have "; },
      titleNoGroups: function(d) { return "no groups matching your search"; },
      detail: function(d) { return "Please try updating your filters."; }
    },
    moreAction: function(d) { return "Find out more"; }
  },
  emptySearchQuery: {
    titleEmphasis: function(d) { return "Start your journey to a"; },
    titleOutro: function(d) { return "slimmer, healthier you today!"; }
  },
  nearestGroupDetail: {
    intro: {
      one: function(d) { return "Ready to join"; },
      two: function(d) { return "Slimming World?"; }
    },
    successStories: {
      title: function(d) { return "read about our members"; },
      subtitle: function(d) { return "success stories"; },
      meet: function(d) { return "Meet our members"; }
    },
    persuade: {
      minititle: function(d) { return "Two great ways to"; },
      title: function(d) { return "lose weight"; }
    },
    errorBoundaryTitle: function(d) { return "Nearest group detail"; }
  },
  nearestGroupDetails: {
    title: function(d) { return d.NAME + " at " + d.PLACE; },
    profileButton: function(d) { return d.NAME + "'s Profile"; },
    consultantDetails: {
      consultant: function(d) { return "Your Consultant is"; }
    },
    profileDetails: {
      address: function(d) { return "Address"; },
      telephone: function(d) { return "Telephone"; },
      email: function(d) { return "Email address"; },
      venue: function(d) { return "Address"; },
      payments: function(d) { return "Payments"; },
      sessions: function(d) { return "Group start times"; },
      Christmas: function(d) { return "Christmas Week Group Times"; },
      NewYear: function(d) { return "New Year Week Group Times"; },
      opens: function(d) { return "New group opening – " + d.DATE; }
    },
    venue: {
      wheelChairAccess: function(d) { return "Wheel Chair Access"; },
      disabledParking: function(d) { return "Disabled Parking"; },
      disabledToilet: function(d) { return "Disabled Toilet"; }
    },
    disclaimer: function(d) { return "If you have any questions at all, please give your Consultant a call – they'll be happy to help and there’ll be a warm welcome waiting for you."; },
    otherGroupButton: function(d) { return "Other groups near you"; },
    noSuitableGroup: function(d) { return "Can't find a group near you?"; },
    getDirections: function(d) { return "Get directions"; }
  },
  nearestGroupCounties: {
    title1: function(d) { return "Find your"; },
    title2: function(d) { return "Slimming World"; },
    title3: function(d) { return "group"; },
    county: {
      description: function(d) { return "Counties in the UK that have "; },
      link: function(d) { return "Slimming World Groups"; }
    },
    errorBoundaryTitle: function(d) { return "nearest group counties"; }
  },
  nearestGroupTile: {
    groupTile: {
      distanceDescription: function(d) { return "(" + d.DISTANCE + " miles)"; },
      getDirections: function(d) { return "Get directions"; },
      bankHolidayTitle: function(d) { return "Festive Fortnight Changes"; },
      bankHolidaySubTitles: {
        christmas: function(d) { return "Christmas"; },
        newYear: function(d) { return "New Year"; }
      },
      venueChanged: function(d) { return "Venue has changed for " + d.DAYS + ". Please call me."; },
      additionalGroups: function(d) { return "I'm running extra sessions. Please call me for details."; }
    },
    consultant: function(d) { return "Consultant"; },
    telephone: function(d) { return "Telephone"; },
    address: function(d) { return "Address"; },
    opens: function(d) { return "New group opening – " + d.DATE; },
    venueAccessibility: function(d) { return "Venue accessibility"; }
  },
  myths: {
    heading: {
      h1: function(d) { return "Your burning questions answered"; },
      h2: function(d) { return "Everything you ever wanted to know about Slimming World"; },
      introduction: function(d) { return "We tackle common weight loss myths and bring you the lowdown on Slimming World groups and our unique weight loss programme"; }
    },
    errorBoundaryTitle: function(d) { return "Slimming World myths"; }
  },
  mentalHealth: {
    quiz: {
      title: function(d) { return "Changing the way we think about our mental health"; },
      bold: function(d) { return "Slimming World understands that looking after our mental health is essential for good overall health and wellbeing – which is why we continue to fully support " + d.LINK + ", the government’s mental health campaign.\n\n"; },
      body: function(d) { return "We know that being unhappy with our weight can lead to self-criticism, shame and low self-esteem, while things like stress, poor sleep and low mood can all affect our eating habits. Good mental health helps us to feel relaxed more often and cope better with day-to-day events and challenges – and can lead to us achieving more.\n\nSince its launch in 2018, Every Mind Matters has offered expert advice and practical tips to help you look after your mental health and wellbeing. There are special sections on the website for supporting others and dealing with life’s challenges, too. We love the health-boosting ideas, from eating well and being active to enjoying more me-time and building supportive relationships – all things that we support our members with every day. You can even get a free personalised ‘Mind Plan’: just answer five quick questions to get your plan with tips to help you deal with stress and anxiety, improve your sleep, boost your mood and feel more in control.\n\n"; },
      cta: function(d) { return "Take the quiz and create your personal Every Mind Matters Mind Plan here."; }
    },
    link: function(d) { return "Every Mind Matters"; },
    health: {
      title: function(d) { return "Mental health"; },
      subtitle: function(d) { return "Finding help and support"; },
      copy: function(d) { return "One in four of us will experience a mental health problem – and whether you’ve been diagnosed with a specific condition or you feel you’re struggling to cope at the moment, recognising how you’re feeling and finding the right support can really help. If you’re concerned about any aspect of your mental health, talk to your GP or tap the links below for information on support where you live:"; }
    },
    england: {
      title: function(d) { return "England"; },
      one: function(d) { return "NHS Mental Health Services"; },
      two: function(d) { return "Better Health"; },
      three: function(d) { return "Mental Health UK"; },
      four: function(d) { return "Mind"; },
      five: function(d) { return "Samaritans"; }
    },
    scotland: {
      title: function(d) { return "Scotland"; },
      one: function(d) { return "NHS Inform Mental Health"; },
      two: function(d) { return "Breathing Space"; },
      three: function(d) { return "Clear your head"; },
      four: function(d) { return "Scottish Association for Mental Health"; },
      five: function(d) { return "Mental Health UK"; },
      six: function(d) { return "Samaritans"; }
    },
    wales: {
      title: function(d) { return "Wales"; },
      one: function(d) { return "Mental Health Helpline for Wales"; },
      two: function(d) { return "Public Health Wales"; },
      three: function(d) { return "Mental Health Wales"; },
      four: function(d) { return "Mental Health UK"; },
      five: function(d) { return "Mind"; },
      six: function(d) { return "Samaritans"; }
    },
    northern: {
      title: function(d) { return "Northern Ireland"; },
      one: function(d) { return "Action Mental Health"; },
      two: function(d) { return "NI Direct"; },
      three: function(d) { return "Lifeline"; },
      four: function(d) { return "Mental Health UK"; },
      five: function(d) { return "Samaritans"; }
    },
    roi: {
      title: function(d) { return "Ireland"; },
      one: function(d) { return "Mental Health Ireland"; },
      two: function(d) { return "Your Mental Health"; },
      three: function(d) { return "Samaritans"; }
    }
  },
  magazine: {
    promo: {
      title: function(d) { return "Buy yours in"; },
      titleTwo: function(d) { return "group"; },
      detail: function(d) { return "The " + d.ISSUE + " issue of " + d.ITALICS + " is on sale now at a Slimming World group near you, where you’ll pay our best-offer price of just " + d.PRICE; },
      detailMagazine: function(d) { return "Slimming World Magazine"; },
      button: function(d) { return "subscribe"; },
      groupRoundel: {
        bottom: function(d) { return "cheaper in\ngroup"; }
      },
      roundelTop: function(d) { return "Subscribe\n& save"; }
    }
  },
  printMagazinePromo: {
    title: function(d) { return "Print"; },
    subTitle: function(d) { return "edition"; },
    roundel: function(d) { return "Subscribe\n& save"; },
    imageCaption: function(d) { return "Cover page of Slimming World magazine"; },
    button: function(d) { return "Subscribe"; },
    introduction: function(d) { return "On sale in all good newsagents and major supermarkets from " + d.DATE + " "; },
    price: function(d) { return "priced " + d.PRICE + " – or subscribe and save 20% on the full cover price."; },
    dataProtection: {
      heading: function(d) { return "Data Protection: "; },
      productName: function(d) { return "Slimming World Magazine "; },
      disclaimer: function(d) { return "subscriptions are provided on behalf of Slimming World by Select Publisher Services. By taking out a subscription, you are entering into an agreement with Select Publisher Services and all personal information will be held in accordance with their privacy policy."; }
    }
  },
  digitalMagazinePromo: {
    title: function(d) { return "Digital"; },
    titleTwo: function(d) { return "edition"; },
    roundel: function(d) { return "Subscribe\n& save"; },
    mainContent: {
      single: {
        intro: function(d) { return "Single issue"; }
      },
      annual: {
        intro: function(d) { return "Annual subscription"; },
        issues: function(d) { return "7 issues"; }
      }
    },
    platform: {
      title: function(d) { return "Available on the following platforms"; },
      description: function(d) { return "Download our app on Apple App Store or Google Play to find your free sample of Slimming World Magazine and discover why we’re the UK’s No 1 slimming magazine!"; }
    }
  },
  icelandOurRange: {
    filter: function(d) { return "Filter products"; },
    noProducts: function(d) { return "Sorry, no products match your filters"; },
    filterDisclaimer: function(d) { return "*Although the recipe does not contain gluten, dairy or egg where stated, these products are manufactured in an environment where gluten /diary /egg is processed."; },
    cta: {
      recipeButtonText: function(d) { return "Make me at home"; }
    }
  },
  icelandLanding: {
    allergens: function(d) { return "Read more on allergens and dietary info "; },
    sections: {
      meals: {
        title: function(d) { return "Meals"; },
        description: function(d) { return d.MEALS + " satisfying meals, from " + d.PRICE + " each"; }
      },
      sides: {
        title: function(d) { return "Side dishes"; },
        description: function(d) { return d.MEALS + " tasty sides, from " + d.PRICE + " each"; }
      },
      meats: {
        title: function(d) { return "Meat and meat alternatives"; },
        description: function(d) { return d.MEALS + " delicious dishes, from " + d.PRICE + " each"; }
      },
      soup: {
        title: function(d) { return "Soup"; },
        description: function(d) { return d.MEALS + " warming soup pots, from " + d.PRICE + " each"; }
      },
      sauces: {
        title: function(d) { return "Sauces"; },
        description: function(d) { return d.MEALS + " mouth-watering sauces, " + d.PRICE + " each"; }
      }
    },
    viewRangeCta: function(d) { return "View the range"; },
    errorBoundaryTitle: function(d) { return "Iceland landing page"; }
  },
  icelandRecipeInspiration: {
    title: function(d) { return "Recipe inspiration"; }
  },
  icelandCategoryCarousel: {
    button: function(d) { return "View full range"; }
  },
  icelandOurStoryPromo: {
    buttonText: function(d) { return "Our story"; }
  },
  icelandOurProductsPromo: {
    buttonText: function(d) { return "Our products"; }
  },
  icelandHeader: {
    title: {
      top: function(d) { return "Slimming World"; },
      bottom: function(d) { return "Free Food"; }
    },
    button: function(d) { return "View full range"; }
  },
  icelandAdvertisement: {
    buttonText: function(d) { return "View the range"; }
  },
  icelandProductBanner: {
    warehouse: function(d) { return "Only available at"; },
    reStock: function(d) { return "Back on sale"; }
  },
  howItWorks: {
    errorBoundaryTitle: function(d) { return "How it works"; }
  },
  ukHome: {
    main: {
      greeting: function(d) { return "Discover a world of weight loss"; },
      title: function(d) { return "without dieting"; },
      intro: function(d) { return "Slimming World's unique healthy eating plan, Food Optimising, is based on science, backed by evidence and loved by millions. Find out more below..."; }
    },
    persuade: {
      intro: function(d) { return "Slimming world is here to help guide you to whatever you’d like to be. No gimmicks, no pressure, just great advice on how to make a few simple changes. Changes which will help you loose weight whilst enjoying wonderfully delicious meals."; },
      leadin: function(d) { return "Why not choose from..."; }
    },
    errorBoundaryTitle: function(d) { return "home"; }
  },
  colorCarousel: {
    buttonLabels: {
      "0": {
        button: function(d) { return "Learn more"; }
      },
      "1": {
        button: function(d) { return "How it works"; }
      },
      "2": {
        button: function(d) { return "Learn more"; }
      }
    },
    navigation: {
      prevLabel: function(d) { return "Back to the previous slide"; },
      nextLabel: function(d) { return "Go to the next slide"; }
    }
  },
  groupOrOnlineContentWrapper: {
    group: {
      tabHeading: function(d) { return "Group"; }
    },
    online: {
      tabHeading: function(d) { return "Digital"; }
    }
  },
  freeFoodFebruary: {
    hero: {
      one: function(d) { return "love  it"; },
      two: function(d) { return "cook  it"; },
      three: function(d) { return "share it!"; }
    },
    intro: {
      one: function(d) { return "Free Food February"; },
      two: function(d) { return "at"; },
      three: function(d) { return "Slimming World"; },
      four: function(d) { return "Free Food is the stroke of genius behind Food Optimising that makes losing weight with Slimming World so incredibly different, so satisfying and so, so easy! Simply put, they’re the hundreds of foods that you can eat to your heart’s (or tummy’s) content – without weighing, measuring or counting and without ever going hungry!\n\nDuring the whole of February we’ll be celebrating all of these Free Foods in a Slimming World group near you – we’ll be getting excited about menus, ideas and recipes where Free Food is the star of the show – and losing weight like a dream.\n\nJoin in with Slimming World’s Free Food February and discover the super slimming power of Free Food for yourself. Find your nearest group "; },
      five: function(d) { return "here."; }
    },
    roundel: {
      one: function(d) { return "Get a"; },
      two: function(d) { return "free book"; },
      three: function(d) { return "with a 12-week"; },
      four: function(d) { return "Countdown or"; },
      five: function(d) { return "resubscription"; },
      six: function(d) { return "worth " + d.PRICE; }
    },
    bookOffer: {
      one: function(d) { return "Slimming World’s Free Food on the Go"; },
      two: function(d) { return "Irresistible offers running throughout February in Slimming World groups from 4th February until 2nd March"; },
      bulletpoint: {
        one: function(d) { return "a free copy of Slimming World’s Free Food on the Go recipe book (worth £4.95) when you commit to your weight loss with a 12-week Countdown (or Countdown extension) in group, which gives you 12 weeks for the price of 10"; },
        two: function(d) { return "plus get a free week for every new member you introduce to your group"; }
      },
      three: function(d) { return "With so much fabulous Free Food inspiration to choose from, you’ll be able to eat on the go and achieve your weight loss dream, wherever life takes you! Even more great reasons to discover a fun and friendly Slimming World group near you. "; }
    },
    fffVideo: {
      one: function(d) { return "Watch the"; },
      two: function(d) { return "Free Food February"; },
      three: function(d) { return "video"; }
    },
    errorBoundaryTitle: function(d) { return "Free food February"; }
  },
  cyprus: {
    title: {
      intro: function(d) { return "Find your nearest"; },
      main: function(d) { return "Slimming World"; },
      group: function(d) { return "group"; }
    },
    details1: function(d) { return "A warm, friendly Slimming World group is the very best place to lose weight, achieve your target and stay there for life!"; },
    details2: function(d) { return "Select your town below to find your nearest session…"; },
    placeholder: function(d) { return "Please select your town"; },
    virtualGroups: {
      header: function(d) { return "Slimming World groups update"; },
      description: function(d) { return "Our community groups are open and running. If you have any questions at all, please give your Consultant a call – they’ll be happy to help and there’ll be a warm welcome waiting for you."; }
    }
  },
  joinTheHeadOfficeTeam: {
    line1: function(d) { return "an inspiring\nplace to work!"; }
  },
  careersImageSlideshow: {
    header: {
      line1: function(d) { return "meet " + d.THE; },
      line2: function(d) { return "the"; },
      line3: function(d) { return " teams"; }
    },
    departments: {
      magazineAndPublications: {
        name: function(d) { return "Magazine and Publications"; },
        description: function(d) { return "Whether it’s our award-winning magazine, top-selling member cookbooks or weekly Slimming World Kitchen mag, these teams ensure our inspiring resources surprise and delight every time."; }
      },
      marketingCommunicationsAndDesign: {
        name: function(d) { return "Marketing, communications and design"; },
        description: function(d) { return "The expert teams behind our beautifully designed content and communications for our internal and external channels, social media, advertising, PR activity and events."; }
      },
      foodNutritionAndResearch: {
        name: function(d) { return "Food, nutrition and research"; },
        description: function(d) { return "Our teams of dieticians, nutritionists and researchers keep the Slimming World plan safe and effective for everyone. Through our research, we stay at the cutting edge of developments in weight loss and satiety."; }
      },
      customerServices: {
        name: function(d) { return "Customer services"; },
        description: function(d) { return "Our support teams work tirelessly to support our field-based Consultants, our online, group and referral members and everyone in-between to ensure they receive an exceptional service."; }
      },
      technologyAndDigital: {
        name: function(d) { return "Technology and digital"; },
        description: function(d) { return "From developers and data specialists to cyber security and UX/UI designers, our teams create and maintain our services, websites and apps to ensure Slimming World’s digital experiences go from strength to strength."; }
      },
      businessDevelopment: {
        name: function(d) { return "Business development"; },
        description: function(d) { return "Our business development teams provide our members with a seamless Slimming World experience – online and in our groups – ensuring they’re supported, inspired and cared for every step of their journey."; }
      },
      facilitiesAndWarehouse: {
        name: function(d) { return "Facilities and warehouse"; },
        description: function(d) { return "Our teams behind the scenes keep us running on rails, whether that’s preparing delicious meals in the restaurant, looking after our Head Office estate or dispatching millions of parcels each year from our state-of-the-art distribution centre."; }
      },
      businessServices: {
        name: function(d) { return "Business services"; },
        description: function(d) { return "From first-class training at the Slimming World Academy to teams who are dedicated to protecting our brand and developing our service. Not forgetting HR and Finance, and a sprinkle of glamour from our onsite Hair & Beauty team! "; }
      }
    }
  },
  careersLanding: {
    line1: function(d) { return "help us make the"; },
    line2: function(d) { return "world a healthier,"; },
    line3: function(d) { return "happier place"; }
  },
  careersJobVacanciesFeed: {
    title: function(d) { return "Current vacancies"; },
    subtitle: function(d) { return "For more information, please click on any role that interests you..."; }
  },
  careersFaqs: {
    title: {
      start: function(d) { return "your questions "; },
      end: function(d) { return "answered"; }
    }
  },
  becomeADistrictManager: {
    line1: function(d) { return "change lives as a Slimming World District Manager"; }
  },
  careersThreeStages: {
    description: function(d) { return "Before you become a Slimming World District Manager, we’ll make sure you’re set for success. We’ll provide a comprehensive induction and training programme to equip you in every aspect of our field-based service, with dedicated support from an experienced management and training team. We’ll be with you every step of the way."; },
    stageTitle: function(d) { return "stage"; },
    stage1: {
      body: function(d) { return "run a Slimming World group and deliver " + d.SERVICE + " to members"; },
      description: function(d) { return "You'll step into the shoes of a Slimming World Consultant and launch, promote and develop your own group to help members in your community lose weight and achieve lasting success."; }
    },
    stage2: {
      body: function(d) { return d.INSPIRE + " a group of Consultants as a Team Developer"; },
      description: function(d) { return "You'll then lead a team of Consultants - and achieve even greater success together - all while soaking up incredible training from the Slimming World Academy."; }
    },
    stage3: {
      body: function(d) { return "become a \n" + d.DISTRICT + " \n" + d.MANAGER; },
      description: function(d) { return "Finally you'll build on this strong foundation to lead your new district to success - with an ongoing programme of training and development to see you and your district fly. If you've joined the team as a successful member you can expect to be fully in your district around ten months into your career journey."; }
    }
  },
  careersSlidingOverlay: {
    circle1: {
      description: function(d) { return d.LEAD + " self-employed Consultants and Team Developers to achieve success"; }
    },
    circle2: {
      description: function(d) { return d.BUILD + " on the district where everyone works within our values, culture and Slimming World Methods"; }
    },
    circle3: {
      description: function(d) { return d.COACH + " Team Developers and Consultants by providing exceptional support through weekly calls, 1:1s and observation"; }
    },
    circle4: {
      description: function(d) { return "Shape and drive " + d.RECRUITEMENT + " to grow and develop your district to its full potential"; }
    },
    circle5: {
      description: function(d) { return "Deliver engaging and impactful " + d.TRAINING; }
    },
    circle6: {
      description: function(d) { return "Regular " + d.VISITS + " and to attend our National Field meetings held at least every two months – an injection of support, motivation and sharing!"; }
    }
  },
  careersFieldDetails: {
    stageTitle: function(d) { return "stage"; },
    stage1: {
      description: function(d) { return "We have more than " + d.NUMBER_OF_GROUPS + " across the UK and Ireland. Our groups fall into geographical " + d.DISTRICTS; }
    },
    stage2: {
      description: function(d) { return "Each district is part of a wider " + d.REGION + ", led by a Senior Manager"; }
    },
    stage3: {
      description: function(d) { return "And the whole Field is supported by our " + d.HEAD_OFFICE_TEAMS + ", based in Derbyshire."; }
    }
  },
  bmiCalculator: {
    heading: {
      h2: function(d) { return "Check your BMI"; }
    },
    paragraphOne: function(d) { return "Body Mass Index (BMI) is a handy guide to the healthy weight range for your height."; },
    paragraphTwo: function(d) { return "At Slimming World we’ll never tell you what you should weigh or what your personal target weight should be. If you’d like some help in deciding your weight loss goal, though, the Body Mass Index (BMI) is a measure that can be used to find out the healthy weight range for your height.\n\nFor most adults, an ideal BMI is in the 18.5 to 24.9 range. To find out your current BMI, use our BMI calculator.\n\nNote: A BMI guide may not be accurate for people with a high muscle mass, or mums-to-be, and shouldn’t be used for under-16s. The point at which someone’s health is at risk due to their weight also varies between ethnic groups."; },
    form: {
      title: function(d) { return "Slimming World BMI calculator"; },
      intro: function(d) { return "Simply fill in the form below to see your results"; },
      button: function(d) { return "Calculate"; }
    },
    groupLabels: {
      weight: function(d) { return "Enter your weight"; },
      height: function(d) { return "Enter your height"; }
    },
    result: {
      title: function(d) { return "Your BMI is: " + d.BMI; },
      paragraph: {
        weightTooLow: {
          locale: {
            first: function(d) { return "From the weight and height you’ve provided, your BMI suggests that you’re underweight for your height."; },
            second: function(d) { return "Intentionally trying to lose weight to reach and stay at this BMI can be unhealthy and you may not be eating enough food to supply your body with all the nutrients it needs.\n\nIf you do have any concerns about your weight, we recommend you discuss them with your doctor or nurse."; }
          }
        },
        healthyWeightBottomRange: {
          locale: {
            first: function(d) { return "Fantastic! Your BMI suggests that you're a healthy weight for your height."; },
            second: function(d) { return "This means you’re at least risk of developing weight-related health problems. For the majority of people within this range, a focus on maintaining weight would be most suitable."; }
          }
        },
        healthyWeight: {
          locale: {
            first: function(d) { return "Fantastic! Your BMI suggests that you’re a healthy weight for your height."; },
            second: function(d) { return "This means you’re at least risk of developing weight-related health problems. For the majority of people within this range, a focus on maintaining weight would be most suitable.\n\nFor our members' safety, at Slimming World we have minimum joining and personal target weights. To find out more, contact " + d.LINK; }
          },
          links: {
            linkText: function(d) { return "your local Slimming World Consultant"; }
          }
        },
        healthyWeightTopRange: {
          locale: {
            first: function(d) { return "Fantastic! Your BMI suggests that you’re a healthy weight for your height."; },
            second: function(d) { return "This means you’re at least risk of developing weight-related health problems. For the majority of people within this range, a focus on maintaining weight would be most suitable.\n\nIf you’re concerned about your weight, though, and would be interested in joining a Slimming World group, contact " + d.LINK + " to find out more. Or, if you’d like to join Slimming World Online, " + d.LINK2 + "."; }
          },
          links: {
            linkText: function(d) { return "your local Consultant"; },
            link2Text: function(d) { return "here’s how"; }
          }
        },
        atRiskLower: {
          locale: {
            first: function(d) { return "Your BMI suggests that you may be at risk of developing weight-related problems"; },
            second: function(d) { return "Losing weight can help reduce this risk and also improve existing health conditions. You’ll find expert support in a warm and powerfully motivating " + d.LINK + " – or on " + d.LINK2 + " – to achieve a healthy weight without needing to give up the foods you love."; }
          },
          links: {
            linkText: function(d) { return "Slimming World group"; },
            link2Text: function(d) { return "Slimming World Online"; }
          }
        },
        atRiskHigher: {
          locale: {
            first: function(d) { return "Your BMI suggests that your health is at greater risk from weight-related problems, including heart disease and type 2 diabetes."; },
            second: function(d) { return "Losing weight can help reduce this risk and also improve existing health conditions. You’ll find expert support in a warm and powerfully motivating " + d.LINK + " – or on " + d.LINK2 + " – to achieve a healthy weight without needing to give up the foods you love."; }
          },
          links: {
            linkText: function(d) { return "Slimming World group"; },
            link2Text: function(d) { return "Slimming World Online"; }
          }
        }
      }
    },
    errors: {
      height: {
        required: function(d) { return "Please enter your height."; }
      },
      initialWeight: {
        required: function(d) { return "Please enter your weight."; }
      }
    },
    errorBoundaryTitle: function(d) { return "BMI calculator"; }
  },
  betterHealth: {
    errorBoundaryTitle: function(d) { return "better health"; }
  },
  ourFranchisePackage: {
    title: {
      start: function(d) { return "our franchise"; },
      end: function(d) { return "package"; }
    }
  },
  ourConsultants: {
    title: {
      start: function(d) { return "our"; },
      end: function(d) { return "Consultants"; }
    }
  },
  franchiseFacts: {
    title: {
      start: function(d) { return "franchise"; },
      end: function(d) { return "facts"; }
    }
  },
  consultantLanding: {
    hero: {
      topFirstLine: function(d) { return "touch hearts,"; },
      topLastLine: function(d) { return "change lives"; },
      inBetween: function(d) { return "as a"; },
      bottomFirstLine: function(d) { return "Slimming World"; },
      bottomLastLine: function(d) { return "Consultant"; }
    }
  },
  consultantEnquirySuccess: {
    thankYou: function(d) { return "Thank you"; },
    header: function(d) { return " for your interest in becoming a Slimming World Consultant"; },
    moreInfo: function(d) { return "We'll be in touch soon with your invitation to our next Opportunity Event in your area, where you'll discover more about this rewarding role."; }
  },
  consultantBlock: {
    cta: function(d) { return "Watch"; }
  },
  callOutBlock: {
    block1: function(d) { return "<span>enjoy the</span> *freedom* <span>and flexibility of self-employment</span>"; },
    block2: function(d) { return "*grow* <span>your own</span> <span>Slimming World group</span>"; },
    block3: function(d) { return "<span>make</span> *dreams come true*"; }
  },
  venueAccessibility: {
    options: {
      wheelchairAccess: function(d) { return "Wheelchair access"; },
      disabledToilet: function(d) { return "Disabled toilet"; },
      disabledParking: function(d) { return "Disabled parking"; },
      hearingLoop: function(d) { return "Hearing loop"; }
    }
  },
  newsletterSignUp: {
    title: function(d) { return "Would you love a taste of Slimming World?"; },
    info: {
      title: function(d) { return "Free 7-day\neating plan"; },
      content: function(d) { return "Eat the Slimming World way for a week! Sign up now for our classic 7-day menu, or our vegetarian or vegan option. Plus you'll also get our email newsletter."; },
      leadin: function(d) { return "Find more easy recipes online"; }
    },
    label: {
      name: function(d) { return "First name"; },
      email: function(d) { return "Email address"; },
      radioButton: {
        classic: function(d) { return "I’d like the classic menu"; },
        vegetarian: function(d) { return "I’d like the vegetarian menu"; },
        vegan: function(d) { return "I’d like the vegan menu"; }
      }
    },
    placeholders: {
      name: function(d) { return "Enter your first name"; },
      email: function(d) { return "Enter your email address"; },
      checkbox: function(d) { return "I am a vegetarian"; }
    },
    submit: function(d) { return "Sign me up"; },
    errorBoundaryTitle: function(d) { return "Newsletter sign-up"; },
    linkText: function(d) { return " Go to the 7 day menu​"; },
    errors: {
      firstName: {
        validator: function(d) { return "Please enter a valid first name."; }
      },
      blankRecaptcha: function(d) { return "Please select the ‘I’m not a robot’ checkbox"; },
      invalidRecaptcha: function(d) { return "Captcha failed"; },
      existingUnverifiedSubscription: function(d) { return "We've previously emailed you a confirmation link. Please check your email inbox."; },
      alreadySubscribed: function(d) { return "Good news, you're already on our email list.\n\rLook out for emails from us packed full of recipe inspiration.\n\r\n\r​" + d.MENU_LINK; },
      generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; }
    }
  },
  newsletterSignUpSubmittedSuccessMessage: {
    title: function(d) { return "Thank you"; },
    subtitle: function(d) { return "for signing up to receive our 7-day menu"; },
    verifyEmail: function(d) { return "We just need you to verify your email address."; },
    description: function(d) { return "Please check your inbox and click on the verification link so we can let you know about new menus, recipes and offers."; }
  },
  application: {
    subTitle: function(d) { return "Please fill in the form below and we’ll get back to you as soon as we can"; },
    groupLabels: {
      PersonalInfo: function(d) { return "Personal information"; },
      message: function(d) { return "Your messsage"; }
    },
    placeholder: {
      fullName: function(d) { return "Full name"; },
      email: function(d) { return "Email"; },
      contactMessage: function(d) { return "Type your message..."; }
    },
    errors: {
      contactMessage: {
        required: function(d) { return "Comment is required"; }
      }
    },
    submitButton: function(d) { return "Send your message"; }
  },
  validation: {
    requiredField: function(d) { return "*"; },
    errors: {
      general: function(d) { return "Oops, something went wrong, please try again later."; },
      region: {
        required: function(d) { return "Oops, this field is blank! Please select your region to continue."; }
      },
      package: {
        required: function(d) { return "Oops, this field is blank! Please select a package to continue."; }
      },
      firstName: {
        required: function(d) { return "Oops, this field is blank! Please fill in your first name to continue."; }
      },
      lastName: {
        required: function(d) { return "Oops, this field is blank! Please fill in your surname to continue."; }
      },
      fullName: {
        required: function(d) { return "Oops, this field is blank! Please fill in your full name to continue."; }
      },
      dateOfBirth: {
        required: function(d) { return "Oops, this field is blank! Please fill in your date of birth to continue."; },
        past: function(d) { return "Please enter a date in the past"; },
        notAllowed: function(d) { return "We’re sorry, we’re only able to offer membership to people 18 or over."; }
      },
      gender: {
        required: function(d) { return "Oops, this field is blank! Please select male or female to continue."; }
      },
      isPregnant: {
        required: function(d) { return "Oops, this field is blank! Please let us know if you are pregnant to continue."; }
      },
      isBreastfeeding: {
        required: function(d) { return "Oops, this field is blank! Please let us know if you are breastfeeding to continue."; }
      },
      hasEatingDisorder: {
        required: function(d) { return "Oops, this field is blank! Please let us know if you have any eating disorders to continue."; }
      },
      hasMedicalConditions: {
        required: function(d) { return "Oops, this field is blank! Please let us know if you have any medical conditions to continue."; }
      },
      noDietaryPreference: {
        required: function(d) { return "Please let us know if you have any dietary preferences."; },
        noPreference: function(d) { return "Please select your dietary preferences."; }
      },
      initialWeight: {
        required: function(d) { return "Oops, this field is blank! Please fill in your current weight to continue."; },
        tooHigh: function(d) { return "The weight you’ve entered seems unusually high - please double check your details."; },
        tooLow: function(d) { return "The weight you’ve entered seems unusually low - please double check your details."; },
        bmiTooLow: function(d) { return "We're sorry, your chosen target weight means your BMI will be below what is considered healthy."; }
      },
      currentWeight: {
        required: function(d) { return "Oops, this field is blank! Please fill in your current weight to continue."; }
      },
      startWeight: {
        required: function(d) { return "Oops, this field is blank! Please fill in your start weight to continue."; },
        tooHigh: function(d) { return "The weight you’ve entered seems unusually high - please double check your details."; },
        tooLow: function(d) { return "The weight you’ve entered seems unusually low - please double check your details."; }
      },
      height: {
        required: function(d) { return "Oops, this field is blank! Please enter your height to continue."; },
        tooHigh: function(d) { return "The height you’ve entered seems unusually high - please double check your details. "; },
        tooLow: function(d) { return "The height you’ve entered seems unusually low - please double check your details. "; }
      },
      commitment: {
        required: function(d) { return "Oops, this field is blank! Please enter your commitment to continue."; }
      },
      termsOfUse: {
        required: function(d) { return "Please agree to our Privacy Promise and Terms and Conditions"; }
      },
      privacyPolicy: {
        required: function(d) { return "Please agree to our Privacy Policy"; }
      },
      receiveConsultantSupportEmails: {
        required: function(d) { return "Please agree to our Privacy Policy"; }
      },
      userSubscription: {
        required: function(d) { return "Please agree to our User Subscription Agreement"; }
      },
      securityQuestionId: {
        required: function(d) { return "Please choose a security question"; }
      },
      securityQuestionAnswer: {
        required: function(d) { return "To continue, please enter an answer for your security question"; },
        whitespace: function(d) { return "Your answer cannot contain any spaces at the start or end"; },
        minlength: function(d) { return "Your answer should be at least " + d.LENGTH + " characters in length."; }
      },
      email: {
        required: function(d) { return "Oops, this field is blank! Please fill in your email address to continue."; },
        validator: function(d) { return "Please enter a valid email address."; }
      },
      newEmail: {
        required: function(d) { return "Oops, this field is blank! Please fill in your email address to continue."; },
        validator: function(d) { return "Please enter a valid email address."; }
      },
      emailConfirm: {
        required: function(d) { return "Oops, this field is blank! Please retype your email address to continue."; },
        match: function(d) { return "Oops, these email addresses don't match"; }
      },
      cardNumber: {
        range: function(d) { return "Your card number should be either 8 or 12 digits."; },
        required: function(d) { return "Please enter your card number"; }
      },
      pin: {
        length: function(d) { return "Your pin must be 5 digits long"; },
        required: function(d) { return "Please enter your PIN"; }
      },
      phoneNumber: {
        required: function(d) { return "Oops, this field is blank! Please fill in a phone number to continue."; },
        invalid: function(d) { return "Oops, this is not a valid phone number, please enter a valid phone number ( " + d.PHONE_EXAMPLE + " )."; }
      },
      password: {
        required: function(d) { return "Oops, this field is blank! Please fill in a password to continue."; },
        validator: function(d) { return "Create a strong password: between 8-64 characters with a mix of letters and numbers."; }
      },
      newPassword: {
        required: function(d) { return "Oops, this field is blank! Please fill in a password to continue."; },
        validator: function(d) { return "Create a strong password: between 8-64 characters with a mix of letters and numbers."; }
      },
      passwordConfirm: {
        required: function(d) { return "Oops, this field is blank! Please fill in a password to continue."; },
        match: function(d) { return "Oops your passwords don’t match, please try again."; }
      },
      billingAddress: {
        addressLine1: {
          required: function(d) { return "Oops, this field is blank! Please fill in your address to continue."; }
        },
        city: {
          required: function(d) { return "Oops, this field is blank! Please fill in your town or city to continue."; }
        },
        country: {
          required: function(d) { return "Oops, this field is blank! Please fill in your country to continue."; }
        },
        state: {
          required: function(d) { return "Oops, this field is blank! Please fill in your county to continue."; }
        },
        zip: {
          required: function(d) { return "Please fill in your zip or postal code to continue."; }
        }
      },
      addressLine1: {
        required: function(d) { return "Please fill in your address to continue."; }
      },
      city: {
        required: function(d) { return "Please fill in your town or city to continue."; }
      },
      county: {
        required: function(d) { return "Please fill in your county to continue."; }
      },
      country: {
        required: function(d) { return "Please fill in your country to continue."; }
      },
      state: {
        required: function(d) { return "Please fill in your state to continue."; }
      },
      zip: {
        required: function(d) { return "Please fill in your " + d.POSTAL_CODE + " to continue."; },
        formatError: function(d) { return "Please double-check your " + d.POSTAL_CODE; },
        invalid: function(d) { return "Please check that all your characters are correct (eg, are you typing a zero instead of an O?)"; }
      },
      contactMessage: {
        required: function(d) { return "Message is required"; }
      },
      userName: {
        required: function(d) { return "Please choose a username to continue."; },
        minlength: function(d) { return "Your username should be at least " + d.LENGTH + " characters in length."; },
        maxlength: function(d) { return "Username must be less than " + d.LENGTH + " characters in length."; },
        invalid: function(d) { return "Your username should contain a mix of numbers and letters. You can also use full stops and underscores. Please note you can't use spaces in usernames."; },
        alreadyregistered: function(d) { return "Sorry, this username already exists. Please choose another one."; }
      },
      timeZoneId: {
        required: function(d) { return "Please select your timezone to continue."; }
      },
      targetWeight: {
        required: function(d) { return "Please fill in your target weight to continue."; },
        bmiTooLow: function(d) { return "We're sorry, your chosen target weight means your BMI will be below what is considered healthy."; }
      },
      lbs: {
        maxExceeded: function(d) { return "lbs should be smaller than 14"; }
      },
      groupJoinDate: {
        required: function(d) { return "Oops, this field is blank! Please enter the date of your first group."; },
        past: function(d) { return "Please enter a date in the past"; }
      },
      groupDay: {
        required: function(d) { return "Oops, this field is blank! Please select the day you normally go to group."; }
      },
      productName: {
        required: function(d) { return "Please fill in a product name to continue."; }
      },
      freeFoodAllowance: {
        required: function(d) { return "Please select a Free Food allowance to continue."; }
      },
      calories: {
        required: function(d) { return "Please fill in an energy value to continue"; }
      },
      fat: {
        required: function(d) { return "Please fill in a fat value to continue"; }
      },
      carbohydrate: {
        required: function(d) { return "Please fill in a carbohydrates value to continue"; }
      },
      sugar: {
        required: function(d) { return "Please fill in a sugar value to continue"; }
      },
      protein: {
        required: function(d) { return "Please fill in a protein value to continue"; }
      },
      calcium: {
        required: function(d) { return "Please fill in a calcium value to continue"; }
      },
      portionSize: {
        required: function(d) { return "Please fill in a portion size to continue"; }
      },
      category: {
        required: function(d) { return "So we can direct your question to the right team please select a category"; }
      },
      postcode: {
        required: function(d) { return "Please enter a valid postcode"; },
        formatError: function(d) { return "Please enter a valid postcode"; }
      },
      groupSearch: {
        searchTypeUnknown: function(d) { return "Please enter a valid postcode."; },
        failedGetTowns: function(d) { return "Something went wrong, please search by postcode or location"; },
        noResultsForTownCity: function(d) { return "Sorry, we can’t find your town or city. Please check the spelling or try a postcode search."; }
      },
      journeyStartDate: {
        required: function(d) { return "Oops, this field is blank! Please enter the date you would like to track your weight from."; },
        past: function(d) { return "Please enter a date in the past"; },
        longAgo: function(d) { return "Please enter a date after 3rd January 2000"; },
        ageInvalid: function(d) { return "Your journey cannot start before your 16th birthday"; }
      },
      foodCategories: {
        required: function(d) { return "Please select a meal"; }
      },
      startDateUTC: {
        required: function(d) { return "Please choose a start date."; }
      }
    }
  },
  searchFilter: {
    title: function(d) { return "Filter "; },
    successStories: {
      name: function(d) { return "Success stories"; },
      stories: {
        name: function(d) { return "Stories"; },
        health: function(d) { return "Health"; },
        wedding: function(d) { return "Wedding"; },
        becomingAParent: function(d) { return "Becoming a parent"; },
        vacation: function(d) { return "Holiday"; },
        generalWellBeing: function(d) { return "General well-being"; }
      },
      gender: {
        name: function(d) { return "Gender"; },
        male: function(d) { return "Male"; },
        female: function(d) { return "Female"; }
      },
      weightLoss: {
        name: function(d) { return "Weight loss"; },
        underOneStoneHalf: function(d) { return "1½ st"; },
        oneHalfThreeHalf: function(d) { return "1½ st - 3½ st"; },
        threeHalfFiveHalf: function(d) { return "3½ st - 5½ st"; },
        fiveHalfSevenHalf: function(d) { return "5½ st - 7½ st"; },
        overSevenHalfStone: function(d) { return "7½ st+"; }
      },
      age: {
        name: function(d) { return "Age"; },
        under20: function(d) { return "Under 20"; },
        twenties: function(d) { return "20s"; },
        thirties: function(d) { return "30s"; },
        forties: function(d) { return "40s"; },
        fifties: function(d) { return "50s"; },
        sixtiesAndMore: function(d) { return "60s+"; }
      }
    },
    recipes: {
      name: function(d) { return "Recipes"; },
      useFoodPreferences: {
        name: function(d) { return "Dietary Preferences"; },
        useFoodPreferences: function(d) { return "Use my dietary preferences"; },
        uncheckFoodPreferences: function(d) { return "(uncheck to search all recipes)"; }
      },
      foodOptimising: {
        name: function(d) { return "Food Optimising"; },
        lessThan5Ingredients: function(d) { return "Less than 5 ingredients"; },
        free: function(d) { return "Free"; },
        familyFavourites: function(d) { return "Family favourites"; },
        foodOptimizingSp: function(d) { return "Extra Easy SP"; },
        vegetarian: function(d) { return "Vegetarian"; },
        freezerFriendly: function(d) { return "Freezer friendly"; }
      },
      cuisine: {
        name: function(d) { return "Cuisine"; },
        freezerFriendly: function(d) { return "Freezer friendly"; },
        italian: function(d) { return "Italian"; },
        chinese: function(d) { return "Chinese"; },
        mexican: function(d) { return "Mexican"; },
        greek: function(d) { return "Greek"; },
        thai: function(d) { return "Thai"; },
        indian: function(d) { return "Indian"; },
        middleEastern: function(d) { return "Middle eastern"; },
        american: function(d) { return "American"; },
        mediterranean: function(d) { return "Mediterranean"; },
        french: function(d) { return "French"; },
        asian: function(d) { return "Asian"; },
        britishIsles: function(d) { return "British Isles"; }
      },
      mealType: {
        name: function(d) { return "Meal type"; },
        breakfast: function(d) { return "Breakfast"; },
        lunch: function(d) { return "Lunch"; },
        dinner: function(d) { return "Dinner"; },
        snack: function(d) { return "Snack"; },
        dessert: function(d) { return "Dessert"; },
        appetizer: function(d) { return "Appetiser"; },
        saucesAndCondiments: function(d) { return "Sauces & condiments"; },
        drinks: function(d) { return "Drinks"; },
        sideDishes: function(d) { return "Side dishes"; }
      },
      seasonal: {
        name: function(d) { return "Seasonal"; },
        christmas: function(d) { return "Christmas"; },
        newYear: function(d) { return "New year's"; },
        halloween: function(d) { return "Halloween"; },
        stPatricksDay: function(d) { return "St. Patrick's Day"; },
        easter: function(d) { return "Easter"; },
        valentinesDay: function(d) { return "Valentine's Day"; },
        summer: function(d) { return "Summer"; },
        fall: function(d) { return "Autumn"; },
        winter: function(d) { return "Winter"; },
        spring: function(d) { return "Spring"; }
      },
      cookingType: {
        name: function(d) { return "Cooking type"; },
        grilling: function(d) { return "Grilling"; },
        fakeaway: function(d) { return "Fakeaway"; },
        onePot: function(d) { return "One pot"; },
        slowCooker: function(d) { return "Slow cooker"; },
        noCook: function(d) { return "No cooking"; }
      },
      time: {
        name: function(d) { return "Time"; },
        lessThan30: function(d) { return "30 mins or less"; },
        lessThan60: function(d) { return "30-60 min"; },
        moreThan60: function(d) { return "60+ min"; }
      }
    },
    features: {
      name: function(d) { return "Features"; },
      features: {
        name: function(d) { return "Features"; },
        foodOptimizing: function(d) { return "Food Optimising"; },
        foodOptimizingSp: function(d) { return "Extra Easy SP"; },
        gettingStarted: function(d) { return "Getting started"; },
        travelAndVacation: function(d) { return "Travel & holidays"; },
        foodIdeas: function(d) { return "Food ideas"; },
        exercise: function(d) { return "Getting active"; },
        trickyTimes: function(d) { return "Tricky times"; },
        health: function(d) { return "Health"; },
        eatingOut: function(d) { return "Eating out"; },
        targetMembers: function(d) { return "Target members"; },
        seasonal: function(d) { return "Seasonal"; }
      },
      seasonal: {
        name: function(d) { return "Seasonal"; },
        christmas: function(d) { return "Christmas"; },
        newYear: function(d) { return "New year's"; },
        thanksgiving: function(d) { return "Thanksgiving"; },
        halloween: function(d) { return "Halloween"; },
        valentinesDay: function(d) { return "Valentine's Day"; },
        summer: function(d) { return "Summer"; },
        fall: function(d) { return "Autumn"; },
        winter: function(d) { return "Winter"; },
        spring: function(d) { return "Spring"; }
      }
    },
    community: {
      name: function(d) { return "Community"; },
      tagsFilter: {
        name: function(d) { return "Tags"; }
      }
    },
    foodRange: {
      name: function(d) { return "Food Range recipes"; },
      categories: {
        name: function(d) { return "Range"; }
      },
      foodRangeDiet: {
        name: function(d) { return "Dietary preferences"; },
        eggFree: function(d) { return "No egg ingredients*"; },
        dairyFree: function(d) { return "No dairy ingredients*"; },
        glutenFree: function(d) { return "No gluten ingredients*"; },
        speedFood: function(d) { return "Suitable for SP"; },
        vegan: function(d) { return "Vegan"; },
        vegetarian: function(d) { return "Vegetarian"; }
      }
    },
    advertisement: {
      name: function(d) { return "Artwork"; },
      orientation: {
        name: function(d) { return "Orientation"; },
        landscape: function(d) { return "Landscape"; },
        portrait: function(d) { return "Portrait"; }
      },
      imagery: {
        name: function(d) { return "Imagery"; },
        food: function(d) { return "Food"; },
        people: function(d) { return "People"; }
      }
    },
    nearestGroupSearch: {
      name: function(d) { return "Group search"; },
      days: {
        name: function(d) { return "Day"; },
        monday: function(d) { return "Monday"; },
        tuesday: function(d) { return "Tuesday"; },
        wednesday: function(d) { return "Wednesday"; },
        thursday: function(d) { return "Thursday"; },
        friday: function(d) { return "Friday"; },
        saturday: function(d) { return "Saturday"; },
        sunday: function(d) { return "Sunday"; }
      },
      times: {
        name: function(d) { return "Time"; },
        morning: function(d) { return "Morning"; },
        afternoon: function(d) { return "Afternoon"; },
        evening: function(d) { return "Evening"; }
      },
      venueAccessibility: {
        name: function(d) { return "Venue accessibility"; },
        wheelchairAccess: function(d) { return "Wheelchair access"; },
        disabledToilet: function(d) { return "Disabled toilet"; },
        disabledParking: function(d) { return "Disabled parking"; },
        hearingLoop: function(d) { return "Hearing loop"; }
      }
    }
  },
  general: {
    post: {
      isEdited: function(d) { return "This post has been edited"; },
      createdDate: function(d) { return "Posted " + d.DATE; },
      latestActivity: function(d) { return "Latest activity " + d.DATE; },
      comment: {
        cta: function(d) { return plural(d.TOTAL_COMMENTS, 0, en, { "0": "Comment", one: d.TOTAL_COMMENTS + " Comment", other: d.TOTAL_COMMENTS + " Comments" }); },
        info: function(d) { return plural(d.TOTAL_COMMENTS, 0, en, { "0": "0 Comments", one: d.TOTAL_COMMENTS + " Comment", other: d.TOTAL_COMMENTS + " Comments" }); },
        label: function(d) { return plural(d.TOTAL_COMMENTS, 0, en, { "0": "No comments just yet", one: d.TOTAL_COMMENTS + " Comment", other: d.TOTAL_COMMENTS + " Comments" }); }
      },
      reply: {
        label: function(d) { return "Reply"; }
      },
      read: function(d) { return "Read this post"; },
      readShort: function(d) { return "Read"; },
      postedTo: function(d) { return "Posted to "; },
      slimmingWorldCommunity: function(d) { return "Slimming World Community"; }
    },
    tiles: {
      foodRange: function(d) { return "Food Range"; }
    },
    sort: {
      label: function(d) { return "Sort by"; },
      relevance: function(d) { return "Relevance"; },
      newest: function(d) { return "Most recent"; },
      oldest: function(d) { return "Oldest"; },
      latestActivity: function(d) { return "Latest activity"; },
      mostLiked: function(d) { return "Most liked"; }
    },
    postType: {
      post: function(d) { return "Post"; },
      "weigh-in": function(d) { return "Weigh-in"; }
    },
    cta: {
      backToCommunity: function(d) { return "Back to community"; },
      bookmark: function(d) { return "Bookmark"; },
      bookmarked: function(d) { return "Bookmarked"; },
      startAChat: function(d) { return "Start a chat"; },
      back: function(d) { return "Back"; },
      save: function(d) { return "Save"; },
      clear: function(d) { return "Clear"; },
      add: function(d) { return "Add"; },
      remove: function(d) { return "Remove"; },
      reject: function(d) { return "Reject"; },
      removeQuestion: function(d) { return "Remove this question"; },
      next: function(d) { return "Next"; },
      close: function(d) { return "Close"; },
      loadMore: function(d) { return "Load more"; },
      contactSupport: function(d) { return "Contact our support team"; },
      readMore: function(d) { return "Read more"; },
      cancel: function(d) { return "Cancel"; },
      print: function(d) { return "Print"; },
      share: function(d) { return "Share"; },
      retry: function(d) { return "Retry"; },
      "continue": function(d) { return "Continue"; },
      finish: function(d) { return "Finish"; },
      edit: function(d) { return "Edit"; },
      ok: function(d) { return "Ok"; },
      gotIt: function(d) { return "Okay, got it!"; },
      confirm: function(d) { return "Confirm"; },
      viewDish: function(d) { return "View the dish"; },
      copy: function(d) { return "Copy"; },
      oops: function(d) { return "Oops!"; },
      previousSlide: function(d) { return "Previous slide"; },
      nextSlide: function(d) { return "Next slide"; }
    },
    inlineText: {
      contactSupport: function(d) { return "contact" + d.TENSE + " our support team"; },
      viewPregnancySettings: function(d) { return "pregnancy settings"; },
      of: function(d) { return "of"; }
    },
    syns: {
      label: function(d) { return plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn " + d.SYNSTYPE, other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }) + " " + d.SYNSTYPE, other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns " + d.SYNSTYPE }); },
      shortLabel: function(d) { return plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn", other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns" }); },
      noLabel: function(d) { return plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½", other: "0" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + select(d.HALF, { "5": "", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) }); },
      perPerson: function(d) { return "per serving"; },
      each: function(d) { return "each"; }
    },
    forms: {
      savedSuccessfully: function(d) { return "The form has been saved successfully"; },
      sentSuccesfully: function(d) { return "The form has been sent successfully"; },
      generalErrorMessage: function(d) { return "Oops, something's gone wrong! Please try again..."; }
    },
    measureUnit: {
      height: {
        feet: {
          label: function(d) { return "feet"; },
          labelAbbreviation: function(d) { return "ft"; }
        },
        inches: {
          label: function(d) { return "inches"; },
          labelZeroInch: function(d) { return "zero inch"; },
          labelHalfInch: function(d) { return "half inch"; },
          labelAbbreviation: function(d) { return "in"; },
          name: function(d) { return select(d.HEIGHT, { null: "inches", other: plural(d.HEIGHT, 0, en, { one: "inche", other: "inches" }) }); },
          abbreviation: function(d) { return select(d.HEIGHT, { null: "ins", other: plural(d.HEIGHT, 0, en, { one: "in", other: "ins" }) }); }
        },
        meters: {
          label: function(d) { return "metres"; },
          labelAbbreviation: function(d) { return "m"; },
          name: function(d) { return select(d.HEIGHT, { null: "metres", other: plural(d.HEIGHT, 0, en, { one: "metre", other: "metres" }) }); },
          abbreviation: function(d) { return select(d.HEIGHT, { null: "ms", other: plural(d.HEIGHT, 0, en, { one: "m", other: "ms" }) }); }
        },
        centimeters: {
          label: function(d) { return "centimeters"; },
          labelAbbreviation: function(d) { return "cm"; },
          name: function(d) { return select(d.HEIGHT, { null: "centimetres", other: plural(d.HEIGHT, 0, en, { one: "centimetre", other: "centimetres" }) }); },
          abbreviation: function(d) { return d.HEIGHT + " cm"; }
        }
      },
      weight: {
        pounds: {
          label: function(d) { return "pounds"; },
          labelZeroPound: function(d) { return "zero pound"; },
          labelHalfPound: function(d) { return "half pound"; },
          labelAbbreviation: function(d) { return "lbs"; },
          labelAbbreviationSingle: function(d) { return "lb"; },
          name: function(d) { return select(d.WEIGHT, { null: "pounds", other: plural(d.WEIGHT, 0, en, { one: "pound", other: "pounds" }) }); },
          abbreviation: function(d) { return select(d.WEIGHT, { null: "lbs", other: plural(d.WEIGHT, 0, en, { one: "lb", other: "lbs" }) }); },
          abbreviatedNumber: function(d) { return plural(d.NUMBER, 0, en, { "0": select(d.HALF, { "5": "½ lb", other: "" }), "1": d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " lb" + select(d.HALF, { "5": "s", other: "" }), other: d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " lbs" }); }
        },
        stone: {
          label: function(d) { return "stones"; },
          labelAbbreviation: function(d) { return "st"; },
          name: function(d) { return select(d.WEIGHT, { null: "pounds", other: plural(d.WEIGHT, 0, en, { one: "pound", other: "pounds" }) }); },
          abbreviation: function(d) { return "st"; },
          abbreviatedNumber: function(d) { return plural(d.NUMBER, 0, en, { "0": select(d.HALF, { "5": "½ st", other: "" }), "1": d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " st" + select(d.HALF, { "5": "s", other: "" }), other: d.NUMBER + select(d.HALF, { "5": "½", other: "" }) + " st" }); }
        },
        kilogram: {
          label: function(d) { return "kilograms"; },
          labelKilos: function(d) { return "kilos"; },
          labelGrams: function(d) { return "grams"; },
          labelAbbreviation: function(d) { return "kg"; },
          name: function(d) { return select(d.WEIGHT, { null: "kilograms", other: plural(d.WEIGHT, 0, en, { one: "kilogram", other: "kilograms" }) }); },
          abbreviation: function(d) { return select(d.WEIGHT, { null: "kgs", other: plural(d.WEIGHT, 0, en, { one: "kg", other: "kgs" }) }); },
          abbreviatedNumber: function(d) { return d.NUMBER + " kg"; },
          screenReader: {
            kilo: function(d) { return "kilos"; },
            grams: function(d) { return "grams"; }
          }
        }
      },
      energy: {
        kcal: function(d) { return "kcal"; },
        kj: function(d) { return "kJ"; },
        "100g": function(d) { return "per 100g"; },
        "100ml": function(d) { return "per 100ml"; },
        serving: function(d) { return "per serving"; }
      }
    },
    portionSize: function(d) { return select(d.TYPE, { perperson: "Serves", each: "Makes", other: "Serves" }) + " " + d.SERVINGS; },
    weighIn: {
      error: {
        gainExcess: function(d) { return "Oops, it seems as though there has been a typing error. Please make sure that you have entered your current weight."; },
        lossExcess: function(d) { return "Oops, it seems as though there has been a typing error. Please make sure that you have entered your current weight."; }
      },
      warning: {
        gain: function(d) { return "The amount of weight you've gained since you last updated your progress is more than we would normally expect.\nPlease double check you have entered your weight correctly."; },
        loss: function(d) { return "The amount of weight you've lost since you last updated your progress is more than we would normally expect.\nPlease double check you have entered your weight correctly."; },
        incorrectLoss: function(d) { return "The amount of weight you've lost since you last updated your progress looks incorrect.\nPlease double check you have entered your weight correctly"; },
        incorrectGain: function(d) { return "The amount of weight you've gained since you last updated your progress looks incorrect.\nPlease double check you have entered your weight correctly."; }
      },
      bmiLow: function(d) { return "We’re sorry, your BMI is below what is considered a healthy weight for your height. Intentionally trying to lose weight to reach and stay within this range can be unhealthy. \n\nPlease double check the weight you’ve entered, or get in touch with our support team " + d.SUPPORT_EMAIL + " to talk through your options. "; }
    },
    commitment: {
      error: {
        gainExcess: function(d) { return "Oops, it seems as though there has been a typing error. Please make sure that you have entered a valid commitment."; },
        lossExcess: function(d) { return "We’re sorry the weight you’d like to lose this week is more than we’d recommend. Please double check you’ve entered your weight loss commitment correctly. If you’d like to talk about this please contact our support team " + d.SUPPORT_EMAIL + "."; }
      },
      warning: {
        gainExcess: function(d) { return "The amount of weight you’d like to lose is more than we would normally expect. Please double check you’ve entered your weight loss commitment correctly. \nPlease click Finish to continue."; },
        lossExcess: function(d) { return "The amount of weight you’d like to lose is more than we would normally expect. Please double check you’ve entered your weight loss commitment correctly. \nPlease click Finish to continue."; }
      },
      bmiLow: function(d) { return "We’re sorry the weight you’d like to lose this week would take you below our lowest acceptable BMI for your height. Please double check you’ve entered your weight loss commitment correctly."; }
    },
    user: {
      isAdmin: function(d) { return "SW team"; },
      isNewMember: function(d) { return "New member"; }
    },
    date: {
      year: function(d) { return "Year"; },
      month: function(d) { return "Month"; },
      day: function(d) { return "Day"; },
      fullDate: function(d) { return "Date"; },
      error: {
        ageNotAllowed: function(d) { return "Age below 18 years is not allowed"; }
      }
    },
    securityInfoLabel: function(d) { return "Why do we ask for a security question?"; },
    securityInfo: function(d) { return "We ask for this information so we can help identify you and recover your account if you lose access for any reason."; },
    securityQuestion: {
      securityQuestionsPlaceholder: function(d) { return "Select your security question"; },
      securityAnswerPlaceholder: function(d) { return "Answer"; },
      securityLabel: function(d) { return "Choose a security question"; }
    },
    error: {
      title: function(d) { return "Oops, something went wrong!"; },
      description: function(d) { return "We're sorry for the error. Please close your browser and try again shortly."; },
      inline: function(d) { return "Oops, something went wrong!\nPlease close your browser and try again shortly."; },
      contactSupport: function(d) { return "If you continue to experience problems please contact our support team: "; }
    },
    video: {
      play: function(d) { return "Play"; }
    }
  },
  errors: {
    requiredField: function(d) { return "Oops, this field is blank! Please fill in " + d.FIELD_NAME + " to continue."; },
    invalidField: function(d) { return "Please enter a valid " + d.FIELD_NAME; },
    unauthorized: function(d) { return "You are not authorized to view this page."; },
    notFound: function(d) { return "Something you were looking for could not be found.."; },
    unexpected: function(d) { return "We're sorry for the error. Please close your browser and try again shortly."; },
    invite: {
      required: function(d) { return "You need an invite to register at this time"; },
      notFound: function(d) { return "The invite could not be found"; },
      used: function(d) { return "This invite is already used"; }
    }
  },
  notFound: {
    title: function(d) { return "Sorry, this page isn't available"; },
    description: function(d) { return "The link you followed may be broken, or the page may have been removed."; },
    buttonLabel: function(d) { return "Return to homepage"; }
  },
  recipeSearch: {
    hero: {
      title: function(d) { return "Recipes"; },
      content: function(d) { return "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit inventore corporis facilis pariatur ad tenetur expedita culpa consequatur sed et nostrum aliquid rem incidunt, neque maxime sequi doloribus a obcaecati."; }
    },
    sortBy: function(d) { return "Sort by"; },
    pagination: function(d) { return d.limit + " Recipes of  " + d.total; },
    results: {
      serves: function(d) { return "Serves:"; },
      readyIn: function(d) { return "Ready in:"; },
      synsPerServing: function(d) { return "Syns per serving:"; }
    },
    loadAll: function(d) { return "Show all"; }
  },
  contactUsFaqs: {
    header: function(d) { return "Have you got a niggling question?"; },
    body: function(d) { return "Find all the information you need to help with your query in our FAQs"; },
    cta: function(d) { return "Browse our FAQs"; }
  },
  smartNotFound: {
    header: function(d) { return "We’re sorry"; },
    body: function(d) { return "We can’t find that page, but don’t go!"; },
    recipe: {
      tagLine: function(d) { return "You'll find recipes similar to ‘" + d.SEARCH_TERM + "’ below."; },
      cta: function(d) { return "Find more recipes"; }
    },
    story: {
      tagLine: function(d) { return "You'll find more inspiring member stories below."; },
      cta: function(d) { return "Browse our real life stories"; }
    },
    group: {
      tagLine: function(d) { return "Search for one of our warm and friendly groups below."; },
      title: function(d) { return "Start your journey to a"; },
      subTitle: function(d) { return "slimmer, healthier you today!"; }
    }
  },
  contactUs: {
    header: function(d) { return "Contact us"; }
  },
  weightInput: {
    title: function(d) { return "What is your height?"; },
    legend: function(d) { return "Choose weight units:"; },
    label: function(d) { return "Why do we ask for your weight?"; },
    info: {
      online: function(d) { return "We need this to work out your Body Mass Index (BMI) - a BMI higher than 25 gives an increased risk of developing weight-related health problems."; },
      group: function(d) { return "We need this to work out your Body Mass Index (BMI)"; }
    }
  },
  footer: {
    disclaimer1Copy: function(d) { return "Weight loss will vary due to your individual circumstances and how much weight you have to lose."; },
    disclaimer2Copy: function(d) { return "Please note: we may record calls for training and quality purposes."; },
    statistic: function(d) { return "†More people in the UK choose to attend a Slimming World group each week than any other weight loss group. "; },
    ipso: function(d) { return "Slimming World is a member of the Independent Press Standards Organisation (IPSO) and we subscribe to its Editors’ Code of Practice. To get in touch with us click " + d.LINK; },
    here: function(d) { return "here"; },
    socialsTitle: function(d) { return "Follow us on"; },
    magazine: {
      subscribe: function(d) { return "Find out more"; },
      subtitle: function(d) { return "Discover the UK’s number 1"; },
      title: function(d) { return "slimming magazine"; }
    },
    consultantLogin: function(d) { return "Consultants' log in"; }
  },
  searchFilterBar: {
    labels: {
      filterTitle: function(d) { return "Filters"; },
      open: function(d) { return "Open search filter"; }
    },
    searchResults: function(d) { return plural(d.NUM_RESULTS, 0, en, { "0": "No results", one: "1 result", other: number(d.NUM_RESULTS, "NUM_RESULTS") + " results" }); },
    totalFilters: function(d) { return " (" + d.NUM_FILTERS + ")"; }
  },
  recruitmentHeader: {
    consultant: {
      tagLine: function(d) { return "Become a Slimming World Consultant"; },
      cta: function(d) { return "Enquire here"; },
      navigation: {
        home: function(d) { return "Home"; },
        consultants: function(d) { return "Meet our Consultants"; },
        franchisePackage: function(d) { return "Our franchise package"; },
        franchiseFacts: function(d) { return "Franchise facts"; }
      }
    },
    careers: {
      tagLine: function(d) { return "Slimming World Careers"; },
      cta: function(d) { return "Apply here"; },
      navigation: {
        home: function(d) { return "Home"; },
        joinTheHeadOfficeTeam: function(d) { return "Join Head Office"; },
        becomeADistrictManager: function(d) { return "Become a District Manager"; },
        faqs: function(d) { return "FAQs"; }
      }
    }
  },
  recruitmentCtaBlock: {
    "default": {
      title: function(d) { return "Our groups are truly where\nthe *magic* happens"; },
      text: function(d) { return "If you’re highly motivated to inspire others to lead happier and healthier lives, this rewarding role could be just right for you. The most successful Consultants build buzzing, thriving and fun groups, where members feel cared for and safe, and are inspired to make positive changes that help them stay slim for life. Slimming World groups are truly where the magic happens. \n \nAre you ready to find out more? Register now for our next Opportunity Event and come along to find out how you can touch hearts and change lives as a self-employed Slimming World Consultant."; }
    },
    ourFranchisePackage: {
      title: function(d) { return "Are you ready to\n*find out more?*"; },
      text: function(d) { return "Register now for our next <b>Opportunity Event</b> and come along to find out how you can touch hearts and change lives as a self-employed Slimming World Consultant."; }
    },
    franchiseFacts: {
      title: function(d) { return "Are you ready to\n*find out more?*"; },
      text: function(d) { return "Register now for our next <b>Opportunity Event</b> and come along to find out how you can touch hearts and change lives as a self-employed Slimming World Consultant."; }
    },
    careers: {
      cta: function(d) { return "Register your interest"; }
    },
    consultant: {
      cta: function(d) { return "Enquire here"; }
    },
    landing: {
      title: function(d) { return "*join the Slimming World team*"; },
      text: function(d) { return "If you’re looking for an exciting opportunity – a career in which your passionate desire to help others achieve their dreams makes a real difference to peoples' lives, a career with a company whose investment in people is second to none – then you’re in the right place!"; }
    },
    joinTheHeadOfficeTeam: {
      title: function(d) { return "*keep in touch...*"; },
      text: function(d) { return "If you don’t see the role of your dreams right now and you’d like to play a part in our future success, click below so we can keep in touch."; }
    },
    becomeADistrictManager: {
      title: function(d) { return "your *future* starts *here!*"; },
      text: function(d) { return "If you’re looking for an exciting opportunity – a career in which your passionate desire to help others achieve their dreams makes a real difference to peoples' lives, a career with a company whose investment in people is second to none – then you’re in the right place!"; }
    },
    careersFaqs: {
      title: function(d) { return "are you ready to\n*find out more?*"; },
      text: function(d) { return ""; }
    }
  },
  recipeDetailContent: {
    ingredients: function(d) { return "Ingredients"; },
    method: function(d) { return "Method"; },
    diabetesUKModal: {
      cta: function(d) { return "Nutrition Information"; },
      screenReader: function(d) { return "Display Nutrition Information"; }
    }
  },
  membershipPackage: {
    plus: function(d) { return "Plus..."; }
  },
  nutritionSymbols: {
    vegan: function(d) { return "Vegan"; },
    "dairy-free": function(d) { return "Dairy free"; },
    "gluten-free": function(d) { return "Gluten free"; },
    "freezer-friendly": function(d) { return "Freezer friendly"; },
    vegetarian: function(d) { return "Vegetarian"; },
    sp: function(d) { return "Extra Easy SP"; }
  },
  notableDetails: {
    healthyExtra: function(d) { return "Deduct " + plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn", other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns" }) + " if using " + d.NAME + " as a Healthy Extra " + d.HEALTHY_EXTRA + "."; }
  },
  nearestGroupSearchBar: {
    title: function(d) { return "Find Slimming World groups near you"; },
    longTitle: function(d) { return "Search for your nearest Slimming World Group"; },
    searchPlaceholder: function(d) { return "Postcode"; },
    button: function(d) { return "Find your group"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    search: {
      resultsLabel: function(d) { return "Your search results for"; }
    }
  },
  publicGroupSearch: {
    label: function(d) { return "Please enter a postcode or town"; },
    errors: {
      geoLocation: {
        error: function(d) { return "We're sorry we can't pinpoint your location, please type in your postcode"; }
      },
      query: {
        required: function(d) { return "Please enter a valid postcode"; }
      }
    }
  },
  joinBar: {
    searchPlaceholder: function(d) { return "Postcode"; },
    button: function(d) { return "Find your \nnearest group"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    search: {
      resultsLabel: function(d) { return "Your search results for"; }
    }
  },
  imageFlip: {
    label: {
      text: function(d) { return "Image of " + d.PERSON + " " + d.TYPE + " Slimming World diet"; },
      after: function(d) { return "after"; },
      before: function(d) { return "before"; }
    }
  },
  heightInputField: {
    title: function(d) { return "What is your height?"; },
    legend: function(d) { return "Choose height units:"; },
    info: {
      online: function(d) { return "We need this to work out your Body Mass Index (BMI) - a BMI higher than 25 gives an increased risk of developing weight-related health problems."; },
      group: function(d) { return "We need this to work out your Body Mass Index (BMI)"; }
    }
  },
  groupSessionExplained: {
    titleBegin: function(d) { return "Your first time at a "; },
    titleEnd: function(d) { return "Slimming World group"; },
    modalLaunch: function(d) { return "Find out more"; },
    intro: function(d) { return "As soon as you walk through the doors, you’ll get a warm welcome from your Consultant and fellow members. Before you go ahead and join, your Consultant will explain everything you need to know about our delicious Food Optimising plan."; },
    part0: function(d) { return "You’ll then be invited to enjoy our support session where our members share ideas, recipes and their inspirational stories – this is where the magic happens!"; },
    part1: function(d) { return "Along with other new members, you’ll enrol and weigh-in privately – your weight always remains confidential."; },
    part2: function(d) { return "The session will last around 1½ hours – there's no obligation to join, but you’ll be so glad you did!"; }
  },
  groupSearchConsultantDetails: {
    consultantName: function(d) { return "Your Consultant is"; },
    consultantTelephone: function(d) { return "Telephone"; },
    consultantLocation: function(d) { return d.NAME + " at " + d.PLACE; },
    welcomeText: {
      mobile: function(d) { return "A warm welcome..."; },
      desktop: function(d) { return "A really warm welcome..."; }
    }
  },
  contactUsForm: {
    contactMessage: function(d) { return "Your query"; },
    contactMessageAdvertisement: function(d) { return "Any additional information you think will be useful"; },
    body: function(d) { return d.PREFER + ", choose your subject below. Please note, it can take up to three days to respond to email enquiries."; },
    prefer: function(d) { return "If you’d prefer to email us"; },
    error: {
      message: function(d) { return "Add the details of your question above"; },
      cardNumber: function(d) { return "Your card number must contain numbers only"; },
      required: {
        name: function(d) { return "full name"; },
        postcode: function(d) { return "postcode"; },
        message: function(d) { return "message"; },
        email: function(d) { return "email"; },
        telephone: function(d) { return "telephone"; },
        mediaOrganisation: function(d) { return "media organisation"; },
        jobTitle: function(d) { return "job title"; }
      },
      maxLength: {
        name: function(d) { return "Full name must not exceed 30 characters in length"; }
      }
    },
    placeholder: {
      subject: function(d) { return "Choose a subject"; },
      "default": {
        name: function(d) { return "Full name"; },
        postcode: function(d) { return "Postcode"; },
        county: function(d) { return "County"; },
        email: function(d) { return "Email address"; },
        telephone: function(d) { return "Phone number (optional)"; },
        onlineRef: function(d) { return "Online ref number (optional)"; },
        cardNumber: function(d) { return "Group card number (optional)"; }
      },
      advertisement: {
        name: function(d) { return "Full name"; },
        postcode: function(d) { return "Postcode or county"; },
        email: function(d) { return "Email address"; },
        telephone: function(d) { return "Phone number"; },
        advertSize: function(d) { return "Advert size (width and height)"; },
        orientation: function(d) { return "Orientation"; },
        colorPreference: function(d) { return "Colour preference"; },
        date: function(d) { return "Date advert will appear in print"; },
        groupInformation: function(d) { return "Group information (day, time, location)"; }
      },
      media: {
        name: function(d) { return "Full name"; },
        email: function(d) { return "Email address"; },
        telephone: function(d) { return "Phone number"; },
        mediaOrganisation: function(d) { return "Media organisation"; },
        jobTitle: function(d) { return "Job title"; }
      }
    },
    categories: {
      groupInformation: function(d) { return "Group information"; },
      groupCustomerServices: function(d) { return "Group customer service"; },
      swIceland: function(d) { return "Slimming World food range"; },
      hiFiBars: function(d) { return "Hi-fi bars"; },
      consultantRecruitment: function(d) { return "Consultant recruitment"; },
      editorial: function(d) { return "Editorial"; },
      advertisementRequest: function(d) { return "Advertisement request"; },
      mediaEnquiries: function(d) { return "Media enquiries"; },
      onlineTechnicalHelp: function(d) { return "App/website support"; }
    },
    selectOptions: {
      landscape: function(d) { return "Landscape"; },
      portrait: function(d) { return "Portrait"; },
      fullColour: function(d) { return "Full colour"; },
      blackAndWhite: function(d) { return "Black and white"; }
    },
    cta: function(d) { return "Send"; }
  },
  ukPublicNavigationLinks: {
    home: function(d) { return "Home"; },
    howItWorks: function(d) { return "How does it work?"; },
    whatCanIEat: function(d) { return "What can I eat?"; },
    successStories: function(d) { return "Real-life stories"; },
    ourStory: function(d) { return "Our story"; },
    signIn: function(d) { return "Log in"; },
    findGroup: function(d) { return "Find my group"; },
    recipies: function(d) { return "Recipes"; },
    specialOffers: function(d) { return "Offers"; },
    groupCard: function(d) { return "Register your group card"; },
    join: function(d) { return "Discover our \ndigital service"; },
    joinToday: function(d) { return "Join today"; },
    contactUs: function(d) { return "Contact us"; },
    blog: function(d) { return "Blog"; },
    consultantLogin: function(d) { return "Consultants' log in"; },
    countrySwitch: {
      gb: function(d) { return "UK"; },
      ireland: function(d) { return "IE"; },
      cyprus: function(d) { return "CY"; }
    }
  },
  successStoryData: {
    member: {
      startingWeight: function(d) { return "starting weight"; },
      currentWeight: function(d) { return "weight now"; },
      timePeriod: function(d) { return "time period"; },
      height: function(d) { return "height"; },
      months: function(d) { return d.PERIOD + " months"; }
    },
    public: {
      startingWeight: function(d) { return "Starting weight"; },
      currentWeight: function(d) { return "Weight now"; },
      timePeriod: function(d) { return "Time period"; },
      height: function(d) { return "Height"; },
      months: function(d) { return d.PERIOD + " months"; }
    }
  },
  staticContentMenu: {
    title: function(d) { return "Contents"; }
  },
  socialShare: {
    title: function(d) { return "Share this " + d.PAGE; },
    typesOfPost: {
      successStory: function(d) { return "story"; },
      recipe: function(d) { return "recipe"; },
      "default": function(d) { return "page"; }
    }
  },
  skipLinks: {
    navigation: function(d) { return "Skip to main navigation"; },
    subNavigation: function(d) { return "Skip to sub navigation"; },
    content: function(d) { return "Skip to content"; },
    footer: function(d) { return "Skip to footer"; }
  },
  publicRecipeMetaBar: {
    join: {
      content: function(d) { return "to unlock all of our amazing recipes..."; },
      button: function(d) { return "Join now"; }
    }
  },
  publicHorizontalSearchBar: {
    search: function(d) { return "Search"; },
    searchFor: function(d) { return "Search for more"; },
    delicious: function(d) { return "delicious"; },
    recipes: function(d) { return "recipes"; },
    intro: function(d) { return "We've got a taster of just some of our recipes, join for access to hundreds more..."; },
    placeholder: function(d) { return "Search for recipes"; },
    generalErrorMessage: function(d) { return "Oops, something went wrong, please try again later."; },
    required: function(d) { return "Let us know what you want to search for"; }
  },
  promotionText: {
    head: function(d) { return "Free Food February"; },
    body: function(d) { return "During the whole of February we'll be celebrating our famous Free Foods in a Slimming World group near you."; }
  },
  pagePromotion: {
    bmi: {
      header: function(d) { return "BMI\nCalculator"; },
      subHeader: function(d) { return "Body Mass Index – (BMI) is a handy guide to the healthy weight range for your height"; },
      intro: function(d) { return "Use your height and weight to find out which range you’re currently in, where you’d love to be and to track your progress"; }
    },
    cta: function(d) { return "Try it now"; }
  },
  nearestGroupGeoLocation: {
    cta: function(d) { return "Use my location"; },
    label: function(d) { return "Find your nearest Slimming World group by location"; }
  },
  modifiedGroupTimetable: {
    venueChanged: function(d) { return "We’re in a different venue on " + d.DAYS + ". Please call me for details."; },
    additionalGroups: function(d) { return "I’m running extra sessions this week – please call me to find out more."; },
    groupNotRunning: function(d) { return "Sorry, my group won’t be running this week. Look forward to seeing you in the New Year!"; },
    christmasGroupTimes: function(d) { return "Christmas week (w/c 22nd) group times"; },
    newYearGroupTimes: function(d) { return "New Year week (w/c 29th) group times"; },
    hasNotConfirmed: function(d) { return "Please call the Consultant for details"; }
  },
  instagramTrending: {
    instagram: function(d) { return "on Instagram"; },
    description: function(d) { return "We have lots to tell you about… the very latest from us, and our members!"; },
    cta: function(d) { return "More from our Instagram"; }
  },
  informationToggle: {
    defaultLabel: function(d) { return "Why do we ask for this?"; }
  },
  imageContentVerticalSplitBlock: {
    cta: function(d) { return "Read " + d.NAME + "'s story"; }
  },
  icelandPromotion: {
    sw: function(d) { return "Slimming World"; },
    freeFood: {
      title: function(d) { return "Free Food"; },
      trademarkDisclaimer: function(d) { return "Free Food is a registered trademark"; }
    },
    description: function(d) { return "A delicious, filling frozen range – ready when you are"; },
    cta: function(d) { return "View the full range"; }
  },
  homepageSecondaryShowcase: {
    heading: {
      h1: function(d) { return "Real food, real support, real results"; }
    },
    title1: function(d) { return "We’ll always be"; },
    title2: function(d) { return "here for you"; }
  },
  healthyExtraFlag: function(d) { return "Deduct " + plural(d.SYNS, 0, en, { "0": select(d.HALF, { "5": "½ Syn", other: "FREE" }), "1": d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syn" + select(d.HALF, { "5": "s", other: "" }), other: d.SYNS + select(d.HALF, { "5": "½", other: "" }) + " Syns" }) + " if using " + d.NAME + " as a Healthy Extra " + d.HEALTHY_EXTRA + "."; },
  handleErrorComponent: {
    title: function(d) { return "Oops, it seems something\nwent wrong."; },
    descriptionError: function(d) { return "We weren’t able to show you " + d.TITLE + ". \n\nDon’t worry, our team has been notified about the issue and they are working on it. In the mean time you can still use the rest of the " + d.COMPONENT_TYPE + ". Please try again later."; },
    keepExperiencingProblems: function(d) { return "If you keep experiencing problems please "; },
    contactUs: function(d) { return "contact us"; },
    errorLevel: {
      site: function(d) { return "site"; },
      page: function(d) { return "page"; }
    }
  },
  groupTimetable: {
    groupTimes: function(d) { return "Group Times"; },
    opens: function(d) { return "New group opening – " + d.DATE; }
  },
  groupOnlineSignUp: {
    buttonLabel: {
      group: function(d) { return "Find out more about joining a group"; },
      online: function(d) { return "Find out more about joining digitally"; }
    },
    errorBoundaryTitle: function(d) { return "Group online sign-up"; }
  },
  foodRangeCta: {
    title: function(d) { return "Find me at Iceland, the Food Warehouse and Dunnes Stores"; },
    imgAlt: function(d) { return "Slimming World food range"; },
    wholeDescription: function(d) { return "This tasty dish is part of our Free Food range, available exclusively at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland. But it’s also a doddle to make at home when you have a little more time."; },
    partDescription: function(d) { return "Look out for our Slimming World food range, available exclusively at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland, where you’ll find Free sauces/meat products to make this meal."; },
    button: function(d) { return "Buy from Iceland"; }
  },
  foodRange: {
    title: function(d) { return "Buy me, or make me!"; },
    imgAlt: function(d) { return "Slimming World food range"; },
    wholeDescription: function(d) { return "This tasty dish is part of our Free Food range, available exclusively at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland. But it’s also a doddle to make at home when you have a little more time."; },
    partDescription: function(d) { return "Look out for our Slimming World food range, available at Iceland and the Food Warehouse in the UK and Dunnes Stores in Republic of Ireland, where you’ll find Free sauces/meat products to make this meal."; }
  },
  diabetesUKNutritionalInformationModal: {
    title: function(d) { return "each serving contains"; }
  },
  diabetesUKNutritionalInformation: {
    cta: function(d) { return "Nutrition Information"; },
    grid: {
      title: function(d) { return "each serving contains"; },
      key: {
        low: function(d) { return "low"; },
        medium: function(d) { return "medium"; },
        high: function(d) { return "high"; }
      }
    }
  },
  virtualGroupRoundel: {
    group: function(d) { return "Our groups\nhave gone"; },
    virtual: function(d) { return "virtual"; },
    trail: function(d) { return "for now"; }
  },
  saveUpToOfferRoundel: {
    saveUpTo: function(d) { return "Save up to"; },
    save: function(d) { return "Save"; },
    price: function(d) { return d.PRICE; },
    moreDetails: function(d) { return "ask in group for details"; }
  },
  halfPriceOfferRoundel: {
    top: function(d) { return "Special offer"; },
    center: function(d) { return "Half-price\nmembership"; },
    bottom: {
      saving: function(d) { return "That’s a saving of"; },
      price: function(d) { return d.PRICE; }
    }
  },
  FreeMembershipRoundel: {
    save: function(d) { return "Save"; },
    price: function(d) { return "£5"; }
  },
  FreeFreshStartMenuPlannerRoundel: {
    plus: function(d) { return "plus"; },
    free: function(d) { return "free"; },
    "fresh-start": function(d) { return "fresh-start"; },
    menu: function(d) { return "menu"; },
    planner: function(d) { return "planner*"; }
  },
  freeFestivePlannerOfferRoundel: {
    free: function(d) { return "FREE"; },
    planner: function(d) { return "Festive planner with a 12-week Countdown"; },
    until: function(d) { return "until 2nd November 2019"; }
  },
  freeBookOfferRoundel: {
    free: function(d) { return "FREE"; },
    book: function(d) { return d.BOOK; },
    worth: function(d) { return "worth "; },
    price: function(d) { return d.PRICE; },
    moreDetails: function(d) { return "when you buy a 12-week\nCountdown 26th May -\n15th June 2019"; }
  },
  contactUsSuccessMessage: {
    header: function(d) { return "Thank you"; },
    success: function(d) { return "Your email has been sent to the relevant Slimming World department."; },
    veryBusy: function(d) { return "We are receiving an exceptionally high volume of emails at the moment and it it is taking us longer to respond. We’re sure you’ll understand why, and we thank you for bearing with us at this time."; },
    body: {
      "default": function(d) { return "We aim to respond to all emails within 3 days, sooner if we can!"; },
      workDayDisclaimer: function(d) { return "(Monday to Friday - except bank holidays) before we can respond."; }
    }
  },
  contactUsIpso: {
    body: {
      info: function(d) { return "As members of the Independent Press Standards Organisation, we are committed to the highest of editorial standards and abide by the Editors’ Code of Practice. We take concerns and complaints very seriously and welcome feedback via the contact methods listed above."; }
    }
  },
  contactUsGroupEnquiries: {
    title: function(d) { return "Group enquiries"; },
    body: {
      info: function(d) { return "You can also " + d.FOCUS + " using the search below."; },
      focus: function(d) { return "look for your nearest group"; }
    }
  },
  bookmarkButtonInteraction: {
    bookmark: function(d) { return "Bookmark this content"; },
    unbookmark: function(d) { return "Remove this content from your Bookmarks"; }
  },
  videoBlock: {
    play: function(d) { return "Play video"; }
  },
  imageWithCaptionCarouselBlock: {
    counter: function(d) { return d.CURRENT_SLIDE + "/" + d.SLIDE_COUNT; }
  },
  betterHealthTile: {
    titleSerif: function(d) { return "It's better"; },
    titleScript: function(d) { return "when we’re together!"; },
    specialOffer: function(d) { return "Special offer"; },
    body: function(d) { return "Slimming World is proud to support the Better Health campaign. Discover our amazing support and a new healthy mindset to help you achieve your goals."; },
    cta: function(d) { return "Find out more"; },
    terms: function(d) { return "T&Cs apply"; }
  },
  avatar: {
    memberSince: function(d) { return "Member since " + d.DATE; },
    memberSinceComposingComment: function(d) { return "You've been a member since " + d.DATE; },
    detailAwardsTitle: function(d) { return "Awards"; },
    errorBoundaryTitle: function(d) { return "the avatar"; },
    readOnlyProfileCTA: function(d) { return "View " + d.USERNAME + "'s profile"; }
  },
  allergyInfo: {
    toggleButtonText: function(d) { return "Allergens and dietary info"; }
  },
  successStatsTile: {
    age: function(d) { return "Age"; },
    startWeight: function(d) { return "Starting weight"; },
    lostWeight: function(d) { return "Weight loss"; },
    timePeriod: function(d) { return "Time period"; },
    timePeriodMeasurement: function(d) { return "months"; }
  },
  recipeDurationDisplay: {
    totalTime: {
      duration: function(d) { return plural(d.HOURS, 0, en, { "0": "", one: d.HOURS + " hour", other: d.HOURS + " hours" }) + " " + plural(d.MINUTES, 0, en, { "0": "", one: d.MINUTES + " minute", other: d.MINUTES + " minutes" }); }
    },
    additionalTime: {
      duration: function(d) { return "plus " + plural(d.HOURS, 0, en, { "0": "", one: d.HOURS + " hour", other: d.HOURS + " hours" }) + " " + plural(d.MINUTES, 0, en, { "0": "", one: d.MINUTES + " minute", other: d.MINUTES + " minutes" }) + " " + d.SUFFIX; }
    },
    screenReaderPrefix: function(d) { return "This recipe will take you " + d.DURATION; }
  },
  openingTimes: {
    openingDays: {
      monToThurs: function(d) { return "Monday to Thursday"; },
      monToFri: function(d) { return "Monday to Friday"; },
      friday: function(d) { return "Friday"; },
      satSun: function(d) { return "Saturday to Sunday"; },
      satSunBank: function(d) { return "Saturday, Sunday and bank holidays"; }
    }
  },
  galleryModal: {
    slideIndex: function(d) { return d.currentSlide + " of " + d.totalSlides; }
  },
  addressFormGroup: {
    placeholders: {
      addressLine1: function(d) { return "Address line 1"; },
      addressLine2: function(d) { return "Address line 2"; },
      addressLine3: function(d) { return "Address line 3"; },
      city: function(d) { return "Town or City"; },
      state: function(d) { return "State"; },
      county: function(d) { return "County"; },
      zip: {
        us: function(d) { return "Zipcode"; },
        gb: function(d) { return "Postcode"; },
        ie: function(d) { return "Eircode"; },
        cy: function(d) { return "Postal code"; },
        "default": function(d) { return "Zipcode"; }
      },
      country: function(d) { return "Country"; },
      countyIe: function(d) { return "Please select"; }
    }
  },
  contactUsOnlineMemberSupport: {
    title: function(d) { return "Digital support"; },
    intro: {
      digitalSupport: function(d) { return "If you need help with our website or app, our digital support team is available..."; }
    },
    contactUsCallUs: {
      header: function(d) { return "Call us"; },
      body: function(d) { return "Pick up the phone and call " + d.PHONE_NUMBER + " if you’d like to speak to a member of the team."; }
    }
  },
  contactUsIcelandConfirm: {
    checkbox: function(d) { return "To respond to your query about our food range we need to pass your details onto the manufacturer, Iceland. Please tick this box if you’re happy for us to pass your details on to Iceland. They will contact you directly to discuss your experience."; },
    disclaimer: function(d) { return "Your details will only be used to respond to your enquiry."; }
  },
  contactUsHeadOffice: {
    title: function(d) { return "Our Head Office"; },
    address: {
      intro: function(d) { return "Write to us:"; },
      address: function(d) { return "Slimming World,\nPO Box 55, Alfreton,\nDerbyshire DE55 4RF"; }
    },
    call: {
      intro: function(d) { return "Call: "; }
    }
  }
}