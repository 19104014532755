import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';

import TextNew, {
  ElementTypes,
  FontScaleType,
  FontScaleLineHeight,
} from 'components/atoms/TextNew';

import DeviceStateType from 'components/types/DeviceState';
import ImageType from 'components/types/Image';
import IconButton from 'components/molecules/IconButton';

import Icon from '../../../../../atoms/Icon';
import ResponsiveImage from '../../../../../atoms/ResponsiveImage';

import './quotation-with-large-author-photo-and-link-block.scss';

export type QuotationWithLargeAuthorPhotoAndLinkBlockProps = {
  author: string;
  authorPhoto: ImageType;
  mediaAssetCta: {
    ctaText: string;
    ctaLink: string;
  };
  note: string;
  quotation: string;
  deviceState: DeviceStateType;
  photoPosition: 'Left' | 'Right';
};

const QuotationWithLargeAuthorPhotoAndLinkBlock = (
  {
    author,
    authorPhoto,
    mediaAssetCta,
    note,
    quotation,
    deviceState,
    photoPosition,
  }: QuotationWithLargeAuthorPhotoAndLinkBlockProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => (
  <div className={className} data-testid={dataTestId}>
    <div className={classNames('outer', photoPosition === 'Right' ? 'right' : 'left')}>
      <div className="author">
        {authorPhoto?.src && (
          <ResponsiveImage src={authorPhoto.src} alt={authorPhoto.alt} ratio={1} />
        )}

        <TextNew
          fontScaleLineHeight={FontScaleLineHeight.SMALL}
          fontScaleType={FontScaleType.SANS_SERIF_NOTE}
        >
          {author}
        </TextNew>
      </div>

      <div className="author-copy">
        <blockquote>
          <Icon
            name={IconName.BLOCKQUOTE_SERIF}
            width={deviceState > DeviceState.MD ? 32 : 22}
            direction={DirectionType.UP}
          />

          <div
            className="quote"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: quotation }}
          />

          <Icon
            name={IconName.BLOCKQUOTE_SERIF}
            width={deviceState > DeviceState.MD ? 32 : 22}
            direction={DirectionType.DOWN}
          />
        </blockquote>

        {note && (
          <div className="quote-detail">
            <TextNew fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}>{note}</TextNew>
          </div>
        )}

        {mediaAssetCta && (
          <div className="quote-link">
            <IconButton icon={IconName.DOWNLOAD_NEW} width={24} href={mediaAssetCta?.ctaLink}>
              <TextNew
                fontScaleLineHeight={FontScaleLineHeight.SMALL}
                fontScaleType={FontScaleType.SANS_SERIF_NOTE}
                element={ElementTypes.DIV}
              >
                {mediaAssetCta?.ctaText}
              </TextNew>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  </div>
);

type QuotationWithLargeAuthorPhotoAndLinkBlockType = (
  props: QuotationWithLargeAuthorPhotoAndLinkBlockProps,
) => JSX.Element;

export default compose<QuotationWithLargeAuthorPhotoAndLinkBlockType>(
  withDeviceState(),
  withFunctionalClassName(ComponentType.ATOM, 'QuotationWithLargeAuthorPhotoAndLinkBlock'),
)(QuotationWithLargeAuthorPhotoAndLinkBlock);
