import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './recruitment-bubble-background-wrapper.scss';

export const GradientTypes = {
  LINEAR: 'linear',
  RADIAL: 'radial',
};

const RecruitmentBubbleBackgroundWrapper = ({ children }, context, className) => (
  <div className={className}>{children}</div>
);

RecruitmentBubbleBackgroundWrapper.propTypes = {
  children: PropTypes.node,
  gradient: PropTypes.oneOf(Object.values(GradientTypes)), // eslint-disable-line react/no-unused-prop-types
  hasWhiteBubbles: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
};

RecruitmentBubbleBackgroundWrapper.defaultProps = {
  gradient: GradientTypes.RADIAL,
  hasWhiteBubbles: false,
};

export default withFunctionalClassName(ComponentType.ATOM, 'RecruitmentBubbleBackgroundWrapper', [
  'gradient-{gradient}',
  'hasWhiteBubbles',
])(RecruitmentBubbleBackgroundWrapper);
