import { compose } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_CAREERS_FAQS } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';

import CareersFaqs from './CareersFaqs';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = (state: any) => ({
  loading: pageLoading(state),
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_CAREERS_FAQS),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  clientOnly: ({}, dispatch: any) =>
    dispatch(getStaticContent(API_STATIC_PUBLIC_CAREERS_FAQS, true)),
});

export default compose(addInitAction, connected)(CareersFaqs);
