import createAction from 'redux-actions/lib/createAction';
import { apiGet } from '../resources/apiActions/apiRequest';
import { GATEWAY_CONTENT_V2 } from '../../data/Injectables';

export const REGISTER_INSTAGRAM_POSTS = 'instagramTrendingActions/REGISTER_INSGRAM_POSTS';
export const registerInstagramPosts = createAction(REGISTER_INSTAGRAM_POSTS);

export const GET_INSTAGRAM_POSTS = 'registrationActions/GET_INSTAGRAM_POSTS';
export const getInstagramPosts = () => dispatch =>
  dispatch(
    apiGet(GET_INSTAGRAM_POSTS, GATEWAY_CONTENT_V2, '/social-media/instagram?limit=4'),
  ).then(({ data }) => dispatch(registerInstagramPosts(data)));
