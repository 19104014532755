import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';

import Icon from 'components/atoms/Icon';

import './individual-stage.scss';

export type IndividualStageProps = {
  id: string;
  title: React.ReactNode;
  body: React.ReactNode;
  description: React.ReactNode;
  hasArrow?: boolean;
};

const IndividualStage = (
  { id, title, body, description, hasArrow }: IndividualStageProps,
  {},
  className: string,
): JSX.Element => (
  <section className={classNames(className, id)}>
    <div className="circle-container">
      {hasArrow && (
        <div className="arrow-wrapper" data-testid={`${id}-arrow`}>
          <Icon name={IconName.RADIUS_ARROW} />
        </div>
      )}
      <div className="content-wrapper">
        {title} {body}
      </div>
    </div>
    {description}
  </section>
);

type IndividualStageType = (props: IndividualStageProps) => JSX.Element;

export default compose<IndividualStageType>(
  withFunctionalClassName(ComponentType.MOLECULE, 'IndividualStage'),
)(IndividualStage);
