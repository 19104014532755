import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Pages from 'common/src/app/data/enum/Pages';
import IconName from 'common/src/app/data/enum/IconName';
import SlimmingWorldLogo from 'components/atoms/SlimmingWorldLogo';
import HeadingButton from 'components/atoms/HeadingButton';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, {
  Colors,
  ElementTypes,
  FontFamily,
  FontSizes,
  TextTypes,
} from 'components/atoms/TextNew';
import Button from 'components/atoms/Button';
import LocaleMessage from 'components/atoms/LocaleMessage';
import NavLink from 'components/atoms/NavLink';
import Icon from 'components/atoms/Icon';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';

import './recruitment-header.scss';

type navigationShape = {
  to: string;
  locale: string;
};

export type RecruitmentHeaderProps = {
  deviceState: number;
  navigationLinks?: navigationShape[];
  site: 'consultant' | 'careers';
};

const RecruitmentHeader = (
  { deviceState, navigationLinks, site }: RecruitmentHeaderProps,
  {},
  className: string,
): JSX.Element => (
  <div className={className}>
    <div className="header-top">
      <Wrapper.XL>
        <SlimmingWorldLogo link={Pages.HOME} />
        <TextNew
          size={FontSizes.SM}
          family={FontFamily.SANS}
          element={ElementTypes.SPAN}
          localeId={`recruitmentHeader.${site}.tagLine`}
        />
      </Wrapper.XL>
    </div>
    <nav className="header-bottom">
      <Wrapper.XL>
        {deviceState <= DeviceState.LG ? <HeadingButton /> : null}
        <ul className="main-navigation">
          {navigationLinks?.map(navigationObject => (
            <li className="navigation-item" key={`navigation-item-${navigationObject.locale}`}>
              <NavLink to={navigationObject.to}>
                <TextNew
                  type={TextTypes.FORMAL}
                  localeId={`recruitmentHeader.${navigationObject.locale}`}
                  color={Colors.PRIMARY_LIGHT}
                  element={ElementTypes.SPAN}
                />
              </NavLink>
            </li>
          ))}
        </ul>
        <Button
          cid="enquiry-cta"
          to={site === 'consultant' ? Pages.UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY : null}
          href={site === 'careers' ? PagesExternal.SW_PEOPLE_HR : null}
        >
          <Icon name={IconName.MENU_COMMUNITY} />
          <LocaleMessage id={`recruitmentHeader.${site}.cta`} />
        </Button>
      </Wrapper.XL>
    </nav>
  </div>
);

RecruitmentHeader.defaultProps = {
  site: 'consultant',
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'RecruitmentHeader')(RecruitmentHeader),
);
