import React from 'react';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import TextNew, {
  ElementTypes,
  FontScaleType,
  FontScaleLineHeight,
} from 'components/atoms/TextNew';

import ImageType from 'components/types/Image';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

import ResponsiveImage from '../../../../../atoms/ResponsiveImage';

import './image-with-title-and-description-list.scss';

export type ImageWithTitleAndDescriptionListProps = {
  items: Array<{
    description?: string;
    image: ImageType;
    title: string;
    _type: string;
  }>;
};

const ImageWithTitleAndDescriptionList = (
  { items }: ImageWithTitleAndDescriptionListProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => (
  <section className={className} data-testid={dataTestId}>
    {items?.map(({ description, title, image }, index) => (
      <article className="outer" key={index}>
        {image?.src && <ResponsiveImage src={image.src} alt={image?.alt} ratio={1} />}
        <div className="detail-section">
          <TextNew
            fontScaleLineHeight={FontScaleLineHeight.SMALL}
            fontScaleType={FontScaleType.SERIF_DISPLAY_THREE}
            element={ElementTypes.H3}
          >
            {title}
          </TextNew>
          {description && <RichTextBlock text={description} />}
        </div>
      </article>
    ))}
  </section>
);

type ImageWithTitleAndDescriptionListType = (
  props: ImageWithTitleAndDescriptionListProps,
) => JSX.Element;

export default compose<ImageWithTitleAndDescriptionListType>(
  withFunctionalClassName(ComponentType.ATOM, 'ImageWithTitleAndDescriptionList'),
)(ImageWithTitleAndDescriptionList);
