import handleActions from 'redux-actions/lib/handleActions';

import { REGISTER_VACANCY_FEED_DATA } from 'common/src/app/actions/vacancyFeedActions';

const initialState = {
  vacancies: [],
};

export default handleActions(
  {
    [REGISTER_VACANCY_FEED_DATA]: (state, { payload }) => ({
      ...state,
      vacancies: payload,
    }),
  },
  initialState,
);
