import debugLib from 'debug';
import createAction from 'redux-actions/lib/createAction';

import { apiGet } from './resources/apiActions/apiRequest';
import { GATEWAY_CONTENT } from '../data/Injectables';

export const REGISTER_VACANCY_FEED_DATA = 'vacancyFeedActions/REGISTER_VACANCY_FEED_DATA';

export const registerVacancyFeedData = createAction(REGISTER_VACANCY_FEED_DATA);

export const GET_VACANCY_FEED_DATA = 'careerActions/GET_VACANCY_FEED_DATA';

const debug = debugLib('SlimmingWorld:vacancyFeedActions');

/**
 * Gets the peoplehr vacancy feed data
 * @param location {string} Either - Field, HeadOffice or ''
 */

export const getVacancyFeedData = (location = '') => dispatch =>
  dispatch(
    apiGet(
      GET_VACANCY_FEED_DATA,
      GATEWAY_CONTENT,
      `/vacancy-feed${location ? `?location=${location}` : ''}`,
    ),
  )
    .then(({ data }) => dispatch(registerVacancyFeedData(data)))
    .catch(error =>
      debug(
        `Error during request - getting peoplehr vacancies feed - for location: ${location}: ${error}`,
      ),
    );
