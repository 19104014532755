import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';

import TextNew, { FontScaleType, FontWeight } from 'components/atoms/TextNew';
import LockUp from 'components/atoms/LockUp';

import './careers-image-slideshow.scss';

export type CareersImageSlideshowProps = {
  deviceState: number;
  defaultDepartment: string;
};

const departments = [
  {
    title: 'magazineAndPublications',
  },
  {
    title: 'marketingCommunicationsAndDesign',
  },
  {
    title: 'foodNutritionAndResearch',
  },
  {
    title: 'customerServices',
  },
  {
    title: 'technologyAndDigital',
  },
  {
    title: 'businessDevelopment',
  },
  {
    title: 'facilitiesAndWarehouse',
  },
  {
    title: 'businessServices',
  },
];

const CareersImageSlideshow = (
  { deviceState, defaultDepartment = 'magazineAndPublications' }: CareersImageSlideshowProps,
  {},
  className: string,
): JSX.Element => {
  const [activeDepartment, setActiveDepartment] = useState(defaultDepartment);

  useEffect(() => {
    /** Set default department so that the selected department link and description displays correctly **/
    setActiveDepartment(defaultDepartment);
    return () => setActiveDepartment(defaultDepartment);
  }, [defaultDepartment]);

  return (
    <section className={className}>
      <LockUp type="inline">
        <LockUp.Serif>meet </LockUp.Serif>
        <LockUp.Serif fontScaleType={FontScaleType.SERIF_STANDOUT_ONE}>the </LockUp.Serif>
        <LockUp.Script fontScaleType={FontScaleType.SCRIPT_DISPLAY_ONE} swoosh>
          teams
        </LockUp.Script>
      </LockUp>

      <section className="outer">
        {/* Mobile slider */}
        {deviceState <= DeviceState.MD ? (
          <>
            {departments?.map(({ title }) => (
              <div className="sliding-team-outer-wrapper" key={title}>
                <div className="sliding-team-inner-wrapper">
                  <div className={classNames('department-image', title)}></div>

                  <div className="department-name-and-description">
                    <TextNew
                      cid="department-description-small"
                      fontScaleType={FontScaleType.SERIF_STANDOUT_ONE}
                      localeId={`careersImageSlideshow.departments.${title}.name`}
                    />

                    <TextNew
                      fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}
                      localeId={`careersImageSlideshow.departments.${title}.description`}
                    />
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="department">
              {departments?.map(({ title }) => (
                <button
                  key={title}
                  className={classNames('cta', activeDepartment === title && 'is-active')}
                  onClick={() => setActiveDepartment(title)}
                >
                  <TextNew
                    fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}
                    weight={FontWeight.SEMI_BOLD}
                    localeId={`careersImageSlideshow.departments.${title}.name`}
                  />
                </button>
              ))}
            </div>

            <div className={classNames('department-image', activeDepartment)}>
              {activeDepartment && (
                <TextNew
                  fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}
                  localeId={`careersImageSlideshow.departments.${activeDepartment}.description`}
                />
              )}
            </div>
          </>
        )}
      </section>
    </section>
  );
};

CareersImageSlideshow.contextTypes = {
  getMessage: PropTypes.func.isRequired,
};

type CareersJobVacanciesFeedType = (props: CareersImageSlideshowProps) => JSX.Element;

export default compose<CareersJobVacanciesFeedType>(
  withDeviceState(),
  withFunctionalClassName(ComponentType.MOLECULE, 'CareersImageSlideshow'),
)(CareersImageSlideshow);
