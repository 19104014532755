import React from 'react';
import IconName from 'common/src/app/data/enum/IconName';
import Icon from 'components/atoms/Icon';
import TextNew, { Colors, FontScaleLineHeight, FontScaleType } from 'components/atoms/TextNew';
import useElementSize from './hooks/useElementSize';

type SwooshType = string;

const setSwoosh = (charCount: number): SwooshType => {
  if (charCount >= 9) {
    return IconName.SWOOSH_LONG;
  }
  if (charCount >= 4) {
    return IconName.SWOOSH_MEDIUM;
  }
  return IconName.SWOOSH_SHORT;
};

interface TextWithSwooshProps {
  element?: string;
  color?: string;
  fontScaleLineHeight?: string;
  fontScaleType?: string;
  children: React.ReactNode;
  swooshType?: 'short' | 'medium' | 'long';
  swooshOffSet?: number;
}

const TextWithSwoosh = ({
  element = 'span',
  color = Colors.BRAND,
  fontScaleLineHeight = FontScaleLineHeight.SMALL,
  fontScaleType = FontScaleType.SCRIPT_DISPLAY_TWO,
  children,
  swooshOffSet = 0,
}: TextWithSwooshProps): JSX.Element => {
  const charCount = typeof children === 'string' ? children.length : 0;
  const swooshIconName = setSwoosh(charCount);
  const [textRef, { height }] = useElementSize();
  const swooshHeight = height * 0.17;

  return (
    <span className={`with-swoosh ${color} `}>
      <TextNew
        textRef={textRef}
        element={element}
        color={color}
        fontScaleLineHeight={fontScaleLineHeight}
        fontScaleType={fontScaleType}
      >
        {children}
      </TextNew>
      <div className="swoosh-icon" style={{ bottom: `${swooshOffSet}px` }}>
        <Icon cid="swoosh" name={swooshIconName} height={swooshHeight || 8} />
      </div>
    </span>
  );
};

export default TextWithSwoosh;
