import createAction from 'redux-actions/lib/createAction';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { getSearchResults, SearchType } from 'common/src/app/actions/resources/searchActions';
import { API_STATIC_PUBLIC_SUCCESS_STORIES_LANDING } from 'common/src/app/data/apiStatics';
import ArticleType from 'common/src/app/data/enum/ArticleType';
import { getRecaptchaToken } from 'common/src/app/selectors/recaptchaSelector';
import StatusCode from 'common/src/app/data/enum/StatusCode';
import Pages from 'common/src/app/data/enum/Pages';
import { apiPost } from './apiActions/apiRequest';
import { getValue } from '../../util/injector';
import { GATEWAY_CONTACT, GATEWAY_CONTENT_V2, GATEWAY_COMMUNITY } from '../../data/Injectables';
import { calculateBmi } from '../../util/BmiUtils';
import { RECIPE, PUBLIC_SUCCESS_STORY, SEVEN_DAY_MENU } from '../../data/entityTypes';
import dedupeAsyncThunk from '../../util/dedupeAsyncThunk';
import apiGetEntity from './apiActions/apiGetEntity';
import { createPath } from '../../util/routeUtils';
import NotFoundErrorRedirect from '../../util/NotFoundErrorRedirect';

/**
 * Gets a public recipe by a slug
 */

export const PUBLIC_RECIPE_DETAIL = 'publicActions/PUBLIC_RECIPE_DETAIL';
export const getRecipeDetail = dedupeAsyncThunk(
  slug => dispatch =>
    dispatch(
      apiGetEntity(
        PUBLIC_RECIPE_DETAIL,
        GATEWAY_CONTENT_V2,
        `/public-recipes/${slug}`,
        RECIPE,
        {
          findEntity: entity => entity.slug === slug,
          getId: entity => entity.id,
        },
        {
          updateEntityView: 'view.pages.recipeDetail.recipeDetail',
        },
      ),
    ).catch(error => {
      const { status } = error?.response;
      // If the status is 404:
      // - redirect to the smart not found page
      // - prefixing with -recipes- (so that we know it is a recipe 404)
      if (status === StatusCode.STATUS_404) {
        const combinedSlug = `recipes-${slug}`;
        throw new NotFoundErrorRedirect(
          `${createPath(Pages.UK_PUBLIC_SMART_CONTENT_NOT_FOUND_PAGE, { slug: combinedSlug })}`,
        );
      }
      throw error;
    }),
  true,
);

// UK_PUBLIC_NEAREST_GROUP_COUNTIES

export const PUBLIC_MENU_RECIPE_DETAIL = 'publicActions/PUBLIC_MENU_RECIPE_DETAIL';
export const getMenuRecipeDetail = dedupeAsyncThunk(
  slug => dispatch =>
    dispatch(
      apiGetEntity(
        PUBLIC_MENU_RECIPE_DETAIL,
        GATEWAY_CONTENT_V2,
        `/public-recipes/${slug}`,
        SEVEN_DAY_MENU,
        {
          findEntity: entity => entity.slug === slug,
          getId: entity => entity.slug,
        },
      ),
    ),
  true,
);

/**
 * Gets a public success story/(real life story) by a slug
 */

export const PUBLIC_SUCCESS_STORIES_DETAIL = 'searchActions/PUBLIC_SUCCESS_STORIES_DETAIL';
export const getPublicDetailSuccessStories = dedupeAsyncThunk(
  slug => dispatch =>
    dispatch(
      apiGetEntity(
        PUBLIC_SUCCESS_STORIES_DETAIL,
        GATEWAY_CONTENT_V2,
        `/public-success-stories/${slug}`,
        PUBLIC_SUCCESS_STORY,
        {
          findEntity: entity => entity.link === `/public-success-stories/${slug}`,
          getId: entity => entity.id,
          transformEntity: entity => {
            const { link, ...rest } = entity;
            return {
              link: link.replace('/success-stories', '/real-life-stories'),
              ...rest,
            };
          },
        },
        {
          updateEntityView: 'view.pages.successStoriesDetail.successStory',
        },
      ),
    ).catch(error => {
      const { status } = error?.response;
      // If the status is 404:
      // - redirect to the smart not found page
      // - prefixing with -real-life-stories- (so that we know it is a real life story 404)
      if (status === StatusCode.STATUS_404) {
        const combinedSlug = `real-life-stories-${slug}`;

        throw new NotFoundErrorRedirect(
          `${createPath(Pages.UK_PUBLIC_SMART_CONTENT_NOT_FOUND_PAGE, { slug: combinedSlug })}`,
        );
      }
      throw error;
    }),
  true,
);

export const getAntiForgeryTokenCommunity = () => () =>
  getValue(GATEWAY_COMMUNITY).get('/antiforgery', null, {
    credentials: 'include',
  });

export const getAntiForgeryTokenContact = () => () =>
  getValue(GATEWAY_CONTACT).get('/antiforgery', null, {
    credentials: 'include',
  });

export const SAVE_PUBLIC_BMI = 'public-actions/SAVE_PUBLIC_BMI';
const publicBmiAction = createAction(SAVE_PUBLIC_BMI);

export const calculatePublicBmi = values => dispatch =>
  dispatch(
    publicBmiAction(Math.round(calculateBmi(values.initialWeight, values.height) * 10) / 10),
  );

/**
 * Public Success stories/(real life stories) landing page
 */

export const PUBLIC_SUCCESS_STORIES_LANDING = 'publicActions/PUBLIC_SUCCESS_STORIES_LANDING';

export const getPublicLandingSuccessStories = dedupeAsyncThunk(
  () => dispatch =>
    dispatch(
      getStaticContent(API_STATIC_PUBLIC_SUCCESS_STORIES_LANDING, true, entity => {
        const { featuredStory, featuredStories, ...rest } = entity;
        // Change the featuredStory type to be PublicSuccessStoryTile
        if (featuredStory) {
          // eslint-disable-next-line no-underscore-dangle
          featuredStory._type = ArticleType.PUBLIC_SUCCESS_STORY;
        }

        if (featuredStories?.length > 0) {
          // Change the featuredStory type to be PublicSuccessStoryTile - for any storys within the featuredStories array
          // eslint-disable-next-line no-underscore-dangle, no-param-reassign
          featuredStories.forEach(el => (el._type = ArticleType.PUBLIC_SUCCESS_STORY));
        }

        return {
          featuredStory,
          featuredStories,
          ...rest,
        };
      }),
    ).then(dispatch(getSearchResults(SearchType.PUBLIC_SUCCESS_STORIES))),
  true,
);

/**
 * SevenDayMenu - newsletter signup form submission
 */

export const SEVEN_DAY_MENU_SIGNUP = 'publicActions/SEVEN_DAY_MENU_SIGNUP';

export const sevenDayNewsletterSignUp = values => (dispatch, getState) => {
  // get the recaptchaToken from state
  // if the token is not found in state - the back end will fail and display an error to the signing up member
  const recaptchaToken = getRecaptchaToken(getState());

  const mergedValues = { ...values, recaptchaToken };

  return dispatch(getAntiForgeryTokenContact()).then(result =>
    dispatch(
      apiPost(SEVEN_DAY_MENU_SIGNUP, GATEWAY_CONTACT, '/subscribers', mergedValues, {
        headers: {
          'X-XSRF-Token': result.data,
        },
        credentials: 'include',
      }),
    ).then(flowResult => flowResult),
  );
};

/**
 * SevenDayMenu - newsletter signup email confirmation
 * confirm the members email address, using the passed email address and token
 * this endpooint adds them onto the subscriber mailing list
 */

export const CONFIRM_NEWSLETTER_SIGNUP = 'publicActions/CONFIRM_NEWSLETTER_SIGNUP';

export const confirmNewsletterSignup = (id, token) => dispatch =>
  dispatch(getAntiForgeryTokenContact()).then(result =>
    dispatch(
      apiPost(
        CONFIRM_NEWSLETTER_SIGNUP,
        GATEWAY_CONTACT,
        '/subscribers/verify',
        {
          id,
          token,
        },
        {
          headers: {
            'X-XSRF-Token': result.data,
          },
          credentials: 'include',
        },
      ),
    ).then(validationResult => validationResult?.data),
  );

export const PERSIST_HIDE_NAV = 'publicActions/PERSIST_HIDE_NAV';
export const setPersistHideNav = createAction(PERSIST_HIDE_NAV);
