import createAction from 'redux-actions/lib/createAction';
import SortType from '../../data/enum/SortType';
import ArticleType from '../../data/enum/ArticleType';
import {
  GATEWAY_CONTENT_V2,
  GATEWAY_CONTENT_V2_AUTH,
  GATEWAY_GROUP_SEARCH,
} from '../../data/Injectables';
import { SEARCH, PROMOTED_SEARCH } from '../../data/entityTypes';
import apiGetCollection, { GET_NEXT } from './apiActions/apiGetCollection';
import {
  searchResultsCollectionId,
  promotedSearchResultsCollectionId,
} from '../../data/collectionIds';
import { SEARCH_RESULTS, PROMOTED_SEARCH_RESULTS } from '../../data/collectionPaginationViews';
import { collectionClearAll } from '../entities/collectionActions';

export const SearchType = {
  TOP: 'top',
  PUBLIC_RECIPE: 'public-recipes',
  RECIPE: 'recipes',
  SUCCESS_STORY: 'success-stories',
  PUBLIC_SUCCESS_STORIES: 'public-success-stories',
  FEATURE: 'features',
  GROUP: 'groups',
  PROMOTED: 'promoted',
};

/* eslint-disable no-underscore-dangle */
const correctType = (type, apiEntity) => {
  switch (type) {
    case SearchType.PUBLIC_SUCCESS_STORIES:
      return ArticleType.PUBLIC_SUCCESS_STORY;
    default:
      return apiEntity._type;
  }
};

/* eslint-enable no-underscore-dangle */
const correctId = (type, apiEntity) => `${type}-${apiEntity.id}`;

const getGateway = type => {
  switch (type) {
    case SearchType.GROUP:
      return GATEWAY_GROUP_SEARCH;
    case SearchType.PUBLIC_RECIPE:
    case SearchType.PUBLIC_SUCCESS_STORIES:
      return GATEWAY_CONTENT_V2;
    default:
      return GATEWAY_CONTENT_V2_AUTH;
  }
};

export const GET_SEARCH_RESULTS = 'searchActions/GET_SEARCH_RESULTS';

export const getSearchResults = (
  type,
  query,
  loadMore,
  mock = null,
  requestOptions = null,
  excludedIds,
) => dispatch => {
  const requestPagination = { limit: (query && query.limit) || 12 };

  if (query && query.offset) {
    requestPagination.offset = query.offset;
  } else if (loadMore) {
    requestPagination.offset = GET_NEXT;
  } else {
    requestPagination.offset = 0;
  }
  const sort = !query || !query.sort || query.sort === '0' ? undefined : SortType.NEWEST;

  const requestData = {
    ...query,
    sort,
    excludedIds,
  };

  // The url appends the UK spelling however the api requires the American spelling
  if (query?.foodOptimising) {
    requestData.foodOptimizing = query.foodOptimising;
  }

  // The api requires mealtype, while the filterKey recognized in the code is mealType
  if (query?.mealType) {
    requestData.mealType = query.mealType;
  }

  const path = `${type === SearchType.TOP ? '' : `/${type}`}/search`;
  const params = {
    type: type === SearchType.TOP ? 'top' : type,
    ...query,
  };
  return dispatch(
    apiGetCollection(
      GET_SEARCH_RESULTS,
      getGateway(type),
      path,
      searchResultsCollectionId({ ...params, ...requestData }),
      requestPagination,
      {
        requestData,
        requestOptions,
        updatePaginationView: {
          target: SEARCH_RESULTS,
          extend: true,
        },
        entityType: SEARCH,
        getId: entity => correctId(type, entity),
        transformEntity: apiEntity => ({
          ...apiEntity,
          // Build up the slug from the link, if the slug does not exist
          // this should only be for recipes, so I have removed the initial part of the link
          slug: apiEntity.slug || (apiEntity.link && apiEntity.link.replace('/recipes/', '')),
          _type: correctType(type, apiEntity),
        }),
        _response: mock,
      },
    ),
  );
};

export const clearSearchResults = () => collectionClearAll(SEARCH_RESULTS);

export const ADD_RECENT_SEARCH = 'searchActions/ADD_RECENT_SEARCH';

export const addRecentSearch = createAction(ADD_RECENT_SEARCH, query => ({
  query,
  createdTime: new Date().getTime(),
}));

export const GET_PROMOTED_SEARCH_RESULTS = 'searchActions/GET_PROMOTED_SEARCH_RESULTS';

export const getPromotedSearchResults = (query, mock = null, requestOptions = null) => dispatch => {
  const params = {
    type: SearchType.PROMOTED,
    ...query,
  };

  const requestData = {
    ...query,
  };

  return dispatch(
    apiGetCollection(
      GET_PROMOTED_SEARCH_RESULTS,
      GATEWAY_CONTENT_V2_AUTH,
      `/promoted-search/${query.query}`,
      promotedSearchResultsCollectionId({ ...params, ...requestData }),
      {},
      {
        requestData,
        requestOptions,
        updatePaginationView: {
          target: PROMOTED_SEARCH_RESULTS,
          extend: true,
        },
        entityType: PROMOTED_SEARCH,
        getId: entity => `${SearchType.PROMOTED}-${entity.id}`,
        transformEntity: apiEntity => ({
          ...apiEntity,
          isInternal: !apiEntity.link.startsWith('http'),
        }),
        _response: mock,
      },
    ),
  );
};

export const clearPromotedSearchResults = () => collectionClearAll(PROMOTED_SEARCH_RESULTS);
