import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';

import RecruitmentCtaBlock from 'components/organisms/RecruitmentCtaBlock';
import Loader from 'components/atoms/Loader';
import Wrapper from 'components/atoms/Wrapper';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import TextNew, {
  Colors,
  ElementTypes,
  FontScaleType,
  FontScaleLineHeight,
} from 'components/atoms/TextNew';

import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';

// Types
import DeviceStateType from 'components/types/DeviceState';
import LockUp from 'components/atoms/LockUp';
import PageDataType from '../../../types/PageData';

import careersPageNames from '../../../../../../data/enum/careersPageNames';

import CareersBlockContent from '../../molecules/CareersBlockContent';

type BecomeADistrictManagerProps = {
  deviceState: DeviceStateType;
  loading: boolean;
  pageData: PageDataType;
};

const BecomeADistrictManager = (
  { deviceState, loading, pageData }: BecomeADistrictManagerProps,
  {},
  className: string,
): JSX.Element => {
  const headerSelector = () => {
    if (!pageData) return null;

    if (deviceState >= DeviceState.XL) {
      return pageData?.ultraWideHeaderImage;
    } else if (deviceState >= DeviceState.LG) {
      return pageData?.desktopHeaderImage;
    } else if (deviceState > DeviceState.MD) {
      return pageData?.tabletHeaderImage;
    } else if (deviceState > DeviceState.SM) {
      return pageData?.largeMobileHeaderImage;
    }
    return pageData?.smallMobileHeaderImage;
  };

  return (
    <div className={className}>
      <HeroBarPublic image={headerSelector() || pageData?.desktopHeaderImage}>
        <header className="header-lockup">
          <TextNew
            element={ElementTypes.H1}
            localeId="becomeADistrictManager.line1"
            color={Colors.BRAND}
            fontScaleLineHeight={FontScaleLineHeight.SMALL}
            fontScaleType={FontScaleType.SCRIPT_DISPLAY_ONE}
          />
        </header>
      </HeroBarPublic>

      {loading && <Loader />}

      {!loading && (
        <Wrapper cid="content-wrapper">
          {pageData?.description && (
            <RichTextBlock cid="description" text={pageData?.description} />
          )}
          {pageData?.subdescription && (
            <RichTextBlock cid="sub-description" text={pageData?.subdescription} />
          )}

          {pageData && <CareersBlockContent pageData={pageData} deviceState={deviceState} />}
        </Wrapper>
      )}

      <RecruitmentCtaBlock
        lockUp={
          <LockUp type="inline" element="h3">
            <LockUp.Serif>your </LockUp.Serif>
            <LockUp.Script>future </LockUp.Script>
            <LockUp.Serif>starts </LockUp.Serif>
            <LockUp.Script swoosh>here!</LockUp.Script>
          </LockUp>
        }
        localePrefix={careersPageNames.BECOME_A_DISTRICT_MANAGER}
        site="careers"
      />
    </div>
  );
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.TEMPLATE, 'BecomeADistrictManager')(BecomeADistrictManager),
);
