import React, { useState } from 'react';
import classNames from 'classnames';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import IconName from 'common/src/app/data/enum/IconName';
import DirectionType from 'common/src/app/data/enum/DirectionType';
import Icon from 'components/atoms/Icon';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import RichTextWithImageBlock from 'components/molecules/BlockContent/components/atoms/RichTextWithImageBlock';
import { FaqSeo, schemaUrl } from 'common/src/app/data/enum/SeoSchema';
import ImageType from 'components/types/Image';

import './recruitment-faq-question-answer.scss';

export type RecruitmentFaqQuestionAnswerProps = {
  title: string;
  text: string;
  image: ImageType;
  imageHorizontalPosition: 'left' | 'right';
  imageVerticalPosition: 'top' | 'bottom';
};

const RecruitmentFaqQuestionAnswer = (
  {
    title,
    text,
    image,
    imageHorizontalPosition,
    imageVerticalPosition,
  }: RecruitmentFaqQuestionAnswerProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => {
  const [isCollapsed, setCollapsed] = useState(true);

  const handleQuestionClick = () => setCollapsed(!isCollapsed);

  const chevron = (
    <Icon
      name={IconName.CHEVRON}
      direction={isCollapsed ? DirectionType.DOWN : DirectionType.UP}
      width={18}
    />
  );

  return (
    <div
      className={classNames(className, { 'is-collapsed': isCollapsed })}
      itemScope
      itemProp={FaqSeo.ITEM_PROP.MAIN_ENTITY}
      itemType={`${schemaUrl}${FaqSeo.SCHEMA.QUESTION}`}
      data-testid={dataTestId}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */}
      <div
        className="question-title"
        onClick={handleQuestionClick}
        itemProp={FaqSeo.ITEM_PROP.NAME}
      >
        {title} {chevron}
      </div>
      <div
        className="answer-text"
        itemScope
        itemProp={FaqSeo.ITEM_PROP.ACCEPTED_ANSWER}
        itemType={`${schemaUrl}${FaqSeo.SCHEMA.ANSWER}`}
      >
        <div itemProp={FaqSeo.ITEM_PROP.TEXT}>
          {image ? (
            <RichTextWithImageBlock
              text={text}
              image={image}
              imageHorizontalPosition={imageHorizontalPosition}
              imageVerticalPosition={imageVerticalPosition}
            />
          ) : (
            <RichTextBlock text={text} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'RecruitmentFaqQuestionAnswer',
)(RecruitmentFaqQuestionAnswer);
