import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import Loader from 'components/atoms/Loader';
import RecruitmentCtaBlock from 'components/organisms/RecruitmentCtaBlock';
import RecruitmentPageTitleHeader from 'components/molecules/RecruitmentPageTitleHeader';

import ConsultantBlock from '../../organisms/ConsultantBlock';
import consultantPageNames from '../../../../../../data/enum/consultantPageNames';

import './our-consultants.scss';

export type OurConsultantsProps = {
  brightcoveAccountId: string;
  brightcovePlayerId: string;
  trackVideoEvent: (event: {
    meta: any;
    eventType: string;
    playerType: string;
    videoId: string;
  }) => void;
  loading: boolean;
  pageData: any; // @TO:DO
};

const OurConsultants = (
  {
    brightcoveAccountId,
    brightcovePlayerId,
    trackVideoEvent,
    loading,
    pageData,
  }: OurConsultantsProps,
  {},
  className: string,
): JSX.Element => (
  <div className={className}>
    <RecruitmentPageTitleHeader localePrefix={consultantPageNames.OUR_CONSULTANTS} />

    {loading && <Loader />}

    {!loading &&
      pageData?.nestedContent.map(({ _type, ...content }: { _type: string }, index: number) => {
        if (_type === types.ROLE_BASED_CONTENT) {
          return (
            <ConsultantBlock
              {...content}
              key={`${_type}-${index}`}
              brightcoveAccountId={brightcoveAccountId}
              brightcovePlayerId={brightcovePlayerId}
              trackVideoEvent={trackVideoEvent}
            />
          );
        }
        return null;
      })}
    <RecruitmentCtaBlock />
  </div>
);

export default withFunctionalClassName(ComponentType.TEMPLATE, 'OurConsultants')(OurConsultants);
