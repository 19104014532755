import defaultReducers from 'common/src/app/reducers/defaultReducers';
import combineReducersNamed from 'common/src/app/util/reducers/combineReducersNamed';
import geoLocation from 'common/src/app/reducers/geoLocationReducer';
import persistHideNav from './persistNavReducer';
import newsletterSignupEmailConfirmation from './newsletterSignupEmailConfirmationReducer';
import view from './viewReducer';
import vacancyFeed from './vacancyFeedReducer';

const appReducer = combineReducersNamed({
  ...defaultReducers,
  view,
  geoLocation,
  persistHideNav,
  newsletterSignupEmailConfirmation,
  vacancyFeed,
})('');

export default appReducer;
