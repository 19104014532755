import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Size from 'common/src/app/data/enum/Size';
import createScriptTitle from 'common/src/app/util/createScriptTitle';
import { schemaUrl, FaqSeo } from 'common/src/app/data/enum/SeoSchema';

import Wrapper from 'components/atoms/Wrapper';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import Loader from 'components/atoms/Loader';
import RecruitmentCtaBlock from 'components/organisms/RecruitmentCtaBlock';
import RecruitmentFaq from 'components/organisms/RecruitmentFaq';
import RecruitmentPageTitleHeader from 'components/molecules/RecruitmentPageTitleHeader';

import consultantPageNames from '../../../../../../data/enum/consultantPageNames';

import './franchise-facts.scss';

export type FranchiseFactsProps = {
  loading: boolean;
  pageData: any; // @TODO
};

const FranchiseFacts = (
  { loading, pageData }: FranchiseFactsProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => (
  <div
    className={className}
    itemScope
    itemType={`${schemaUrl}${FaqSeo.SCHEMA.FAQ_PAGE}`}
    data-testid={dataTestId}
  >
    <RecruitmentPageTitleHeader hasSwoosh localePrefix={consultantPageNames.FRANCHISE_FACTS} />

    {loading && <Loader />}

    <Wrapper cid="content-wrapper" padding={Size.XL}>
      {!loading &&
        // eslint-disable-next-line react/prop-types
        pageData?.nestedContent.map(
          (
            { _type, ...content }: { _type: string; title: string; text: string },
            index: number,
          ) => {
            switch (_type) {
              case types.ROLE_BASED_CONTENT:
                return <RecruitmentFaq {...content} key={`franchise-facts-block-${index}`} />;

              default:
                return (
                  <RichTextBlock
                    title={createScriptTitle(content.title)}
                    text={createScriptTitle(content.text)}
                    key={`franchise-facts-block-${index}`}
                  />
                );
            }
          },
        )}
    </Wrapper>
    <RecruitmentCtaBlock localePrefix={consultantPageNames.FRANCHISE_FACTS} />
  </div>
);

export default withFunctionalClassName(ComponentType.TEMPLATE, 'FranchiseFacts')(FranchiseFacts);
