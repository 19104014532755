/**
 * This module contains strings that can be passed to {@link module:Injector~getValue|getValue}
 * to retrieve an injected object
 * @module Injectables
 */

/**
 * The authentication manager on the client
 * @type {string}
 */
export const AUTHENTICATION_MANAGER = 'authentication_manager';

/**
 * The Gateway instance for the account api (without authentication)
 * @type {string}
 */
export const GATEWAY_ACCOUNT = 'gateway_account';

/**
 * The Gateway instance for the account api (without authentication)
 * @type {string}
 */
export const GATEWAY_ACCOUNT_MIGRATION = 'gateway_account_migration';

/**
 * The Gateway instance for the account identity server api
 * @type {string}
 */
export const GATEWAY_ACCOUNT_IDS = 'gateway_account_identity_server';

/**
 * The Gateway instance for the account api (with authentication)
 * @type {string}
 */
export const GATEWAY_ACCOUNT_AUTH = 'gateway_account_auth';

/**
 * The Gateway instance for the contact api (without authentication)
 * @type {string}
 */
export const GATEWAY_CONTACT = 'gateway_contact';

/**
 * The Gateway instance for the content api (without authentication)
 * @type {string}
 */
export const GATEWAY_CONTENT = 'gateway_content';

/**
 * The Gateway instancefor the content v2 api (without authentication)
 * @type {string}
 */
export const GATEWAY_CONTENT_V2 = 'gateway_content_v2';

/**
 * The Gateway instance for the community api (without authentication)
 * @type {string}
 */
export const GATEWAY_COMMUNITY = 'gateway_community';
/**
 * The Gateway instance for the community v2 api (without authentication)
 * @type {string}
 */
export const GATEWAY_COMMUNITY_V2 = 'gateway_community_v2';
/**
 * The Gateway instance for the content v2 api (with authentication)
 * @type {string}
 */
export const GATEWAY_CONTENT_V2_AUTH = 'gateway_content_auth_v2';

/**
 * The Gateway instance for the community api (with authentication)
 * @type {string}
 */
export const GATEWAY_COMMUNITY_AUTH = 'gateway_community_auth';

/**
 * The Gateway instance for the community v2 api (with authentication)
 * @type {string}
 */
export const GATEWAY_COMMUNITY_V2_AUTH = 'gateway_community_v2_auth';

/**
 * The Gateway instance for the community v3 api (with authentication)
 * @type {string}
 */
export const GATEWAY_COMMUNITY_V3_AUTH = 'gateway_community_v3_auth';

/**
 * The Gateway instance for the deal api with authentication
 * @type {string}
 */
export const GATEWAY_DEAL_AUTH = 'gateway_deal_auth';

/**
 * The Gateway instance for the deal api without authentication
 * @type {string}
 */
export const GATEWAY_DEAL = 'gateway_deal';

/**
 * The Gateway instance for the shop api without authentication
 * @type {string}
 */
export const GATEWAY_SHOP = 'gateway_shop';

/**
 * The Gateway instance for the shop api with authentication
 * @type {string}
 */
export const GATEWAY_SHOP_AUTH = 'gateway_shop_auth';

/**
 * The Gateway instance for the current host of the servie itself
 * @type {string}
 */
export const GATEWAY_SELF = 'gateway_self';

/**
 * The Gateway instance for the chat api (with authentication)
 * @type {string}
 */
export const GATEWAY_LIVE_AUTH = 'gateway_live_auth';

/**
 * The Gateway instance for the food api (without authentication)
 * @type {string}
 */
export const GATEWAY_FOOD = 'gateway_food';

/**
 * The Gateway instance for the food api (with authentication)
 * @type {string}
 */
export const GATEWAY_FOOD_AUTH = 'gateway_food_auth';

/**
 * The Gateway instance for the payment api (with authentication)
 * @type {string}
 */
export const GATEWAY_PAYMENT_AUTH = 'gateway_payment_auth';

/**
 * The Gateway instance for the group account api (without authentication)
 * @type {string}
 */
export const GATEWAY_GROUP_ACCOUNT = 'gateway_group_account';

/**
 * The Gateway instance for the group account api (with authentication)
 * @type {string}
 */
export const GATEWAY_GROUP_ACCOUNT_AUTH = 'gateway_group_account_auth';

/**
 * The Gateway instance for the group search api (without authentication)
 * @type {string}
 */
export const GATEWAY_GROUP_SEARCH = 'gateway_group_search';

/**
 * The Gateway instance for the message api (with authentication)
 * @type {string}
 */
export const GATEWAY_MESSAGE_AUTH = 'gateway_message_auth';

/**
 * The Gateway instance for the activity api (without authentication)
 * @type {string}
 */
export const GATEWAY_ACTIVITY = 'gateway_activity';

/**
 * The Gateway instance for the activity api (with authentication)
 * @type {string}
 */
export const GATEWAY_ACTIVITY_AUTH = 'gateway_activity_auth';

/**
 * The Gateway instance for the message api (with authentication)
 * @type {string}
 */
export const GATEWAY_MIGRATION_AUTH = 'gateway_migration_auth';

/**
 * The Gateway instance for the message api (with authentication)
 * @type {string}
 */
export const GATEWAY_PUBLICITY_AUTH = 'gateway_publicity_auth';

/**
 * The Gateway instance for the account api (without the path api/v1)
 * @type {string}
 */
export const GATEWAY_ACCOUNT_WITHOUT_PATH = 'gateway_account_without_path';

/**
 * The Gateway instance for the advertisement api
 * @type {string}
 */
export const GATEWAY_ADVERTISEMENT = 'gateway_advertisement';
