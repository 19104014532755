import { compose } from 'redux';
import { connect } from 'react-redux';
import { withInitAction } from '@mediamonks/react-redux-component-init';
import {
  GET_STATIC_CONTENT,
  getStaticContent,
} from 'common/src/app/actions/resources/staticContentActions';
import { API_STATIC_PUBLIC_CAREERS_JOIN_THE_HEAD_OFFICE_TEAM } from 'common/src/app/data/apiStatics';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import { getVacancyFeedData } from 'common/src/app/actions/vacancyFeedActions';

import JoinTheHeadOfficeTeam from './JoinTheHeadOfficeTeam';

const pageLoading = makeIsLoadingSelector(GET_STATIC_CONTENT);

const mapStateToProps = (state: any) => ({
  loading: pageLoading(state),
  pageData: staticPageSelector(state, API_STATIC_PUBLIC_CAREERS_JOIN_THE_HEAD_OFFICE_TEAM),
});

const connected = connect(mapStateToProps);

const addInitAction = withInitAction({
  prepared: ({}, dispatch: any) =>
    Promise.all([
      dispatch(getStaticContent(API_STATIC_PUBLIC_CAREERS_JOIN_THE_HEAD_OFFICE_TEAM, true)),
      dispatch(getVacancyFeedData('HeadOffice')),
    ]),
});

export default compose(addInitAction, connected)(JoinTheHeadOfficeTeam);
