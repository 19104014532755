import { useRef, useEffect, useState, RefObject } from 'react';

type ElementSize = {
  width: number;
  height: number;
};

const useElementSize = (): [RefObject<HTMLElement>, ElementSize] => {
  const elementRef = useRef<HTMLElement>(null);
  const [size, setSize] = useState<ElementSize>({ width: 0, height: 0 });

  useEffect(() => {
    const calculateSize = () => {
      if (elementRef.current) {
        const { width, height } = elementRef.current.getBoundingClientRect();
        setSize({ width, height });
      }
    };

    const handleResize = () => {
      // Debounce the resize event to avoid excessive calculations
      const timer = setTimeout(() => {
        calculateSize();
      }, 200);

      return () => clearTimeout(timer);
    };

    // Calculate initial size with a delay to ensure styles are loaded
    const initialTimer = setTimeout(() => {
      calculateSize();
    }, 200);

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(initialTimer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return [elementRef, size];
};

export default useElementSize;
