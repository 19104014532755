import React from 'react';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import LockUp from 'components/atoms/LockUp';
import TextNew, { FontScaleType, FontScaleLineHeight } from 'components/atoms/TextNew';

import IndividualCircle from './components/IndividualCircle';

import './careers-sliding-overlay.scss';

const circleData = [
  {
    id: 'motivate',
    localeParams: {
      LEAD: <strong>Lead, motivate and empower</strong>,
    },
  },
  {
    id: 'build',
    localeParams: {
      BUILD: <strong>Build affiliation</strong>,
    },
  },
  {
    id: 'develop',
    localeParams: {
      COACH: <strong>Coach and develop</strong>,
    },
  },
  {
    id: 'recruitment',
    localeParams: {
      RECRUITEMENT: <strong>recruitment activities</strong>,
    },
  },
  {
    id: 'training',
    localeParams: {
      TRAINING: <strong>training activities on your district</strong>,
    },
  },
  {
    id: 'head-office',
    localeParams: {
      VISITS: <strong>visits to Head Office for training</strong>,
    },
  },
];

const CareersSlidingOverlay = ({}, {}, className: string): JSX.Element => (
  <section className={className}>
    <LockUp type="inline">
      <LockUp.Serif>What’s </LockUp.Serif>
      <LockUp.Script>involved </LockUp.Script>
      <LockUp.Serif>in the District Manager role</LockUp.Serif>
    </LockUp>

    <div className="scrolling-image-container">
      <div className="circles">
        {circleData.map(({ id, localeParams }, index) => (
          <IndividualCircle
            key={id}
            id={id}
            description={
              <TextNew
                fontScaleLineHeight={FontScaleLineHeight.MEDIUM}
                fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}
                localeId={`careersSlidingOverlay.circle${index + 1}.description`}
                localeParams={localeParams}
              />
            }
          />
        ))}
      </div>
    </div>
  </section>
);

export default compose(withFunctionalClassName(ComponentType.MOLECULE, 'CareersSlidingOverlay'))(
  CareersSlidingOverlay,
);
