import React from 'react';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import CmsDirectionType from 'common/src/app/data/enum/CmsDirectionType';
import Size from 'common/src/app/data/enum/Size';
import Icons from 'common/src/app/data/enum/IconName';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import createScriptTitle from 'common/src/app/util/createScriptTitle';

import Wrapper from 'components/atoms/Wrapper';
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import LocaleMessage from 'components/atoms/LocaleMessage';

import './consultant-block.scss';

const ConsultantBlock = (
  { brightcoveAccountId, brightcovePlayerId, trackVideoEvent, nestedRoleContent, openModal },
  context,
  className,
) => {
  const openVideoModal = video =>
    openModal(
      ModalNames.VIDEO,
      {
        video: video.video,
        brightcoveAccountId,
        brightcovePlayerId,
        trackVideoEvent,
      },
      false,
      false,
      true,
    );

  return (
    <div className={className}>
      {nestedRoleContent.map(({ _type, ...content }, index) => {
        switch (_type) {
          case types.RICH_TEXT_WITH_IMAGE_BLOCK:
            return (
              <Wrapper.MD
                padding={Size.SM}
                key={`consultant-block-${index}`}
                cid={
                  content.imageHorizontalPosition === CmsDirectionType.LEFT ? 'has-image-left' : ''
                }
              >
                {content.image && (
                  <ResponsiveImage
                    src={content.image.src}
                    alt={content.image.alt}
                    ratio={1}
                    px={508}
                  />
                )}
                <div className="text-content">
                  <QuotationBlock quotation={createScriptTitle(content.title)} isConsultant />
                  <RichTextBlock text={content.text} />
                </div>
              </Wrapper.MD>
            );
          case types.VIDEO_WITH_THUMBNAIL_BLOCK:
            return (
              <Wrapper.MD padding={Size.SM} key={`consultant-block-${index}`}>
                <Button isLarge onClick={() => openVideoModal(content.video)}>
                  <Icon name={Icons.PLAY} height={12} />
                  <LocaleMessage id="consultantBlock.cta" />
                </Button>
              </Wrapper.MD>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

ConsultantBlock.propTypes = {
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
  trackVideoEvent: PropTypes.func,
  nestedRoleContent: PropTypes.array.isRequired,
  openModal: PropTypes.func,
};

export default withFunctionalClassName(ComponentType.ORGANISM, 'ConsultantBlock')(ConsultantBlock);
