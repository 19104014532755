/**
 * Prefered usage
 */
export const FontWeight = {
  LIGHT: 'light',
  NORMAL: 'normal',
  SEMI_BOLD: 'semi-bold',
  BOLD: 'bold',
  BOLDER: 'bolder',
};
FontWeight.propTypes = Object.values(FontWeight);

export const FontFamily = {
  SANS: 'sans',
  SERIF: 'serif',
  SCRIPT: 'script',
};
FontFamily.propTypes = Object.values(FontFamily);

export const FontStyles = {
  NORMAL: 'normal',
  ITALIC: 'italic',
};
FontStyles.propTypes = Object.values(FontStyles);

export const FontSizes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
};
FontSizes.propTypes = Object.values(FontSizes);

export const TextDecoration = {
  UNDERLINE: 'underline',
};
TextDecoration.propTypes = Object.values(TextDecoration);

/**
 * Market differences for text styles
 */

const TextTypes = {
  SCRIPT_LG: 'script-lg',
  SCRIPT_MD: 'script-md',
  SCRIPT_SM: 'script-sm',
  SCRIPT_XS: 'script-xs',
  LOUD: 'loud',
  CONFIDENT: 'confident',
  STRONG: 'strong',
  DETAILS: 'details',
  FORMAL: 'formal',
  FORMAL_ORIGINAL: 'formal-original',
  FORMAL_LIGHT: 'formal-light',
  PRIMARY_ELEGANT: 'primary-elegant',
  PRIMARY_ORIGINAL: 'primary-original',
  PRIMARY_FOOTNOTE: 'primary-footnote',
  ERROR: 'error',
  SUCCESS: 'success',
  QUIET: 'quiet',
  FORM: 'form',
};

export const FontScaleLineHeight = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

FontScaleLineHeight.propTypes = Object.values(FontScaleLineHeight);

/**
 * Type scale styles
 */
export const FontScaleType = {
  SANS_SERIF_STANDOUT_TWO: 'sans-serif-standout-two',
  SANS_SERIF_BODY_ONE: 'sans-serif-body-one',
  SANS_SERIF_BODY_TWO: 'sans-serif-body-two',
  SANS_SERIF_NOTE: 'sans-serif-note',
  SERIF_DISPLAY_TWO: 'serif-display-two',
  SERIF_DISPLAY_THREE: 'serif-display-three',
  SERIF_STANDOUT_ONE: 'serif-standout-one',
  SERIF_STANDOUT_TWO: 'serif-standout-two',
  SCRIPT_DISPLAY_ONE: 'script-display-one',
  SCRIPT_DISPLAY_TWO: 'script-display-two',
  SCRIPT_DISPLAY_THREE: 'script-display-three',
  SCRIPT_STANDOUT_ONE: 'script-standout-one',
  SCRIPT_STANDOUT_TWO: 'script-standout-two',
  SCRIPT_BODY_ONE: 'script-body-one',
};

FontScaleType.propTypes = Object.values(FontScaleType);

TextTypes.propTypes = Object.values(TextTypes);

export default TextTypes;
