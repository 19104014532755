/**
 * Renders text using one of our pre-defined text styles.
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Colors, {
  AccentColors,
  INHERIT,
  Shades,
  OnboarderColors,
} from 'common/src/app/data/enum/Colors';
import ElementTypes from 'common/src/app/data/enum/ElementTypes';
import TextTypes, {
  FontWeight,
  FontFamily,
  FontStyles,
  FontScaleType,
  FontScaleLineHeight,
  FontSizes,
  TextDecoration,
} from 'common/src/app/data/enum/TextTypes';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import LocaleMessage from '../LocaleMessage';
import './text-new.scss';

/**
 * Formats given type to valid component name
 *
 * @example
 * primary-original => PrimaryOriginal
 * something_else => SomethingElse
 */
const typeToNamespace = type => type.replace(/-?\b\w/g, c => c.replace(/\W/, '').toUpperCase());

/**
 * Renders text according to one of our predefined text styles
 *
 * Component can be redndered with props or dot notation
 * n.b. dot notation will not work with weight or style,
 * as these on not regulally used
 *
 * @name TextNew
 * @param {node} children - child component to render
 * @param {string} element - HTML to render
 * @param {string} color
 * @param {string} style - text decoration such as underline and strike though
 * @param {string} family - font fmaily to render, oneOf serif, sans, script
 * @param {string} size - font size to render, oneOf xs,sm,md,lg,xl
 * @param {string} weight - font weight to render, oneOf light, normal, bold, bolder
 * @param {string} style - font style to render, oneOf normal, italic
 *
 * @example
 *
 * <TextNew family="serif" size="md">Genesis</TextNew>
 * <TextNew.Serif.MD>Collins</TextNew.Serif.MD>
 */
const TextNewNoClassName = (
  { children, element, localeId, localeParams, textRef, htmlFor, id, ariaHidden, role, itemProp },
  context,
  className,
  dataTestid,
) => {
  const HtmlElement = element;
  return (
    <HtmlElement
      role={role}
      className={className}
      ref={textRef}
      htmlFor={htmlFor}
      aria-hidden={ariaHidden}
      id={id}
      itemProp={itemProp}
      data-testid={dataTestid}
    >
      {localeId && <LocaleMessage key={localeId} id={localeId} params={localeParams} />}
      {children}
    </HtmlElement>
  );
};

TextNewNoClassName.defaultProps = {
  element: ElementTypes.P,
  color: Colors.PRIMARY_DARK,
};

TextNewNoClassName.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node,
  ariaHidden: PropTypes.bool,
  element: PropTypes.oneOf(ElementTypes.propTypes),
  /* eslint-disable react/no-unused-prop-types */
  decoration: PropTypes.oneOf(TextDecoration.propTypes),
  /* eslint-disable react/no-unused-prop-types */
  color: PropTypes.oneOf([
    ...Colors.propTypes,
    ...AccentColors.propTypes,
    ...OnboarderColors.propTypes,
    INHERIT,
  ]),
  family: PropTypes.oneOf(FontFamily.propTypes),
  size: PropTypes.oneOf(FontSizes.propTypes),
  weight: PropTypes.oneOf(FontWeight.propTypes),
  style: PropTypes.oneOf(FontStyles.propTypes),
  shade: PropTypes.oneOf(Shades.propTypes),
  localeId: PropTypes.string,
  localeParams: PropTypes.object,
  textRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),
  id: PropTypes.string,
  role: PropTypes.string,
  itemProp: PropTypes.string,
  fontScaleType: PropTypes.oneOf(FontScaleType.propTypes),
  fontScaleLineHeight: PropTypes.oneOf(FontScaleLineHeight.propTypes),
  /* eslint-enable react/no-unused-prop-types */
};

const TextNew = memo(
  withFunctionalClassName(ComponentType.ATOM, 'TextNew', [
    ({ color }) => color && [`${color}-color`],
    ({ shade }) => shade && [`${shade}-shade`],
    ({ decoration }) => decoration && [`${decoration}-decoration`],
    ({ family, size }) => family && size && [`${family}-${size}`],
    ({ weight }) => weight && [`${weight}-weight`],
    ({ style }) => style && [`${style}-style`],
    ({ fontScaleType }) => fontScaleType && [`font-scale-type-${fontScaleType}`],
    ({ fontScaleLineHeight }) =>
      fontScaleLineHeight && [`font-scale-line-height-${fontScaleLineHeight}`],
    ({ type }) => type && [`${type.toLowerCase()}-type`],
  ])(TextNewNoClassName),
);

/**
 * TextNew dot notation components
 *
 * usage: <TextNew.Serif.SM>Small Serif text</TextNew.Serif.SM>
 */
FontFamily.propTypes.forEach(familyType => {
  const familyTypeCased = typeToNamespace(familyType);

  TextNew[familyTypeCased] = props => <TextNew family={familyType} {...props} />;

  FontSizes.propTypes.forEach(fontSize => {
    TextNew[familyTypeCased][fontSize.toUpperCase()] = props => (
      <TextNew family={familyType} size={fontSize} {...props} />
    );
  });
});

/**
 * Add dot notation for the textTypes - which are used for market difference between text types
 */
TextTypes.propTypes.forEach(textType => {
  const textTypeCased = typeToNamespace(textType);
  TextNew[textTypeCased] = props => <TextNew type={textType} {...props} />;
});

export {
  FontWeight,
  FontFamily,
  FontStyles,
  FontSizes,
  FontScaleType,
  FontScaleLineHeight,
  TextTypes,
  ElementTypes,
  Colors,
  Shades,
  AccentColors,
  TextDecoration,
};

export default TextNew;
