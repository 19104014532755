import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import './individual-circle.scss';

export type IndividualCircleProps = {
  description: React.ReactNode;
  id: string;
};

const IndividualCircle = (
  { description, id }: IndividualCircleProps,
  {},
  className: string,
): JSX.Element => (
  <section className={className}>
    <section className={classNames('circle', id)} />
    {description}
  </section>
);

type IndividualCircleType = (props: IndividualCircleProps) => JSX.Element;

export default compose<IndividualCircleType>(
  withFunctionalClassName(ComponentType.MOLECULE, 'IndividualCircle'),
)(IndividualCircle);
