import { createSelector } from 'reselect';

interface ConfigState {
  config: {
    environmentConfig?: {
      jotform?: {
        embedUrl?: string;
      };
    };
  };
}

/**
 * Gets the src url for the jotform embed token value from state
 * @param state - The application state
 * @returns The jotform src URL string or undefined
 */
export const getJotformEmbedUrl = createSelector<
  ConfigState,
  string | undefined,
  string | undefined
>(
  (state: ConfigState) => state.config.environmentConfig?.jotform?.embedUrl,
  (jotformEmbedUrl: string | undefined) => {
    if (
      !jotformEmbedUrl ||
      typeof jotformEmbedUrl !== 'string' ||
      !jotformEmbedUrl.startsWith('https://')
    ) {
      return undefined;
    }

    return jotformEmbedUrl;
  },
);

export default getJotformEmbedUrl;
