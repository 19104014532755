import React from 'react';
import JotformEmbed from 'react-jotform-embed';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import createScriptTitle from 'common/src/app/util/createScriptTitle';

import Wrapper from 'components/atoms/Wrapper';
import Size from 'common/src/app/data/enum/Size';
import Loader from 'components/atoms/Loader';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import { ConsultantEnquiryProps } from './ConsultantEnquiry.types';

import './consultant-enquiry.scss';

const ConsultantEnquiry = (
  { jotformEmbedUrl, loading, nestedContent, pageBackgroundImage }: ConsultantEnquiryProps,
  {},
  className: string,
): JSX.Element => (
  <div
    className={className}
    style={{
      backgroundImage: pageBackgroundImage ? `url(${pageBackgroundImage.src})` : undefined,
    }}
  >
    <Wrapper.MD padding={Size.XL}>
      {loading && <Loader />}

      {!loading &&
        nestedContent?.map(content => (
          <RichTextBlock
            key={content.title}
            title={createScriptTitle(content.title)}
            text={createScriptTitle(content.text)}
          />
        ))}

      {jotformEmbedUrl && <JotformEmbed src={jotformEmbedUrl} />}
    </Wrapper.MD>
  </div>
);

export default withFunctionalClassName(
  ComponentType.TEMPLATE,
  'ConsultantEnquiry',
)(ConsultantEnquiry);
