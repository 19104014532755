import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import Wrapper from 'components/atoms/Wrapper';

import TextNew, {
  Colors,
  ElementTypes,
  FontScaleType,
  FontScaleLineHeight,
} from 'components/atoms/TextNew';
import Icon from 'components/atoms/Icon';
import IconName from 'common/src/app/data/enum/IconName';
import RecruitmentBubbleBackgroundWrapper from 'components/atoms/RecruitmentBubbleBackgroundWrapper';

import './recruitment-page-title-header.scss';

export type RecruitmentPageTitleHeaderProps = {
  localePrefix: string;
  hasSwoosh: boolean;
  deviceState: number;
};

const RecruitmentPageTitleHeader = (
  { localePrefix, hasSwoosh, deviceState }: RecruitmentPageTitleHeaderProps,
  {},
  className: string,
): JSX.Element => (
  <div className={className}>
    <RecruitmentBubbleBackgroundWrapper>
      <Wrapper.MD>
        <TextNew
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          fontScaleLineHeight={FontScaleLineHeight.SMALL}
          fontScaleType={FontScaleType.SERIF_DISPLAY_THREE}
          localeId={`${localePrefix}.title.start`}
        />
        <TextNew
          element={ElementTypes.SPAN}
          color={Colors.PRIMARY_LIGHT}
          fontScaleLineHeight={FontScaleLineHeight.SMALL}
          fontScaleType={FontScaleType.SCRIPT_DISPLAY_ONE}
          localeId={`${localePrefix}.title.end`}
        >
          {hasSwoosh && (
            <Icon
              name={IconName.UNDERLINE_SWOOSH_SMALL}
              width={deviceState >= DeviceState.LG ? 200 : 110}
            />
          )}
        </TextNew>
      </Wrapper.MD>
    </RecruitmentBubbleBackgroundWrapper>
  </div>
);

export default withDeviceState()(
  withFunctionalClassName(ComponentType.MOLECULE, 'RecruitmentPageTitleHeader', ['hasSwoosh'])(
    RecruitmentPageTitleHeader,
  ),
);
