import React from 'react';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import LockUp from 'components/atoms/LockUp';
import { Colors, FontScaleType, FontScaleLineHeight } from 'components/atoms/TextNew';

// Nested content items
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import QuotationBlock from 'components/molecules/BlockContent/components/atoms/QuotationBlock';
import QuotationWithLargeAuthorPhotoAndLinkBlock from 'components/molecules/BlockContent/components/atoms/QuotationWithLargeAuthorPhotoAndLinkBlock';
import ImageWithTitleAndDescriptionList from 'components/molecules/BlockContent/components/atoms/ImageWithTitleAndDescriptionList';
import SvgWithTitleList from 'components/molecules/BlockContent/components/atoms/SvgWithTitleList';
import QuotationWithLargeAuthorPhotoAndLinkList from 'components/molecules/BlockContent/components/atoms/QuotationWithLargeAuthorPhotoAndLinkList';
import VideoWithThumbnailBlock from 'components/molecules/BlockContent/components/atoms/VideoWithThumbnailBlock';

// Types
import { types } from 'components/molecules/BlockContent/enum/BlockTypes';
import DeviceStateType from 'components/types/DeviceState';
import PageDataType from '../../../types/PageData';
import PageDataNestedContentType from '../../../types/PageDataNestedContent';

// Static components
import CareersThreeStages from '../../templates/BecomeADistrictManager/components/molecules/CareersThreeStages';
import CareersFieldDetails from '../../templates/BecomeADistrictManager/components/molecules/CareersFieldDetails';
import CareersSlidingOverlay from '../../templates/BecomeADistrictManager/components/molecules/CareersSlidingOverlay';
import CareersJobVacanciesFeed from '../../templates/CareersLanding/components/molecules/CareersJobVacanciesFeed';
import CareersImageSlideshow from '../../templates/JoinTheHeadOfficeTeam/components/molecules/CareersImageSlideshow';

export type CareersBlockContentProps = {
  deviceState: DeviceStateType;
  pageData: PageDataType;
};

const CareersBlockContent = (
  { deviceState, pageData }: CareersBlockContentProps,
  {},
  className: string,
): JSX.Element => (
  <section className={className}>
    {pageData?.content?.map(({ _type, ...content }: PageDataNestedContentType, index: number) => {
      const key = `careers-site-${index}`;

      switch (_type) {
        case types.RICH_TEXT_BLOCK:
          return <RichTextBlock title={content.title} text={content.text} key={key} />;

        case types.QUOTATION_BLOCK:
          return <QuotationBlock {...content} isConsultant key={key} />;

        case types.QUOTATION_WITH_LARGE_AUTHOR_PHOTO_AND_LINK_BLOCK:
          return (
            <QuotationWithLargeAuthorPhotoAndLinkBlock
              author={content?.author}
              authorPhoto={content?.authorPhoto}
              mediaAssetCta={content?.mediaAssetCta}
              note={content?.note}
              quotation={content?.quotation}
              deviceState={deviceState}
              key={key}
              photoPosition={content?.photoPosition}
            />
          );

        case types.IMAGE_WITH_TITLE_AND_DESCRIPTION_LIST:
          return <ImageWithTitleAndDescriptionList items={content?.items} key={key} />;

        case types.SVG_WITH_TITLE_LIST:
          return <SvgWithTitleList items={content?.items} key={key} />;

        case types.QUOTATION_WITH_LARGE_AUTHOR_PHOTO_AND_LINK_LIST:
          return (
            <QuotationWithLargeAuthorPhotoAndLinkList
              deviceState={deviceState}
              items={content?.items}
              key={key}
            />
          );

        case types.RICH_TEXT_LOCKUP_HEADER_BLOCK:
          return (
            <LockUp.RichText
              key={key}
              text={content.text}
              type={content.type}
              strongFontStyle={{
                fontScaleType: FontScaleType.SCRIPT_DISPLAY_TWO,
                color: Colors.BRAND,
              }}
              fontStyle={{
                fontScaleType: FontScaleType.SERIF_DISPLAY_TWO,
                fontScaleLineHeight: FontScaleLineHeight.SMALL,
              }}
            />
          );

        case types.VIDEO_WITH_THUMBNAIL_BLOCK:
          return <VideoWithThumbnailBlock {...content} key={key} />;

        case types.CAREERS_JOB_VACANCIES_FEED_BLOCK:
          return <CareersJobVacanciesFeed key={key} location="" />;

        case types.CARERS_IMAGE_SLIDE_SHOW_BLOCK:
          return (
            <CareersImageSlideshow
              key={key}
              deviceState={deviceState}
              defaultDepartment="magazineAndPublications"
            />
          );

        case types.CARERS_SLIDING_OVERLAY_BLOCK:
          return <CareersSlidingOverlay key={key} />;

        case types.CARERS_FIELD_DETAILS_BLOCK:
          return <CareersFieldDetails key={key} deviceState={deviceState} />;

        case types.CARERS_THREE_STAGES_BLOCK:
          return <CareersThreeStages key={key} />;

        default:
          return null;
      }
    })}
  </section>
);

type CareersBlockContentType = (props: CareersBlockContentProps) => JSX.Element;

export default compose<CareersBlockContentType>(
  withFunctionalClassName(ComponentType.MOLECULE, 'CareersBlockContent'),
)(CareersBlockContent);
