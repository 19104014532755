import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';

import Icon from 'components/atoms/Icon';

import './individual-district-field.scss';

export type IndividualDistrictFieldProps = {
  id: string;
  backgroundSvg: string;
  description: React.ReactNode;
};

const IndividualDistrictField = (
  { id, backgroundSvg, description }: IndividualDistrictFieldProps,
  {},
  className: string,
): JSX.Element => (
  <section className={classNames(className, id)}>
    <div className="circle-container">
      <Icon name={backgroundSvg} width={206} height={206} />
    </div>
    {description}
  </section>
);

type IndividualDistrictFieldType = (props: IndividualDistrictFieldProps) => JSX.Element;

export default compose<IndividualDistrictFieldType>(
  withFunctionalClassName(ComponentType.MOLECULE, 'IndividualDistrictField'),
)(IndividualDistrictField);
