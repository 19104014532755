import React from 'react';
import { compose } from 'redux';

import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import IconName from 'common/src/app/data/enum/IconName';

import TextNew, { Colors, FontScaleType, FontScaleLineHeight } from 'components/atoms/TextNew';

import Icon from 'components/atoms/Icon';
import LockUp from 'components/atoms/LockUp';

import './careers-job-vacancies-feed.scss';

export type CareersJobVacanciesFeedProps = {
  deviceState: number;
  vacancies: Array<{
    reference: string;
    jobTitle?: string;
    location?: string;
    link: string;
    department?: string;
  }>;
};

const CareersJobVacanciesFeed = (
  { deviceState, vacancies }: CareersJobVacanciesFeedProps,
  {},
  className: string,
): JSX.Element => (
  <section className={className}>
    <LockUp type={deviceState >= DeviceState.SM ? 'inline' : 'stacked'}>
      <LockUp.Script>your future</LockUp.Script>
      <LockUp.Serif> starts here...</LockUp.Serif>
    </LockUp>

    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            <TextNew
              color={Colors.BRAND}
              fontScaleLineHeight={FontScaleLineHeight.SMALL}
              fontScaleType={FontScaleType.SERIF_STANDOUT_TWO}
              localeId="careersJobVacanciesFeed.title"
            />

            <TextNew
              fontScaleLineHeight={FontScaleLineHeight.SMALL}
              fontScaleType={FontScaleType.SANS_SERIF_BODY_ONE}
              localeId="careersJobVacanciesFeed.subtitle"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {vacancies?.map(values => {
          const { department, link, location, reference, jobTitle } = values;

          return (
            <tr key={`vacancy-row-${reference}`}>
              <td className="title-cell">
                {jobTitle && (
                  <a href={link} target="_blank">
                    <TextNew
                      color={Colors.BRAND}
                      fontScaleLineHeight={FontScaleLineHeight.SMALL}
                      fontScaleType={FontScaleType.SANS_SERIF_BODY_TWO}
                    >
                      {jobTitle}
                    </TextNew>
                  </a>
                )}
              </td>
              <td>
                {location && (
                  <a href={link} target="_blank">
                    {deviceState > DeviceState.SM && (
                      <Icon name={IconName.MAP_PIN} width={20} height={20} />
                    )}
                    <TextNew
                      fontScaleLineHeight={FontScaleLineHeight.SMALL}
                      fontScaleType={FontScaleType.SANS_SERIF_BODY_TWO}
                    >
                      {location}
                    </TextNew>
                  </a>
                )}
              </td>
              <td>
                {department && (
                  <a href={link} target="_blank">
                    {deviceState > DeviceState.SM && (
                      <Icon name={IconName.SILHOUETTES} width={20} height={20} />
                    )}
                    <TextNew
                      fontScaleLineHeight={FontScaleLineHeight.SMALL}
                      fontScaleType={FontScaleType.SANS_SERIF_BODY_TWO}
                    >
                      {department}
                    </TextNew>
                  </a>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </section>
);

type CareersJobVacanciesFeedType = (props: CareersJobVacanciesFeedProps) => JSX.Element;

export default compose<CareersJobVacanciesFeedType>(
  withDeviceState(),
  withFunctionalClassName(ComponentType.MOLECULE, 'CareersJobVacanciesFeed'),
)(CareersJobVacanciesFeed);
