import React from 'react';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import Pages from 'common/src/app/data/enum/Pages';
import RecruitmentHeader from 'components/organisms/RecruitmentHeader';

import './become-a-consultant.scss';

export type BecomeAConsultantProps = {
  children: { children: React.ReactNode };
};

const BecomeAConsultant = (
  { children }: BecomeAConsultantProps,
  {},
  className: string,
  dataTestId: string,
): JSX.Element => {
  const navigationLinks = [
    {
      to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT,
      locale: 'consultant.navigation.home',
    },
    {
      to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_CONSULTANTS,
      locale: 'consultant.navigation.consultants',
    },
    {
      to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT_OUR_FRANCHISE_PACKAGE,
      locale: 'consultant.navigation.franchisePackage',
    },
    {
      to: Pages.UK_PUBLIC_BECOME_A_CONSULTANT_FRANCHISE_FACTS,
      locale: 'consultant.navigation.franchiseFacts',
    },
  ];

  return (
    <div className={className} data-testid={dataTestId}>
      <RecruitmentHeader navigationLinks={navigationLinks} />
      {children}
    </div>
  );
};

export default withFunctionalClassName(ComponentType.PAGE, 'BecomeAConsultant')(BecomeAConsultant);
