/**
 * We often link to pages from other microservices or from pattern-lib component.
 * Currently they would need to import the Pages file from member or account, which is not
 * what we want; account or member files should only import files from their own folder
 * So instead this file lives in Common, so it can be properly imported from everywhere.
 */
export const PARAM_WAIT_FOR_MIGRATION_CREATION = 'wfmc';

const Pages = {
  HOME: '/',
  MENU: '/menu',

  // ACCOUNT
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/change-password',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_EMAIL: '/change-email',
  CONFIRM_EMAIL_CHANGE: '/confirm-email-change',
  CONFIRMED_EMAIL: '/confirm-email',
  LEGACY_LOGIN: '/legacy-login',
  LOGIN: '/login',
  ALREADY_LOGGED_IN: '/logged-in',
  LOGOUT: '/logout',
  LOGGED_OUT: '/logged-out',
  DUAL_ACCESS: '/dual-access',
  SITEMAP: '/sitemap',

  // NEWSLETTER SIGNUP EMAIL-CONFIRMATION
  CONFIRM_NEWSLETTER_SUBSCRIPTION: '/confirm-seven-day-menu-subscription',
  CONFIRM_NEWSLETTER_SUBSCRIPTION_DEPRECATED: '/confirm-seven-day-subscription',

  // ONLINE REGISTER
  REGISTRATION: '/register',
  REGISTRATION_MEDICAL_CHECK: '/register/health-check',
  REGISTRATION_ACCOUNT_DETAILS: '/register/account-details',
  REGISTRATION_SUMMARY: '/register/summary',
  REGISTRATION_PACKAGE_SELECTION: '/register/package-selection',
  REGISTRATION_CHECKOUT: '/register/payment',
  REGISTRATION_FINISHED: '/register/finished',

  PAYMENT: '/payment',

  // GROUP REGISTER
  GROUP_REGISTRATION: '/group-register',
  GR_CARD_CHECK: '/group-register/card-check',
  GR_MEDICAL_CHECK: '/group-register/medical-check',
  GR_PERSONAL_DETAILS: '/group-register/personal-details',
  GR_CONTACT_DETAILS: '/group-register/contact-details',
  GR_REGISTRATION_ERROR: '/action-required',
  GR_CARD_TRIES_EXCEEDED: '/card-try-exceeded',
  GR_SUCCESSFULLY_REGISTERED_FREE2GO: '/free-2-go-registration-success',

  // ONLINE TO GROUP TRANSFER
  TRANSFER_TO_GROUP: '/transfer-to-group',
  TRANSFER_TO_GROUP_INFO: '/transfer-to-group/information',
  TRANSFER_TO_GROUP_ADDRESS: '/transfer-to-group/address',
  TRANSFER_TO_GROUP_SUCCESS: '/transfer-to-group/success',

  // GROUP TO ONLINE TRANSFER
  TRANSFER_TO_ONLINE: '/transfer-to-online',
  TRANSFER_TO_ONLINE_CHECKS: '/transfer-to-online/information',
  TRANSFER_TO_ONLINE_BMI: '/transfer-to-online/your-details',
  TRANSFER_TO_ONLINE_START_JOURNEY: '/transfer-to-online/start-journey',
  TRANSFER_TO_ONLINE_PACKAGE: '/transfer-to-online/package',
  TRANSFER_TO_ONLINE_SUMMARY: '/transfer-to-online/summary',
  TRANSFER_TO_ONLINE_SUCCESS: '/transfer-success',

  // RECOVER EMAIL
  RE_RECOVER_EMAIL: '/email-recovery',
  RE_SECURITY_CHECK: '/email-recovery/security-check',
  RE_EMAIL_ACCESS: '/email-recovery/email-access',
  RE_SECURITY_QUESTION: '/email-recovery/security-question',
  RE_UPDATE_EMAIL: '/email-recovery/update-email',
  RE_SUCCESS: '/email-recovery/success',

  // UK PUBLIC
  UK_PUBLIC_ADVERTISE_WITH_US: '/advertise-with-us',
  UK_PUBLIC_ADVERTISING: '/advertise-with-us',
  UK_PUBLIC_BMI_CALCULATOR: '/bmi-calculator',
  UK_PUBLIC_HOW_IT_WORKS: '/how-it-works',
  UK_PUBLIC_COMPARISON: '/group-or-online-membership',
  UK_PUBLIC_ONLINE_INFORMATION: '/what-happens-online',
  UK_PUBLIC_FAQ_CAN_MY_CHILD_JOIN: '/faqs/before-you-join/can-my-child-join',
  UK_PUBLIC_HEALTH_PROFESSIONALS: 'https://www.slimmingworld.co.uk/health/',
  UK_PUBLIC_HOME: '/',
  UK_PUBLIC_ICELAND: '/food-range',
  UK_PUBLIC_ICELAND_OUR_STORY: '/food-range/our-story',
  UK_PUBLIC_ICELAND_OUR_PRODUCTS: '/food-range/our-products',
  UK_PUBLIC_ICELAND_OUR_RANGE: '/food-range/our-range',
  UK_PUBLIC_OFFERS: '/offers',
  UK_PUBLIC_OUR_STORY: '/our-story',
  UK_PUBLIC_OVERVIEW_CATEGORY: '/overview/:category',
  UK_PUBLIC_PRESS_MEDIA: '/press-and-media',
  UK_PUBLIC_RECIPE_DETAIL: '/recipes/:slug',
  UK_PUBLIC_RECIPE_SEARCH: '/recipes',
  UK_PUBLIC_SCIENCE: '/the-science-behind-slimming-world',
  UK_PUBLIC_STORY: '/our-story',
  UK_PUBLIC_WHAT_CAN_I_EAT: '/what-can-i-eat',
  UK_PUBLIC_SUCCESS_STORIES: '/real-life-stories',
  UK_PUBLIC_SUCCESS_STORIES_MALE_FILTER: '/real-life-stories/male',
  UK_PUBLIC_SUCCESS_STORIES_FEMALE_FILTER: '/real-life-stories/female',
  UK_PUBLIC_SUCCESS_STORIES_DETAIL: '/real-life-stories/:slug',
  UK_PUBLIC_WHAT_HAPPENS_IN_GROUP: '/what-happens-in-group',
  UK_PUBLIC_SEVEN_DAY_MENU: '/seven-day-menus(/:menuType)(/:day)',
  UK_PUBLIC_SEVEN_DAY_MENU_ENTRY: '/seven-day-menus',
  UK_PUBLIC_MAGAZINE: '/magazine',
  UK_PUBLIC_GOLDEN_BUS: '/golden',
  UK_PUBLIC_MAGAZINE_COMPETITIONS: '/magcomps',
  UK_PUBLIC_USER_TESTING: '/userexperience',
  UK_PUBLIC_BETTER_HEALTH: '/betterhealth',
  UK_PUBLIC_PODCASTS: '/podcast',
  UK_PUBLIC_MYTHS: '/questions-answered',
  UK_PUBLIC_SMART_CONTENT_NOT_FOUND_PAGE: '/content-not-found/:slug',
  UK_PUBLIC_SLIMMING_WORLD_APP: '/slimming-world-app',

  // Blog
  BLOG: '/blog/',

  // UK PUBLIC NEAREST GROUP SEARCH
  UK_PUBLIC_NEAREST_GROUP_LANDING: '/nearest-group-search',
  UK_PUBLIC_NEAREST_GROUP_DETAIL: '/group/:id',
  UK_PUBLIC_NEAREST_CONSULTANT_LANDING: '/consultant/:id',
  UK_PUBLIC_NEAREST_GROUP_COUNTIES: '/counties',
  UK_PUBLIC_NEAREST_GROUP_TOWNS_IN_COUNTY: '/counties/:county',
  UK_PUBLIC_NEAREST_GROUP_GROUPS_IN_TOWNS: '/counties/:county/:town',
  UK_PUBLIC_CYPRUS_GROUP_SEARCH: '/cyprus',

  // UK PUBLIC CAMPAIGN LANDING PAGES
  UK_JAN_2019_CAMPAIGN_LANDINGS: '/landing/jan-2019/:campaign',
  UK_PUBLIC_FREE_FOOD_FEBRUARY: '/freefoodfeb',
  UK_PUBLIC_MENTAL_HEALTH: '/mentalhealth',
  UK_PUBLIC_BODY_MAGIC: '/activity',

  // MEMBER
  COMING_SOON: '/coming-soon',
  WEEK_ZERO: '/week-zero',
  WEEK_ZERO_MODAL_QUICK_START: '/week-zero?modal=quick-start',
  TWELVE_WEEK_PROGRAM_HOME: '/?twelve-week-program',
  SLIMMING_WORLD_ESSENTIALS: '/slimming-world-essentials',
  WEIGHT_LOSS_PLANNER: '/weight-loss-planner',
  FOOD_SYNS: '/food-lists-synds-tools',
  NOTIFICATIONS: '/notifications',
  WEIGH_IN_HISTORY: '/weigh-in-history',

  // COMMUNITY
  COMMUNITY: '/community',
  COMMUNITY_DETAIL: '/community/:postId',
  COMMUNITY_EDIT_POST: '/community/:editPostId/edit',
  COMMUNITY_CREATE_POST: '/community/create-post',
  COMMUNITY_CREATE_POST_IN_GROUP: '/community/create-post/:slug',
  COMMUNITY_GROUP_POSTS: '/community/group/:slug',

  // MY SLIMMING GROUP
  MY_SLIMMING_GROUP: '/my-slimming-group',
  MY_SLIMMING_GROUP_OVERVIEW: '/my-slimming-group/:containerId',
  MY_SLIMMING_GROUP_DETAIL: '/my-slimming-group/:containerId/:postId',
  MY_SLIMMING_GROUP_EDIT_POST: '/my-slimming-group/:containerId/:editPostId/edit',
  MY_SLIMMING_GROUP_CREATE_POST: '/my-slimming-group/:containerId/create-post',

  // LIVE EVENT
  LIVE_GROUP: '/group/:groupId',

  // Profile
  MEMBER_PROFILE: '/profile(/:id)',
  PROFILE: '/profile',

  // Profile Builder
  PROFILE_BUILDER_ONLINE: '/profile/setup',
  PROFILE_BUILDER_GROUP: '/group-profile/setup',

  RECIPE_LANDING: '/recipes',
  RECIPE_OVERVIEW: '/recipes/overview',
  RECIPE_DETAIL: '/recipes/:slug',

  SEVEN_DAY_MENU_DETAIL: '/seven-day-menus/:slug',

  FEATURE_APPS: '/features/download-our-planner-app',

  SUCCESS_STORY_LANDING: '/success-stories',
  SUCCESS_STORY_OVERVIEW: '/success-stories/overview',
  SUCCESS_STORY_DETAIL: '/success-stories/:slug',

  // STATIC PAGES
  COMMUNITY_GUIDELINES: '/community-guidelines',
  ACCESSIBILITYPOLICY: '/accessibility-policy',
  MODERNSLAVERYACT: '/modern-slavery-statement',
  CONTACT_US: '/contact-us',
  CONTACT_US_GET_IN_TOUCH: '/contact-us/get-in-touch',
  CONTACT_US_MEDIA: '/contact-us/media',
  CONTACT_US_ADS: '/contact-us/advertisement',
  FOOD_OPTIMISING_DIARY_APP_SIGN_UP: '/download-slimming-world-apps',
  USER_SUBSCRIPTION_AGREEMENT: '/user-subscription-agreement',
  FAQ: '/faq',
  ABOUT: '/about',
  LANDING_PAGE: '/landing-page',
  GETTING_STARTED: '/getting-started',

  // FOOD_OPTIMISING_ESSENTIALS
  FOOD_OPTIMISING_ESSENTIALS: '/food-optimising-essentials',

  // POLICY CONTENT
  TERMS_OF_USE: '/terms-of-use',
  PRIVACYPOLICY: '/privacy-policy',
  COOKIESPOLICY: '/cookies-policy',

  // PRODUCT SHOP
  SHOP_LANDING: '/',
  SHOP_LANDING_FOOD: '/food',
  SHOP_LANDING_PRINT: '/books',
  SHOP_LANDING_MEMBERSHIP: '/membership',
  PRODUCT_DETAIL: '/products/:category/:productId',
  PRODUCT_CHECKOUT: '/checkout',
  PRODUCT_CHECKOUT_PERSONAL: '/checkout/personal/edit',
  PRODUCT_CHECKOUT_YOUR_ADDRESS: '/checkout/address/edit',
  PRODUCT_CHECKOUT_SHIPPING: '/checkout/shipping/edit',
  PRODUCT_CHECKOUT_BILLING: '/checkout/billing/edit',
  PRODUCT_CHECKOUT_CONFIRMATION: '/order/confirm',

  // SW PLANNER
  FOOD_SEARCH: '/search/food',
  RECIPE_SEARCH: '/search/recipe',
  ACTIVITY_SEARCH: '/search/activity',
  DAILY_SUMMARY: '/summary',
  AWARDS: '/awards',
  AWARD_DETAILS: '/award-detail',

  // SYNS ESTIMATOR
  SYNS_ESTIMATOR: '/syns-estimator',
  SYNS_ESTIMATOR_PRODUCT_INFO: '/syns-estimator/product-info',
  SYNS_ESTIMATOR_NUTRITIONAL_INFO: '/syns-estimator/nutritional-info',
  SYNS_ESTIMATOR_RESULTS: '/syns-estimator/results',
  SYNS_ESTIMATOR_UPLOAD_PHOTO: '/syns-estimator/upload-photo',
  SYNS_ESTIMATOR_UPLOAD_PHOTO_SUCCESS: '/syns-estimator/upload-successful',

  // HEALTHY_EXTRAS
  HEALTHY_EXTRAS: '/healthy-extras',
  HEALTHY_EXTRA: '/healthy-extras/:id',

  // ACCOUNT SETTINGS
  ACCOUNT_SETTINGS: '/account-settings',
  ACCOUNT_SETTINGS_ACCOUNT_DETAILS: '/account-settings/account-details',
  ACCOUNT_SETTINGS_PERSONAL_DETAILS: '/account-settings/personal-details',
  ACCOUNT_SETTINGS_WEIGH_IN: '/account-settings/weigh-in',
  ACCOUNT_SETTINGS_ORDER_HISTORY: '/account-settings/order-history',
  ACCOUNT_SETTINGS_PURCHASE_RETURNS: '/account-settings/purchase-returns',
  ACCOUNT_SETTINGS_RETURN_CONFIRMED: '/account-settings/return-confirmed',
  ACCOUNT_SETTINGS_WEIGHT_LOSS_JOURNEY: '/account-settings/my-weight-loss-journey',
  ACCOUNT_SETTINGS_MEDICAL: '/account-settings/medical-details',
  ACCOUNT_SETTINGS_DIETARY: '/account-settings/dietary-requirements',
  ACCOUNT_SETTINGS_SUBSCRIPTION_STATUS: '/account-settings/subscription-status',
  ACCOUNT_SETTINGS_PAYMENT_HISTORY: '/account-settings/payment-history',
  ACCOUNT_SETTINGS_SHIPPING: '/account-settings/shipping/edit',
  ACCOUNT_SETTINGS_EMAILS: '/account-settings/emails',
  ACCOUNT_SETTINGS_PREGNANCY: '/account-settings/pregnancy',
  ACCOUNT_SETTINGS_HALL_OF_FAME: '/account-settings/hall-of-fame',
  ACCOUNT_SETTINGS_PRIVACY: '/account-settings/privacy',
  ACCOUNT_SETTINGS_SHARE_STORY: '/account-settings/your-story',
  ACCOUNT_SETTINGS_COMPETITION_ENTRIES: '/account-settings/competitions',

  SETTINGS_NEW_JOURNEY: '/account-settings/my-weight-loss-journey',

  // Online restart journey
  SETTINGS_NEW_ONLINE_JOURNEY_CURRENT_WEIGHT: '/restart/current-weight',
  SETTINGS_NEW_ONLINE_JOURNEY_TARGET_WEIGHT: '/restart/target-weight',
  SETTINGS_NEW_ONLINE_JOURNEY_WEIGH_IN_DAY: '/restart/weigh-in-day',

  // Group start / restart weight loss journey
  NEW_GROUP_JOURNEY: '/start',
  NEW_GROUP_JOURNEY_DATE: '/start/start-date',
  NEW_GROUP_JOURNEY_CURRENT_HEIGHT: '/start/current-height',
  NEW_GROUP_JOURNEY_START_WEIGHT: '/start/start-weight',
  NEW_GROUP_JOURNEY_CURRENT_WEIGHT: '/start/current-weight',
  NEW_GROUP_JOURNEY_TARGET_WEIGHT: '/start/target-weight',
  NEW_GROUP_JOURNEY_CONFIRMATION: '/start/confirmation',

  // QUICK START
  QUICK_START: '/quick-start',
  QUICK_START_START: '/quick-start/start',
  QUICK_START_FREE_FOODS: '/quick-start/free-foods',
  QUICK_START_FREE_FOOD_QUIZ: '/quick-start/free-food-quiz',
  QUICK_START_SYNS: '/quick-start/syns',
  QUICK_START_HEALTHY_EXTRAS: '/quick-start/healthy-extras',
  QUICK_START_COMPLETE: '/quick-start/complete',

  // Confirm or change initial weight and height before first weigh in
  FIRST_WEIGH_CONFIRMATION: '/a-quick-check',
  FIRST_WEIGH_WELCOME: '/a-quick-check/welcome',
  FIRST_WEIGH_WEIGHT: '/a-quick-check/weight',
  FIRST_WEIGH_HEIGHT: '/a-quick-check/height',
  FIRST_WEIGH_BMI_OK_PROCEED: '/a-quick-check/great',
  FIRST_WEIGH_BMI_TOO_LOW: '/a-quick-check/fantastic',
  FIRST_WEIGH_REFUND_CLOSE: '/a-quick-check/refund-and-close',

  // Weigh in flow
  WEIGHIN: '/weighin',
  WEIGHIN_ENTERWEIGHT: '/weighin/enter-weight',
  WEIGHIN_CONFIRMWEIGHT: '/weighin/confirm-weight',
  WEIGHIN_AWARD: '/weighin/award',
  WEIGHIN_MOOD: '/weighin/mood',
  WEIGHIN_PREGNANT_FINISH: '/weighin/success',
  WEIGHIN_GOALS: '/weighin/goals',
  WEIGHIN_PERSONALNOTE: '/weighin/personal-note',
  WEIGHIN_PERSONALFEEDBACK: '/weighin/personal-feedback',
  WEIGHIN_SKIPPINGREASON: '/weighin/skip',
  WEIGHIN_SKIPPED: '/weighin/skipped',

  // Edit weigh-in
  EDIT_WEIGHIN: '/edit-weighin',
  EDIT_WEIGHIN_EDIT_WEIGHT: '/edit-weighin/edit-weight',
  EDIT_WEIGHIN_EDIT_WEIGHT_CONFIRMATION: '/edit-weighin/confirmation',
  EDIT_WEIGHIN_EDIT_WEIGHT_UPDATED: '/edit-weighin/updated',

  // Edit start or target weight
  EDIT_START_WEIGHT: '/edit-start-weight',
  EDIT_START_WEIGHT_INFO: '/edit-start-weight-info',
  EDIT_TARGET_WEIGHT: '/edit-target-weight',

  // Add missing weigh-in
  ADD_WEIGHIN: '/add-weighin',
  ADD_WEIGHIN_ADD_WEIGHT: '/add-weighin/add-weight',
  ADD_WEIGHIN_ADD_WEIGHT_CONFIRMATION: '/add-weighin/confirmation',
  ADD_WEIGHIN_ADD_WEIGHT_UPDATED: '/add-weighin/updated',

  // Virtual consultant
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT: '/weighin/personal-feedback/virtual-consultant',
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_TIPS_AND_RECOMMENDATIONS:
    '/weighin/personal-feedback/virtual-consultant/tips-and-recommendations',
  WEIGHIN_PERSONALFEEDBACK_VIRTUAL_CONSULTANT_TIPS_AND_RECOMMENDATIONS_ARTICLE:
    '/weighin/personal-feedback/virtual-consultant/tips-and-recommendations/article/:link',

  // Search
  SEARCH: '/search',
  SEARCH_RECIPES: '/search/recipes',
  SEARCH_FEATURES: '/search/features',
  SEARCH_SUCCESS_STORIES: '/search/success-stories',
  SEARCH_COMMUNITY_POSTS: '/search/community-posts',

  // Bookmarks
  BOOKMARK: '/bookmarks',
  BOOKMARK_RECIPES: '/bookmarks/recipes',
  BOOKMARK_FEATURES: '/bookmarks/features',
  BOOKMARK_SUCCESS_STORIES: '/bookmarks/success-stories',
  BOOKMARK_COMMUNITY_POSTS: '/bookmarks/community-posts',
  BOOKMARK_FOOD_OPTIMISING: '/bookmarks/food-optimising',

  LEGACY_WEIGH_INS: '/legacy-weigh-ins',
  LEGACY_WEIGH_INS_BEGIN: '/legacy-weigh-ins/begin',
  LEGACY_WEIGH_INS_CONFIRM_DONT_IMPORT: '/legacy-weigh-ins/confirm-dont-import',
  LEGACY_WEIGH_INS_COMPLETE: '/legacy-weigh-ins/complete',

  // General invitation error
  INVITATION_ERROR: '/invitation/error',

  // Free 2 go landing
  FREE_2_GO_LANDING: '/free-2-go-landing',

  // Migration account
  MIGRATION_LOGIN: '/migration/login',
  MIGRATION_LOGGED_IN: '/migration/logged-in(/:isMigrating)',
  MIGRATION_UPDATE_PASSWORD: '/migration/update-password',
  MIGRATION_SET_SECURITY_QUESTION: '/migration/security-question',

  // Migration
  // Step 2
  MIGRATION: '/migrate',
  MIGRATION_STATE_CHECK: '/migrate/state-check',
  MIGRATION_STATE_CHECK_WAIT_FOR_MIGRATION_CREATION: `/migrate/state-check?${PARAM_WAIT_FOR_MIGRATION_CREATION}=true`,
  MIGRATION_CONFIRM: '/migrate/confirm',
  MIGRATION_LOADING: '/migrate/loading',

  // Step 3
  MIGRATION_DATA_CHECK: '/information-check',
  MIGRATION_PERSONAL_DETAILS: '/information-check/personal-details',
  MIGRATION_PERSONAL_DETAILS_EDIT: '/information-check/personal-details/edit',
  MIGRATION_AVATAR_USERNAME: '/information-check/avatar-and-username',
  MIGRATION_HEALTH_CHECK: '/information-check/health',
  MIGRATION_DIET_CHECK: '/information-check/diet',
  MIGRATION_WEIGHT_GOALS: '/information-check/weight-and-goals',
  MIGRATION_READY_FOR_MIGRATION: '/information-check/ready-for-migration',
  MIGRATION_TRANSFER: '/move-you-over',
  MIGRATION_COMPLETED: '/completed',
  MIGRATION_TRANSFER_ERROR: '/move-you-over/error(/:referenceNumber)',
  MIGRATION_ERROR: '/migration/error(/:errorState)',

  // Debug
  PAGE_STATUS: '/status',

  // Access Denied
  ACCESS_DENIED: '/access-denied',

  // Publicity
  SHARE_STORY_OVERVIEW: '/share-story',
  SHARE_STORY_ENTRY: '/share-story/entry',
  SHARE_STORY_ENTRY_DETAILS: '/share-story/entry/details',
  SHARE_STORY_ENTRY_QUESTIONNAIRE: '/share-story/entry/questionnaire',
  SHARE_STORY_READONLY_QUESTIONNAIRE: '/share-story/:entryId/questionnaire',
  SHARE_STORY_ENTRY_PHOTOS: '/share-story/entry/photos',
  COMPETITION_OVERVIEW: '/competition',
  COMPETITION_ENTRY: '/competition/entry',
  COMPETITION_ENTRY_DETAILS: '/competition/entry/details',
  COMPETITION_ENTRY_QUESTIONNAIRE: '/competition/entry/questionnaire',
  COMPETITION_READONLY_QUESTIONNAIRE: '/competition/:entryId/questionnaire',
  COMPETITION_ENTRY_PHOTOS: '/competition/entry/photos',
  CRITERIA_INFORMATION: '/criteria-information',
  PUBLIC_AGREEMENT: '/publicity-agreement',
  SUCCESS_STORY_AGREEMENT: '/success-story-agreement',
  PODCAST: '/podcast',

  // Multi Factor Authentication
  MFA_REGISTER: '/mfa/register',
  MFA_VERIFY: '/mfa/verify',

  // Advertisement
  ADVERTISEMENT_DETAIL_PAGE: '/detail/:type/:countryId',
  ADS_GUIDE: '/advertising-guide',

  // UK PUBLIC - Become a consultant
  UK_PUBLIC_BECOME_A_CONSULTANT: '/become-a-consultant',
  UK_PUBLIC_BECOME_A_CONSULTANT_OUR_CONSULTANTS: '/become-a-consultant/meet-our-consultants',
  UK_PUBLIC_BECOME_A_CONSULTANT_OUR_FRANCHISE_PACKAGE: '/become-a-consultant/our-franchise-package',
  UK_PUBLIC_BECOME_A_CONSULTANT_FRANCHISE_FACTS: '/become-a-consultant/franchise-facts',
  UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY: '/become-a-consultant/consultant-enquiry',
  UK_PUBLIC_BECOME_A_CONSULTANT_ENQUIRY_SUCESS: '/become-a-consultant/consultant-enquiry-success',

  // UK PUBLIC - Careers
  UK_PUBLIC_CAREERS: '/careers',
  UK_PUBLIC_CAREERS_JOIN_THE_HEAD_OFFICE_TEAM: '/careers/join-the-head-office-team',
  UK_PUBLIC_CAREERS_BECOME_A_DISTRICT_MANAGER: '/careers/become-a-district-manager',
  UK_PUBLIC_CAREERS_FAQS: '/careers/faqs',

  // UK PUBLIC Comparison page
  UK_PUBLIC_COMPARISON_PAGE: '/slimming-world-or-weight-watchers',
};

export const GROUP_REGISTRATION_FIRST_STEP = Pages.GR_CARD_CHECK;
export const REGISTRATION_FIRST_STEP = Pages.REGISTRATION_PACKAGE_SELECTION;

export default Pages;
