import { compose } from 'redux';
import { connect } from 'react-redux';
import { jobVacanciesSelector } from 'common/src/app/selectors/publicSelectors';

import CareersJobVacanciesFeed from './CareersJobVacanciesFeed';

const mapStateToProps = (state: any) => ({
  vacancies: jobVacanciesSelector(state),
});

const connected = connect(mapStateToProps);

export default compose(connected)(CareersJobVacanciesFeed);
